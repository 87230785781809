<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center">
      <div class="w-2 h-2 rounded-full mr-2 minw2 minh2" :class="backgroundClasses" v-if="!isPdfPage"></div>
      <svg v-else viewBox="0 0 8 8 " class="flex-1 h-2 w-2 minw2 minh2 mr-2">
        <circle r="4" cx="4" cy="4" stroke="red" stroke-width="0" :fill="color" />
      </svg>
      <span class="font-normal text-gray-400 text-xs w-full overflow-hidden overflow-ellipsis">{{ labelWithLimit }}</span>
    </div>
    <div class="flex flex-row items-center">
      <div class="w-2 h-2 rounded-full bg-red-800 mr-2 invisible"></div>
      <div>{{ percents }} %</div>
    </div>
  </div>
</template>

<script>
import stringWithLimit from "@/utils/StringWithLimit";

export default {
  name: "LegendItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    percents: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    order : {
      type : Number,
      required : true
    },

    isPdfPage : {
      type : Boolean,
      default : false
    }
  },

  computed: {
    labelWithLimit() {
      return stringWithLimit(this.label, 30);
    },

    backgroundClasses(){
      switch (this.order % 8) {
        case 0 :
          return "bg-chart-first";
        case 1 :
          return "bg-chart-second";
        case 2 :
          return "bg-chart-third";
        case 3 :
          return "bg-chart-fourth";
        case 4 :
          return "bg-chart-fifth";
        case 5 :
          return "bg-chart-sixth";
        case 6 :
          return "bg-chart-seventh";
        case 7 :
          return "bg-chart-eighth";
        default :
          return "";
      }
    }
  },
};
</script>

<style scoped>
.minw2 {
  min-width: 0.5rem !important;
  max-width: 0.5rem !important;
}
.minh2 {
  min-height: 0.5rem !important;
  max-height: 0.5rem !important;
}
</style>
