<template>
  <div class="bg-gray-100 h-10 w-10 rounded-full flex justify-center items-center text-white">
    <IconUserAvatarSolid class="h-4 w-4" />
  </div>
</template>

<script>
import IconUserAvatarSolid from "@/components/icons/IconUserAvatarSolid";

export default {
  name: "InviteUserAvatar",
  components: { IconUserAvatarSolid },
};
</script>
