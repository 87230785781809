<template>
  <div class="h-12 w-12 bg-red-100 rounded-full flex justify-center items-center text-error-500">
    <IconBell class="w-5 h-5" />
  </div>
</template>

<script>
import IconBell from "../icons/IconBell";

export default {
  name: "FailMark",
  components : {
    IconBell
  }
}
</script>
