<template>
  <div class="px-4 py-4 block flex justify-end">
    <e-button
      v-if="!isCreating && !currentEstimate.archived_at && canArchive"
      type="secondary"
      class="flex flex-row items-center"
      @click="onArchive"
      :disabled="loading"
    >
      <icon-archive-outline class="mr-2 w-5 h-5" /> Archive
    </e-button>
  </div>
</template>

<script>
import EButton from "@/components/base/EButton";
import IconArchiveOutline from "@/components/icons/IconArchiveOutline";
import { mapGetters } from "vuex";

export default {
  name: "SideOverFooter",
  components: { EButton, IconArchiveOutline },
  props: {
    loading: {
      type: Boolean,
    },

    canArchive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onCancel() {
      this.$emit("cancel");
    },
    onArchive() {
      this.$emit("archive", this.currentEstimate.id);
    },
  },

  computed: {
    ...mapGetters({
      isCreating : "sideOver/isCreating",
      currentEstimate : "sideOver/currentEstimate"
    }),

    isDisabled() {
      return this.loading;
    },
  },
};
</script>

<style>
.cancel-btn {
  width: 81px !important;
  height: 38px !important;
}
</style>
