<template>
  <e-button type="secondary" class="inline-flex items-center w-28" @click="copy(url)">
    <icon-clip class="mr-2 flex-initial" v-if="!copied" />
    <icon-checked class="h-6 w-6 mr-2 flex-initial" v-else></icon-checked>
    {{ buttonLabel }}
  </e-button>
</template>

<script>
import { useClipboard, usePermission } from "@vueuse/core";
import IconClip from "@/components/icons/IconClip";
import IconChecked from "@/components/icons/IconChecked";
import EButton from "@/components/base/EButton";
import { createHashId } from "@/api/apiCustomer";


export default {
  name: "CopyToClipboardButton",
  components: { IconClip, IconChecked, EButton },
  props: {
    projectId: {
      required: true,
    },
  },

  data() {
    return {
      url : null
    }
  },
  setup() {
    const { copy, copied, isSupported } = useClipboard();

    usePermission('clipboard-read')
    usePermission('clipboard-write')

    return { copy, copied, isSupported };
  },
  computed: {
    buttonLabel() {
      return !this.copied ? "Copy link" : "Copied!";
    },
  },

  async created() {
    const result = await createHashId(this.projectId);

    this.url = `${process.env.VUE_APP_URL}/clientPage?hash_id=${result}`;
  }
};
</script>
