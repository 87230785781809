import Vue from "vue";
import App from "./App.vue";
import vSelect from "vue-select";
import "./registerServiceWorker";

import "./assets/tailwind.css";
import "./assets/global.css";
import "./assets/animation-toast.css";

import "./assets/formulate-file.css";

import router from "./router";
import store from "./store";

import "./plugins/apexCharts";
import "./plugins/vueFormulate";
import "./plugins/vTooltip";
import "./plugins/vueCompositionAPI";
import "./plugins/notifications";
import "./plugins/VueProgressBar";
import "./plugins/vueModal";

import "./directives/focus";
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
