<template>
  <div class="pb-8">
    <div class="grid-part-table-pie-chart justify-between mt-10">
      <parts-and-additional-expenses-table
        :categories="estimate.categories.filter((category) => !category.hidden)"
        :additional-expenses="estimate.additional_expenses"
        :project-id="estimate.id"
      />
      <div class="shadow rounded-b-md rounded-l-md rounded-r-md border border-neutral-300">
        <pie-chart
          :show-stroke="false"
          title="Estimate breakdown"
          :categories="estimate.categories.filter((category) => !category.hidden)"
          :in-client-page="false"
        />
      </div>
    </div>
    <parts-of-estimations :team="estimate.team" :project-id="estimate.id" class="mt-4" />
  </div>
</template>

<script>
import PartsAndAdditionalExpensesTable from "./kit/PartsAndAdditionalExpensesTable";
import PieChart from "./pie-chart/PieChart";
import PartsOfEstimations from "./PartsOfEstimations";
export default {
  name : "ProjectPageDetailsTab",
  components : {PartsOfEstimations , PieChart , PartsAndAdditionalExpensesTable},
  props : {
    estimate : {},
  }
}
</script>

<style scoped>
.grid-part-table-pie-chart {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 20px;
  align-items: start;
}
</style>
