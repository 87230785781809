<template>
  <v-select
    @input="setSelectedItem"
    :clearable="false"
    class="block mt-1 theme-brand"
    :options="allItems"
    :value="selected"
    :searchable="searchable"
  >
    <template #selected-option="option">
      <slot :option="option" />
    </template>
    <template #header> Status </template>
    <template slot="option" slot-scope="option">
      <span class="flex py-2 items-center justify-between">
        <slot :option="option" />
        <icon-checked class="w-4 h-4" v-if="selected === option.label" />
      </span>
    </template>
  </v-select>
</template>

<script>
import IconChecked from "../icons/IconChecked";

export default {
  name: "SingleSelect",
  components: { IconChecked },
  model: {
    prop: "selected",
    event: "selectedChanged",
  },
  props: {
    allItems: {
      type: Array,
    },
    selected: {
      type: String,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSelectedItem(selectedItem) {
      this.$emit("selectedChanged", selectedItem);
    },
  },

  created() {
    // if (this.allItems.length) this.$emit("selectedChanged", this.allItems[0]);
  },
};
</script>
