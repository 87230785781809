<template>
  <div
    class="h-6 w-10 py-px px-2 bg-white z-10 border-md rounded-md text-xs flex items-center justify-center font-bold"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Badge",
};
</script>
