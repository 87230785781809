<template>
  <modal name="user-invited-modal" heigth="250" width="384" @before-open="initData">
    <div class="flex flex-col justify-center items-center p-8">
      <SuccessEmail class="mb-5" />
      <h4 class="leading-6 font-bold text-center">Invitation sent</h4>
      <span class="leading-5 text-sm text-center text-neutral-500 mt-2"
        >The invitation has been successfully sent to {{ email }}</span
      >
      <EButton type="primary" class="block w-full mt-6" @click="close">Okay</EButton>
    </div>
  </modal>
</template>

<script>
import SuccessEmail from "@/components/marks/SuccessEmail";
import EButton from "@/components/base/EButton";

export default {
  name: "UserInvitedModal",
  components: { SuccessEmail, EButton },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    close() {
      this.$modal.hide("user-invited-modal");
    },

    initData(event) {
      this.email = event.params.email;
    },
  },
};
</script>
