<template>
  <div class="relative w-56" v-click-outside="away">
    <button class="w-full flex flex-row justify-between h-9 py-2 px-3 border leading-5 dropdown-button rounded-md border-gray-200" :class="[showList ? 'border-indigo-500' : 'border-gray-200']" @click="showList = !showList">
      <span class="w-full text-purple-500 truncate text-left" v-if="categories.length > 0 && currentPartId">{{ categories.find(c => c.id === this.currentPartId) ? categories.find(c => c.id === this.currentPartId).name : "" }}</span>
      <icon-chevron-down class="h-4 w-4"/>
    </button>
    <transition name="fade">
      <ul class="absolute parts-list rounded-md bg-white w-full divide-y" v-if="showList && categories.length" >
        <part-select-option
          v-for="category in categories" :key="category.id"
          :name="category.name"
          :current="currentPartId === category.id"
          :id="category.id"
          @changeCurrent="update"
        />
      </ul>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import IconChevronDown from "./icons/IconChevronDown";
import PartSelectOption from "./PartSelectOption";

export default {
  name : "PartSelectInput",
  components: { PartSelectOption, IconChevronDown },
  directives : {
    ClickOutside
  },
  model : {
    prop : "currentPartId",
    event : "change"
  },
  props : {
    currentPartId : {
    },
    categories : {

    },
    estimateId : {

    }
  },
  data() {
    return{
      showList : false
    }
  },
  methods : {
    away(){
      this.showList = false
    },

    update(id){
      this.showList = false;
      this.$emit('change' , id)
    }
  },

  watch: {
    categories(newValue, oldValue){
      const ids = newValue.map(c => c.id);
      if(((oldValue.length === 0 && newValue.length > 0) || (ids.indexOf(this.currentCategoryId) && this.currentCategoryId) === -1) && this.categories.length) {
        this.$emit('change', this.categories[0].id)
      }
    },

    estimateId(){
      this.$emit('change', this.categories[0].id)
    }
  },

}
</script>

<style scoped>
/*.dropdown-button{*/
/*  box-shadow: 0px 0px 0px 1px #6366F1;*/
/*}*/

.parts-list{
  top : auto;
  bottom: calc(100% + 10px);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  left : 0;
  right: 0;
}

.parts-list {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.parts-list::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
