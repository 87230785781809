<template>
  <div class="w-full flex flex-auto items-center justify-center flex-col px-12 text-center">
    <div class="text-neutrals-1000 leading-9 font-xl">No data yet</div>
    <div class="text-neutral-500 text-sm">An estimate diagram will display here, as soon, as you enter some data</div>
  </div>
</template>

<script>
export default {
  name: "EmptyDataPieChart",
};
</script>
