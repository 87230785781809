<template>
  <div class="max-w-4xl mx-auto pt-8 px-4 pb-8" v-if="!loading">
    <pdf-header :name="estimate.name" :deadline="estimate.deadline" />
    <h4 class="font-bold text-gray-900 leading-7 mt-8">General overview</h4>
    <div class="w-full shadow-xl rounded-md mt-1 border border-gray-200">
      <pie-chart
        :categories="estimate.categories.filter((category) => !category.hidden)"
        :show-stroke="false"
        :show-if-empty="true"
        :always-emit-if-ready="true"
        :is-pdf-page="true"
      />
    </div>
    <test-total-table
      class="mt-8"
      :categories="estimate.categories.filter((category) => !category.hidden).sort((a,b) => a.order - b.order)"
      :additional-expenses="estimate.additional_expenses"
    />
    <all-features :categories="estimate.categories.filter((category) => !category.hidden).sort((a,b) => a.order - b.order)" />
  </div>
  <loading-spinner v-else />
</template>

<script>
import TestTotalTable from "@/components/kit/PartsAndAdditionalExpensesTable";
import PieChart from "@/components/pie-chart/PieChart";
import AllFeatures from "@/components/AllFeatures";
import { getEstimateById } from "@/api/apiCustomer";
import LoadingSpinner from "@/components/LoadingSpinner";
import PdfHeader from "@/components/PdfHeader";
import { mapActions} from "vuex";

export default {
  name: "ClientPage",
  components: { TestTotalTable, PieChart, LoadingSpinner, AllFeatures, PdfHeader },
  data() {
    return {
      loading: false,
      estimate: null,
    };
  },

  methods : {
    ...mapActions({
      switchToViewMode : "project/switchToView"
    })
  },

  async created() {
    this.loading = true;
    this.switchToViewMode();
    this.estimate = await getEstimateById(this.$route.query.hash_id);
    this.loading = false;
  },
};
</script>

<style scoped>
 .color-test{
   background: #dc2c2c;
 }

 .and-another{
   background-color: #5897fb;
 }
</style>
