export default {
  state: {
    isEdit: true,
  },
  getters: {
    isEdit: (state) => state.isEdit,
  },
  mutations: {
    SWITCH_TO_EDIT: (state) => (state.isEdit = true),
    SWITCH_TO_VIEW: (state) => (state.isEdit = false),
  },
  actions: {
    switchToEdit: (context) => context.commit("SWITCH_TO_EDIT"),
    switchToView: (context) => context.commit("SWITCH_TO_VIEW"),
  },
  namespaced: true,
};
