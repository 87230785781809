<template>
  <div class="flex flex-col justify-center items-center p-8">
    <SuccessMark class="mb-4" />
    <h4 class="leading-6 font-bold text-center">Congratulations</h4>
    <span class="leading-5 text-sm text-center text-neutral-500">Your changes have been successfully saved!</span>
    <EButton type="primary" class="block w-full mt-4" @click="close">Okay</EButton>
  </div>
</template>

<script>
import SuccessMark from "@/components/marks/SuccessMark";
import EButton from "@/components/base/EButton";

export default {
  name: "CongratulationsUpdateProfileModal",
  components: { SuccessMark, EButton },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
