import { axiosInstance } from "@/api/axiosInstances";

export async function getFiles(estimateId) {
  const { data } = await axiosInstance.get(`/api/estimates/${estimateId}/medias`);

  return data.data;
}

export async function addFile(estimateId, { file }) {
  const formData = new FormData();
  formData.append("file", file);

  await axiosInstance.post(`/api/estimates/${estimateId}/medias`, formData);
}

export async function deleteFile(estimateId, fileId) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/medias/${fileId}`);
}
