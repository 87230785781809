<template>
  <div class="w-full flex flex-auto items-center justify-center flex-col px-12 text-center empty-data-parts">
    <div class="text-neutrals-1000 leading-9 font-xl">No data yet</div>
    <div class="text-neutral-500 text-sm">
      Start by adding additional expenses or adding parts in the settings and it will be displayed here
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyDataTechnologies",
};
</script>

<style>
.empty-data-parts {
  min-height: 214px;
}
</style>
