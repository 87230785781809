<template>
  <modal name="archive-estimate-congratulation" width="384" height="250">
    <div class="flex flex-col justify-center items-center p-8">
      <success-archive class="mb-4" />
      <h4 class="leading-6 font-bold text-center">Congratulations</h4>
      <span class="leading-5 text-sm text-center text-neutral-500">Your changes have been successfully saved!</span>
      <EButton type="primary" class="block w-full mt-4" @click="close">Okay</EButton>
    </div>
  </modal>
</template>

<script>
import SuccessArchive from "@/components/marks/SuccessArchive";
import EButton from "@/components/base/EButton";

export default {
  name: "CongratulationsArchiveEstimate",
  components: { SuccessArchive, EButton },

  methods: {
    close() {
      this.$modal.hide("archive-estimate-congratulation");
    },
  },
};
</script>
