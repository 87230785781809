<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" :stroke="color" />
  </svg>
</template>

<script>
export default {
  name: "IconChevronDown",
  props: ["color"],
};
</script>
