import { axiosInstance } from "@/api/axiosInstances";

export async function getEstimateCategories(estimateId) {
  const { data } = await axiosInstance.get(`/api/estimates/${estimateId}/categories?with_hidden=true`);

  return data.data;
}

export async function addEstimateCategory({ estimateId }) {
  const formData = new FormData();

  formData.append("name", "New category");
  formData.append("status", "To do");
  formData.append("rate_per_hour", 30);

  const response = await axiosInstance.post(`/api/estimates/${estimateId}/categories`, formData);

  return response.data.data;
}

export async function deleteEstimateCategory({ estimateId, id }) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/categories/${id}`);
}

export async function updateEstimateCategoryStatus({ estimateId, categories }) {
  categories = categories.map((category) => {
    return { ...category, rate_per_hour: category.ratePerHour };
  });

  await axiosInstance.put(`/api/estimates/${estimateId}/categories/`, categories);
}

export async function updateEstimateCategory({ estimateId, id, category }) {
  await axiosInstance.put(
    `/api/estimates/${estimateId}/categories/${id}?name=${category.name}&rate_per_hour=${category.ratePerHour}`
  );
}

export async function hideEstimateCategory({ estimateId, id }) {
  await axiosInstance.post(`/api/estimates/${estimateId}/categories/${id}/hidden`);
}

export async function unhideEstimateCategory({ estimateId, id }) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/categories/${id}/hidden`);
}

export async function updateUsers({ estimateID, categoryId, newTeam }) {
  const categories = await getEstimateCategories(estimateID);

  const currentTeam = categories.find((category) => category.id === categoryId).users;

  const currentTeamIds = currentTeam.map((user) => user.id);
  const newTeamIds = newTeam.map((user) => user.id);

  const toDelete = currentTeamIds.filter(
    (currentTeamId) => !newTeamIds.find((newTeamId) => newTeamId === currentTeamId)
  );

  const toAdd = newTeamIds.filter((newTeamId) => !currentTeamIds.find((currentTeamId) => currentTeamId === newTeamId));

  toDelete.forEach(async (id) => {
    await axiosInstance.delete(`/api/estimates/${estimateID}/categories/${categoryId}/users/${id}`);
  });

  if (toAdd.length)
    await axiosInstance.post(`/api/estimates/${estimateID}/categories/${categoryId}/users`, {
      users: toAdd,
    });
}

export async function updateCategories({ estimateId, categories }) {
  categories = categories.map((category) => {
    return { ...category, rate_per_hour: category.ratePerHour };
  });

  await axiosInstance.put(`/api/estimates/${estimateId}/categories`, categories);
}

export async function duplicateCategory({estimateId, duplicatedCategoryId, newCategoryId}){
  axiosInstance.post(`/api/estimates/${estimateId}/categories/${duplicatedCategoryId}/copy`, {
    new_category_id : newCategoryId
  })
}

export async function addCategory({estimateId, category}){
  axiosInstance.post(`/api/estimates/${estimateId}/categories`,
  {
    ...category,
    rate_per_hour : category.ratePerHour
  }
  )
}

export async function deleteCategory({estimateId , categoryId}){
  axiosInstance.delete(`/api/estimates/${estimateId}/categories/${categoryId}`)
}

