<template>
  <span class="py-1 px-2 rounded-md w-28 text-center text-white z-20 h-8 leading-6" :class="projectPriority">
    {{ type === "Archived" ? archivedDate : type }}
  </span>
</template>

<script>
export default {
  name: "EstimateStatus",
  props: {
    type: {
      type: String,
      required: true,
    },
    archivedDate: {
      type: String,
    },
  },
  computed: {
    projectPriority() {
      switch (this.type) {
        case "To do":
          return "bg-blue-accent";
        case "In progress":
          return "bg-yellow-accent";
        case "Done":
          return "bg-green-accent";
        case "Archived":
          return "bg-white  text-gray-500";
        default:
          return "";
      }
    },
  },
};
</script>
