<template>
  <modal name="confirm-duplicate" width="350" height="175" @before-open="initData">
    <div class="flex flex-col justify-center items-center p-8 space-y-4">
      <div class="grid grid-flow-col space-x-6">
        <div class="space-y-2">
          <h3 class="font-bold leading-6 text-neutral-1000">Duplicate part</h3>
          <p class="text-sm leading-5 text-neutral-500">Are you sure you want to duplicate this part with all internal features?</p>
        </div>
      </div>
      <div class="flex justify-end space-x-4 w-full block">
        <EButton type="secondary" @click="close">Cancel</EButton>
        <EButton type="secondary" @click="accept">Duplicate</EButton>
      </div>
    </div>
  </modal>
</template>

<script>
import EButton from "../base/EButton";

export default {
  name: "WarningDuplicatePart",
  components : {EButton},
  data(){
    return{
      category : null
    }
  },
  methods: {
    close(){
      this.$modal.hide('confirm-duplicate');
    },
    accept(){
      this.$emit('accept', this.category);
      this.$modal.hide('confirm-duplicate');
    },

    initData(event){
      this.category = event.params.category
    }
  }
}
</script>

<style>
  .vm--overlay{
    background-color : rgba(0,0,0, 0.05) !important;
  }

  .vm--modal{
    box-shadow: none !important;
  }
</style>
