<template>
  <div
    class="flex flex-row items-center w-max py-px px-2 rounded-lg"
    @click="openMenu"
    :class="statusBackground"
    v-click-outside="away"
  >
    <span class="status-circle rounded-full relative mr-2" :class="markClasses">
      <div v-if="active" class="absolute mt-4 bg-white z-50 rounded border shadow-md status-select-menu">
        <div class="h-full w-full">
          <status-select-option status="To do" @select="select('To do')" />
          <status-select-option status="In progress" @select="select('In progress')" />
          <status-select-option status="Done" @select="select('Done')" />
        </div>
      </div>
    </span>
    <span class="leading-5 text-sm" v-if="!hidden">{{ status }}</span>
    <span class="status-text leading-5 text-sm" v-else>{{ dateOfHide }}</span>
    <icon-status-select-arrow-down v-if="!hidden" class="ml-1.5"/>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import StatusSelectOption from "@/components/StatusSelectOption";
import { mapGetters } from "vuex";
import IconStatusSelectArrowDown from "./icons/IconStatusSelectArrowDown";

export default {
  name: "StatusSelect",
  directives: {
    ClickOutside,
  },
  components: {
    StatusSelectOption,
    IconStatusSelectArrowDown
  },
  props: {
    status: {
      type: String,
    },

    hidden: {},
  },
  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
    }),

    statusBackground() {
      if (this.hidden) return "status-text";
      switch (this.status) {
        case "To do":
          return "bg-sky-50 text-sky-600";
        case "In progress":
          return "bg-warning-100 text-warning-600";
        case "Done":
          return "bg-green-50 text-green-600";
        default:
          return "status-text";
      }
    },

    markClasses() {
      if (this.hidden) return "bg-neutral-500";
      switch (this.status) {
        case "To do":
          return "bg-blue-500";
        case "In progress":
          return "bg-yellow-500";
        case "Done":
          return "bg-green-500";
        default:
          return "bg-neutral-500";
      }
    },

    dateOfHide() {
      if (this.hidden) {
        return this.hidden.split(" ")[0].split('-').join('.');
      }

      return "";
    },
  },

  data() {
    return {
      active: false,
    };
  },

  methods: {
    away() {
      this.active = false;
    },

    select(status) {
      this.active = false;
      this.$emit("select", status);
      this.away();
    },

    openMenu() {
      if (this.hidden || !this.isEdit) return;
      this.active = true;
    },
  },
};
</script>

<style scoped>
.status-circle {
  /*min-height: 0.375rem;*/
  /*min-width: 0.375rem;*/
  height: 0.375rem;
  width: 0.375rem;
}

.status-select-menu {
  width: 8rem !important;
}

.status-circle-inside {
  height: 6px;
  width: 6px;
}

.status-text {
  color: #818e9c !important;
}
</style>
