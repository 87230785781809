import { getUsersByEmail } from "@/api/apiUsers";

export default {
  state: {
    allUsers : []
  },
  getters: {
    allUsers : (state) => state.allUsers
  },
  mutations: {
    UPDATE_ALL_USERS : (state, payload) => state.allUsers = [...payload]
  },
  actions: {
    getAllUsers : async ({commit}) => {
      commit('UPDATE_ALL_USERS' , await getUsersByEmail())
    }
  },
  namespaced: true,
};
