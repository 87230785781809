<template>
  <div class="flex h-screen flex-row w-full">
    <div class="fixed p-8">
      <icon-nerd-logo-with-text />
    </div>
    <div class="flex h-screen bg-purple-50 w-7/12 items-center justify-center">
      <img src="@/assets/img/i-sign-in.png" alt="" />
    </div>
    <div class="h-screen flex items-center justify-center w-5/12">
      <div class="flex w-full info">
        <div class="flex w-full flex-col">
          <h3 class="text-gray-900 leading-10 font-bold text-4xl">Sign in with Google</h3>
          <h5 class="text-gray-900 leading-6 font-bold mt-2">to continue to Scopo</h5>
          <!-- 
          <div class="flex items-end">
            <p class="text-gray-900 leading-6 mt-8 mr-2 font-bold">Why we require Google profile?</p>
            <icon-information class="w-6 h-6" />
          </div>
           -->
          <a
            :href="redirectUrl"
            class="bg-indigo-500 hover:bg-indigo-600 mt-8 text-white font-semibold py-2 px-4 rounded shadow flex justify-center flex-row items-center google-button"
          >
            <icon-google class="mr-3" />
            <span>Google</span>
          </a>

          <!-- 
          <p class="mt-8 text-gray-900 leading-6 font-bold">
            By signing in you agree to our <a class="text-indigo-600 cursor-pointer font-bold">user agreement</a> and
            <a class="text-indigo-600 cursor-pointer font-bold">privacy policy</a>
          </p>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconGoogle from "@/components/icons/IconGoogle";
import IconNerdLogoWithText from "@/components/icons/IconNerdLogoWithText";
import { mapGetters } from "vuex";

export default {
  components: { IconNerdLogoWithText, IconGoogle },
  computed: {
    ...mapGetters({
      token: "user/token",
    }),

    redirectUrl() {
      return `${process.env.VUE_APP_API_URL}/auth/google/redirect`;
    },
  },
  created() {
    if (this.token) {
      this.$router.push({ name: "projects" });
    }
  },
};
</script>

<style scoped>
.info {
  max-width: 336px;
}

.google-button {
  height: 38px;
}
</style>
