<template>
  <layout main-classes="">
    <loading-spinner v-if="loading" />
    <div class="max-w-7xl mx-auto px-2 sm:px-6 pt-8 lg:px-8 pb-12" v-else>
      <file-page-header-section :project="estimate" :show-team="true">
        <project-page-buttons-group
          :project-id="currentEstimateId"
          @openSettings="openSettingsMode(currentEstimate)"
        />
      </file-page-header-section>
      <project-page-tab class="mt-6" v-model="currentTab"/>
      <transition name="slide-fade" mode="out-in">
       <component :is="currentComponent" :estimate="estimate"/>
      </transition>
    </div>

    <feature-form-footer class="z-50" v-if="categories.length" :categories="categories"/>
    <transition name="slide-fade" mode="out-in">
      <side-over-new-project
        v-if="isOpen"
        @close="close"
        @updateEstimate="updateProject($event)"
        @updateEstimateByNotOwner="updateProjectByNotOwner($event)"
        @archiveProject="showArchiveConfirmModal"
        @leaveAndClose="leaveProject"
        :loading="loadingSideOver"
      />
    </transition>
    <warning-archive-estimate @archive="archiveProject" />
    <congratulations-archive-estimate />
  </layout>
</template>

<script>
import Layout from "@/layout/Layout";
import ProjectPageDetailsTab from "../components/ProjectPageDetailsTab";
import ProjectPageInfoTab from "../components/ProjectPageInfoTab";
import FilePageHeaderSection from "@/components/kit/FilePageHeaderSection";
import FilePreview from "../components/FilePreview";
import ProjectPageButtonsGroup from "@/components/ProjectPageButtonsGroup";
import { mapGetters, mapActions } from "vuex";
import CongratulationsArchiveEstimate from "@/components/modals/CongratulationsArchiveEstimate";
import LoadingSpinner from "@/components/LoadingSpinner";
import SideOverNewProject from "@/views/SideOverNewProject";
import WarningArchiveEstimate from "@/components/modals/WarningArchiveEstimate";
import { leaveFromEstimate } from "@/api/apiEstimateUsers";
import FeatureFormFooter from "../components/FeatureFormFooter";
import ProjectPageTab from "../components/ProjectPageTab";
import PhotoReader from "../components/PhotoReader";


export default {
  name: "ProjectPage",
  components: {
    ProjectPageTab,
    FeatureFormFooter,
    SideOverNewProject,
    // PieChart,
    // PartsAndAdditionalExpensesTable,
    ProjectPageDetailsTab,
    ProjectPageInfoTab,
    Layout,
    FilePageHeaderSection,
    // FileUploader,
    // PartsOfEstimations,
    // ProjectDescription,
    ProjectPageButtonsGroup,
    LoadingSpinner,
    CongratulationsArchiveEstimate,
    WarningArchiveEstimate,
    FilePreview,
    PhotoReader
  },
  data() {
    return {
      loading: false,
      loadingSideOver : false,
      currentTab : 'Estimate',
      showReader : false
    };
  },
  computed: {
    ...mapGetters({
      categories: "estimates/currentEstimate/categories",
      additionalExpenses: "estimates/additionalExpenses/additionalExpenses",
      estimateById: "estimates/estimateById",
      estimate: "estimates/currentEstimate/currentEstimate",
      isEdit: "project/isEdit",
      currentEstimateId: "estimates/currentEstimate/currentEstimateId",
      isOpen : "sideOver/isOpen",
      currentEstimate: "estimates/currentEstimate/currentEstimate"
    }),

    currentComponent(){
      return this.currentTab === "Estimate" ? "ProjectPageDetailsTab" : "ProjectPageInfoTab"
    }
  },
  methods: {
    ...mapActions({
      updateEstimate: "estimates/currentEstimate/updateEstimate",
      archiveEstimate: "estimates/archiveEstimate",
      getEstimate: "estimates/currentEstimate/getEstimate",
      updateEstimateByNotOwner: "estimates/currentEstimate/updateEstimateByNotOwner",
      openSettingsMode: "sideOver/openSettingsMode",
      close: "sideOver/close",
      switchToEditMode: "project/switchToEdit",
      switchToViewMode: "project/switchToView"
    }),

    async updateProject(project) {
      this.loadingSideOver = true;

      await this.updateEstimate(project);
      this.close();

      this.loadingSideOver = false;
    },

    async archiveProject(projectId) {
      this.loadingSideOver = true;
      await this.archiveEstimate(projectId);

      this.$modal.hide("confirm-archive");

      this.loadingSideOver = false;
      this.$router.push({ name: "projects" });

    },

    async updateProjectByNotOwner(project) {
      this.loadingSideOver = true
      await this.updateEstimateByNotOwner(project);

      this.close();

      this.loadingSideOver = false;
    },

    closeArchiveModal() {
      this.$modal.hide("archive-estimate");
    },

    showArchiveConfirmModal(projectId) {
      this.$modal.show("confirm-archive", { estimateId: projectId });
    },

    async leaveProject(estimateId) {
      this.loadingSideOver = true;
      await leaveFromEstimate({ estimateId });

      this.loadingSideOver = false;
      this.$router.push({ name: "projects" });
    },
  },

  async created() {
    this.loading = true;
    this.switchToEditMode()
    //this.switchToViewMode()
    await this.getEstimate(Number(this.$route.params.projectId));
    if(this.$route.query.openSettings) {
      this.openSettingsMode(this.currentEstimate);
      //
      this.$router.replace({ name: "project", params: { projectId: this.$route.params.projectId },  });
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.menu-item {
  @apply group w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
}

.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
