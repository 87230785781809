<template>
  <button
    type="button"
    @click="$emit('click')"
    class="bg-gray-50 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
  >
    <span class="sr-only">Close panel</span>
    <icon-close-side-over />
  </button>
</template>

<script>
import IconCloseSideOver from "@/components/icons/IconCloseSideOver";

export default {
  name: "ButtonCloseSideOver",
  components: { IconCloseSideOver },
  emits: ["click"],
};
</script>
