import Vue from "vue";
import VueRouter from "vue-router";
import ProjectPage from "@/views/ProjectPage";
import NotFoundPage from "@/views/errors/NotFoundPage";
import ProjectsPage from "@/views/ProjectsPage";
import NewProjectPage from "@/views/NewProjectPage";
import ProfilePage from "@/views/ProfilePage";
import LoginPage from "@/views/LoginPage";
import ClientPage from "@/views/ClientPage";
import AuthCallbackPage from "@/views/AuthCallbackPage";
import PdfPage from "@/views/PdfPage";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      ignoreAuth: true,
    },
  },
  {
    path: "/login/reset-password", //TODO
    name: "reset_password",
    component: LoginPage,
    meta: {
      ignoreAuth: false,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsPage,
  },
  {
    path: "/projects/new",
    name: "new_project",
    component: NewProjectPage,
  },
  {
    path: "/projects/:projectId",
    name: "project",
    component: ProjectPage,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
  },
  {
    path: "/clientPage",
    name: "clientPage",
    component: ClientPage,
    meta: {
      ignoreAuth: true,
    },
  },
  {
    path: "/pdf",
    name: "pdf",
    component: PdfPage,
    meta: {
      ignoreAuth: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFoundPage,
  },
  {
    path: "/auth/callback",
    name: "AuthCallback",
    component: AuthCallbackPage,
    meta: {
      ignoreAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.ignoreAuth && !store.getters["user/token"]) next({ name: "Login" });
  else next();
});

export default router;
