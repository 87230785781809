<template>
  <div class="space-x-3 flex items-center">
    <copy-to-clipboard-button :project-id="estimateId" />
    <e-button type="secondary" class="inline-flex items-center px-4" @click="downloadExcel">
      <icon-export class="mr-3" color="#111827"/>
      Export
    </e-button>
    <e-button type="secondary" class="inline-flex items-center" :disabled="!isArchived" @click="$emit('openSettings')">
      <icon-settings class="mr-2" color="#111827" />
      Settings
    </e-button>
    <a
      target="_blank"
      :href="url"
      class="h-10 flex flex-row items-center justify-center disabled:opacity-40 disabled:cursor-default w-28 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <span>Preview</span>
    </a>
  </div>
</template>

<script>
import CopyToClipboardButton from "@/components/CopyToClipboardButton";
// import DropdownButton from "@/components/DropdownButton";
import IconExport from "@/components/icons/IconExport";
// import IconChevronDown from "@/components/icons/IconChevronDown";
import IconSettings from "@/components/icons/IconSettings";
import EButton from "@/components/base/EButton";
import { getExcelLink } from "@/api/apiExcel";
import { mapGetters } from "vuex";
import { createHashId } from "../api/apiCustomer";

export default {
  name: "ProjectPageButtonGroup",
  components: {
    EButton,
    CopyToClipboardButton,
    // DropdownButton,
    IconSettings,
    IconExport,
    // IconChevronDown,
  },
  data(){
    return{
      url : null
    }
  },
  computed: {
    ...mapGetters({
      isArchived: "estimates/currentEstimate/isArchived",
      estimateId: "estimates/currentEstimate/currentEstimateId",
    }),
  },
  methods: {
    async downloadExcel() {
      const url = await getExcelLink(this.estimateId);

      const link = document.createElement("a");
      link.href = url;
      link.dispatchEvent(new MouseEvent("click"));
    },

    // async moveToPreviewPage(){
    //    const hash_id = await createHashId(this.estimateId);
    //
    //     // window.open(`${process.env.VUE_APP_URL}/clientPage?hash_id=${hash_id}` ,'_blank')
    // }
  },

  async created(){
    const hash_id = await createHashId(this.estimateId)

    this.url = `${process.env.VUE_APP_URL}/clientPage?hash_id=${hash_id}`
  }
};
</script>
