<template>
  <svg
    class="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="#4F46E5" d="M6 18L18 6M6 6l12 12" />
  </svg>
</template>

<script>
export default {
  name: "IconCloseSideOver",
};
</script>
