<template>
  <div class="fixed z-50 top-0 bottom-14 right-0 left-0 overlay-background flex items-center justify-center">
    <div class="flex flex-col global-cont">
      <div class="bg-gray-100 h-14 p-4 justify-between flex flex-row">
        <span> {{ file.name }} </span>
        <button @click="$emit('close')">
          <IconClose/>
        </button>
      </div>
      <img class="overflow-y-scroll" :src="file.url"/>
    </div>
  </div>
</template>

<script>
import IconClose from "./icons/IconClose";

export default {
  name : "PhotoReader",
  components : {
    IconClose,
  },
  props : {
    file : {}
  },
  errorCaptured() {
    return false
  },
}

</script>

<style>
.overlay-background{
  background-color: rgba(17, 24, 39, 0.5);
}

.pdf{
  overflow: hidden;
  width: 100vw;
}


@media screen and (min-width: 640px) {
  .pdf{
    overflow: hidden;
    width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .pdf{
    overflow: hidden;
    width: 1024px;
  }
}

.global-cont{
  max-height: 90%;
}
</style>
