<template>
  <row class="bg-gray-50 grid-cols-table header" :class="rowClasses">
    <header-label class="text-left" :class="[isEdit ? 'col-start-2' : 'col-start-1']">{{ mainName }}</header-label>
    <header-label class="text-right" :class="[isEdit ? 'col-start-3' : 'col-start-2']">Rate per hour</header-label>
    <header-label class="text-right" :class="[isEdit ? 'col-start-4' : 'col-start-3']">Hours</header-label>
    <header-label class="text-right" :class="[isEdit ? 'col-start-5' : 'col-start-4']">Cost</header-label>
  </row>
</template>

<script>
import HeaderLabel from "../../../../components/tables/HeaderLabel";
import Row from "../../../../components/tables/rows/Row";
import { mapGetters } from "vuex";

export default {
  name: "HeaderRow",
  components: { HeaderLabel, Row },
  computed: {
    rowClasses() {
      return this.isEdit ? "grid-cols-technologies-edit-mode" : "grid-cols-technologies-view-mode";
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
  props : {
    mainName : {
      type: String,
      default: "Parts"
    }
  }
};
</script>

<style>
.header {
  height: 2.625rem !important;
}
</style>
