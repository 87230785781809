<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.75 11.25V10H15V16.25H16.25V13.75H18.125V12.5H16.25V11.25H18.75Z" fill="#111827" />
    <path
      d="M11.875 16.25H9.375V10H11.875C12.3721 10.0005 12.8488 10.1982 13.2003 10.5497C13.5518 10.9012 13.7495 11.3779 13.75 11.875V14.375C13.7495 14.8721 13.5518 15.3488 13.2003 15.7003C12.8488 16.0518 12.3721 16.2495 11.875 16.25ZM10.625 15H11.875C12.0407 14.9998 12.1996 14.9339 12.3168 14.8168C12.4339 14.6996 12.4998 14.5407 12.5 14.375V11.875C12.4998 11.7093 12.4339 11.5504 12.3168 11.4332C12.1996 11.3161 12.0407 11.2502 11.875 11.25H10.625V15Z"
      fill="#111827"
    />
    <path
      d="M6.875 10H3.75V16.25H5V14.375H6.875C7.20637 14.3745 7.52402 14.2426 7.75834 14.0083C7.99265 13.774 8.1245 13.4564 8.125 13.125V11.25C8.12467 10.9186 7.99287 10.6008 7.75852 10.3665C7.52417 10.1321 7.20642 10.0003 6.875 10ZM5 13.125V11.25H6.875L6.87563 13.125H5Z"
      fill="#111827"
    />
    <path
      d="M13.75 8.75009V6.25009C13.7522 6.16795 13.7366 6.08631 13.7042 6.01078C13.6719 5.93526 13.6235 5.86764 13.5625 5.81259L9.1875 1.43759C9.13248 1.37656 9.06486 1.32818 8.98933 1.29581C8.9138 1.26344 8.83215 1.24784 8.75 1.25009H2.5C2.16878 1.25108 1.85142 1.38309 1.61721 1.6173C1.383 1.85151 1.25099 2.16887 1.25 2.50009V17.5001C1.25 17.8316 1.3817 18.1496 1.61612 18.384C1.85054 18.6184 2.16848 18.7501 2.5 18.7501H12.5V17.5001H2.5V2.50009H7.5V6.25009C7.50099 6.58131 7.633 6.89868 7.86721 7.13288C8.10142 7.36709 8.41878 7.4991 8.75 7.50009H12.5V8.75009H13.75ZM8.75 6.25009V2.75009L12.25 6.25009H8.75Z"
      fill="#111827"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPDF",
};
</script>
