<template>
  <loading-spinner v-if="loading" class="h120" :fast="true" />
  <div class="border-l flex flex-col temp-cont" id="category-content" v-else>
    <div class="relative w-full flex-1">
      <div class="h-full w-full relative" v-if="category(currentCategoryId) && category(currentCategoryId).features.length">
        <div>
          <test-e-table
            v-if="currentCategoryId"
            class="min-w-lg overflow-x-hidden h-full"
            :is-input-visible="isEdit"
            :is-draggable="isEdit"
            :is-editable="isEdit"
            :features="category(currentCategoryId).features"
            :project-id="projectId"
            :category-id="currentCategoryId"
            :draggable="true"
            :rate-per-hour="category(currentCategoryId).ratePerHour"
          />
        </div>
      </div>

      <div class="border-neutral-300 h-full flex flex-1 justify-center items-center h-180" v-else>
        <empty-data-features-table />
      </div>
    </div>
  </div>
</template>

<script>
import EmptyDataFeaturesTable from "@/components/empty-data/EmptyDataFeaturesTable";
import TestETable from "@/components/forms/TestETable";
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CategoryContent",
  components: {
    EmptyDataFeaturesTable,
    TestETable,
    LoadingSpinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    currentCategoryId: {},
  },
  computed: {
    ...mapGetters({
      category: "estimates/currentEstimate/currentCategory",
      isEdit: "project/isEdit",
    }),

    totalHours() {
      const hours = this.category(this.currentCategoryId).features.map((feature) => feature.hours);
      let result = 0;
      for (let i = 0; i < hours.length; i++) {
        result += Number(hours[i]);
      }
      return result;
    },

    totalCost() {
      const costs = this.category(this.currentCategoryId).features.map(
        (feature) => feature.hours * this.category(this.currentCategoryId).ratePerHour
      );
      let result = 0;
      for (let i = 0; i < costs.length; i++) {
        result += Number(costs[i]);
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      updateStatus: "estimates/categories/updateCategoryStatus",
      deleteCategory: "estimates/categories/deleteCategory",
      getFeatures: "estimates/categories/features/getFeatures",
      hideCategory: "estimates/categories/hideCategory",
      unhideCategory: "estimates/categories/unhideCategory",
    }),

    async deleteEstimateCategory() {
      await this.deleteCategory({ estimateId: this.projectId, categoryId: this.currentCategoryId });
      this.$emit("changeCurrentCategory");
    },

    async changeStatus(updatedCategory) {
      await this.updateStatus({
        estimateId: this.projectId,
        id: this.currentCategoryId,
        updatedCategory,
      });
    },

    async onHide() {
      await this.hideCategory({
        estimateId: this.projectId,
        categoryId: this.currentCategoryId,
        date: new Date(),
      });
    },

    async onUnhide() {
      await this.unhideCategory({
        estimateId: this.projectId,
        categoryId: this.currentCategoryId,
      });
    },
  },
};
</script>

<style scoped>

</style>
