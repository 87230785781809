<template>
  <single-select
    :allItems="['To do', 'In progress', 'Done']"
    v-model="/* eslint-disable vue/no-mutating-props */ context.model"
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :data-type="context.type"
    v-bind="context.attributes"
  >
    <template v-slot:default="slotProps">
      <estimate-status-option :estimate-type="slotProps.option.label" />
    </template>
  </single-select>
</template>

<script>
import SingleSelect from "../forms/SingleSelect";
import EstimateStatusOption from "../estimate-status/EstimateStatusOption";

export default {
  name: "FormulateEstimatesStatusSelect",
  components: { SingleSelect, EstimateStatusOption },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
};
</script>
