<template>
  <div @click="$emit('click')" class="flex space-x-4 sm:space-x-8">
    <span
      class="cursor-pointer items-center px-1 pt-1 border-b-2 font-medium"
      :class="[
        current
          ? 'border-indigo-500 text-gray-900 inline-flex '
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex',
      ]"
    >
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    current: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
