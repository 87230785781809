<template>
  <row class="text-sm" :class="[rowClasses, borderClasses]">
    <div class="py-4 whitespace-nowrap text-sm font-medium text-neutral-1000 cursor-move" v-if="isEdit">
      <icon-drag />
    </div>
    <formulate-editable-content
      v-if="isEdit"
      :initial-value="feature.name"
      @update="onChangeName"
      placeholder="Feature name"
      validation="required:trim"
    />
    <static-content-label :show-tooltip="feature.name.length >= 50" :tooltip-value="feature.name" type="black" v-else>{{
      feature.name
    }}</static-content-label>
    <static-content-label class="text-right">${{ ratePerHour }}</static-content-label>
    <formulate-editable-content
      v-if="isEdit"
      :initial-value="feature.hours"
      @update="onChangeHours"
      :exist-suffix="true"
      :text-align-right="true"
      placeholder="Hours"
      validation="required|min:0,value|max:10000,value|number"
      :need-right-padding="false"
    >
      <template #suffix> <span class="text-neutral-500 leading-5 text-sm">h</span> </template>
    </formulate-editable-content>
    <static-content-label v-else class="text-right">{{ feature.hours }} h </static-content-label>
    <static-content-label class="text-right"> ${{ ratePerHour * feature.hours }}</static-content-label>
    <div
      class="whitespace-nowrap text-sm font-medium flex justify-center items-center text-purple-500 cursor-pointer"
      v-if="isEdit"
      @click="onDelete"
    >
      <icon-trash-outline class="icon-delete" color="#7F39FB" />
    </div>
  </row>
</template>

<script>
import IconDrag from "@/components/icons/IconDrag";
import StaticContentLabel from "@/components/tables/StaticContentLabel";
import Row from "@/components/tables/rows/Row";
import IconTrashOutline from "@/components/icons/IconTrashOutline";
import { mapGetters, mapActions } from "vuex";
import FormulateEditableContent from "@/components/formulate-inputs/FormulateEditableContent";

export default {
  name: "FeatureRow",
  components: { IconDrag, StaticContentLabel, IconTrashOutline, Row, FormulateEditableContent },
  model: {
    prop: "feature",
    event: "change",
  },
  props: {
    feature: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
    },

    categoryId: {},

    withBorder: {
      type: Boolean,
      default: true,
    },

    ratePerHour: {},
  },
  methods: {
    ...mapActions({
      updateFeature: "estimates/currentEstimate/updateFeature",
      deleteFeature: "estimates/currentEstimate/deleteFeature",
    }),

    async onDelete() {
      await this.deleteFeature({
        estimateId: this.projectId,
        categoryId: this.categoryId,
        featureId: this.feature.id,
      });
    },
    async onChangeName(value) {
      await this.updateFeature({
        estimateId: this.projectId,
        categoryId: this.categoryId,
        feature: {
          ...this.feature,
          name: value,
        },
        id: this.feature.id,
      });
    },

    async onChangeHours(value) {
      await this.updateFeature({
        estimateId: this.projectId,
        categoryId: this.categoryId,
        feature: {
          ...this.feature,
          hours: value,
          cost: this.feature.ratePerHour * value,
        },
        id: this.feature.id,
      });
    },
  },
  computed: {
    rowClasses() {
      return this.isEdit ? "draggable grid-cols-features-edit-mode" : "grid-cols-features-view-mode";
    },

    borderClasses() {
      return this.withBorder ? "border-b border-gray-200" : "";
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
};
</script>

<style scoped>
.icon-delete {
  @apply h-5 w-5;
}
</style>
