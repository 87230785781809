<template>
  <div class="w-full flex justify-center items-center" :class="heightClass">
    <svg class="h-20 w-20 rounded-full border-b-2 border-indigo-500" :class="animateClass"></svg>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    fast: {
      type: Boolean,
      default: false,
    },

    fullScreenHeight: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    animateClass() {
      return this.fast ? "animate-spin-fast" : "animate-spin";
    },

    heightClass() {
      return this.fullScreenHeight ? "h-screen" : "h-full";
    },
  },
};
</script>
