<template>
  <layout>
    <div class="max-w-sm mx-auto">
      <FormulateForm v-model="fields" @submit="saveChanges" class="flex flex-col divide-y divide-gray-200 space-y-3" name="ProfileNameForm">
      <avatar-picker @success="openModal">
        <FormulateInput
          label="Save changes"
          type="submit"
          class="ml-12 save-btn inline-flex w-32 justify-center items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        />
      </avatar-picker>
      <div class="py-2">
        <h3 class="mt-3 font-bold">Profile details</h3>
          <FormulateInput
            error-class="mt-2 text-sm text-red-600"
            input-class="block mt-1 w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
            label="Name"
            label-class="block text-gray-900"
            class="mt-2"
            name="name"
            validation="required:trim|max:32,length"
            error-behavior="submit"
          />
          <FormulateInput
            error-class="mt-2 text-sm text-red-600"
            input-class="block mt-1 w-full text-gray-400 font-weight-400 rounded-md border-none focus:ring-indigo-500 sm:text-sm p-0"
            input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
            label="Email"
            label-class="block text-gray-900"
            class="mt-2"
            name="email"
            validation="required|email"
            error-behavior="submit"
            disabled
          />

      </div>
      </FormulateForm>
    </div>
    <div class="mt-12 max-w-sm mx-auto">
      <e-button type="secondary" class="text-bold w-32" @click="logOut">
        <icon-log-out class="h-5 w-5 mr-2" />
        Sign out
      </e-button>
    </div>
    <modal name="my-first-modal" width="380" height="230">
      <congratulations-update-profile-modal @close="closeModal" />
    </modal>
  </layout>
</template>

<script>
import Layout from "../layout/Layout";
import AvatarPicker from "@/components/forms/AvatarPicker";
import CongratulationsUpdateProfileModal from "@/components/modals/CongratulationsUpdateProfileModal";
import EButton from "../components/base/EButton";
import IconLogOut from "../components/icons/IconLogOut";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SettingsPage",
  components: { Layout, AvatarPicker,  CongratulationsUpdateProfileModal, EButton, IconLogOut },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  data(){
    return {
      fields: {
        name : "",
        email : "",
        avatar : null
      }
    }
  },
  methods: {
    ...mapActions({
      setUserName: "user/updateUserName",
      deleteToken: "user/deleteToken"
    }),

    logOut() {
      this.deleteToken();
      this.$router.push({ name: "Login" });
    },

    async saveChanges() {
      await this.setUserName(this.fields.name);
      this.openModal();
    },

    openModal() {
      this.$modal.show("my-first-modal");
    },

    closeModal() {
      this.$modal.hide("my-first-modal");
    },
  },

  created() {
    this.fields.name = this.user.name;
    this.fields.email = this.user.email
    this.fields.avatar = this.user.avatar
  }
};
</script>
