<template>
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.8515 0.898205C7.75261 0.799232 7.63538 0.749786 7.49994 0.749786H0.500038C0.364534 0.749786 0.247392 0.799232 0.148419 0.898205C0.0494455 0.997288 0 1.11443 0 1.24985C0 1.38525 0.0494455 1.50239 0.148419 1.60139L3.64838 5.10135C3.74746 5.20032 3.86461 5.24988 4 5.24988C4.13539 5.24988 4.25265 5.20032 4.35154 5.10135L7.8515 1.60136C7.95036 1.50239 8 1.38525 8 1.24982C8 1.11443 7.95036 0.997288 7.8515 0.898205Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name : "IconStatusSelectArrowDown"
}
</script>
