<template>
  <div
    class="px-4 py-3 flex flex-row space-x-4 items-center cursor-pointer border shadow rounded-sm"
    :class="visibilityClasses"
    @click="inviteUser"
  >
    <invite-user-avatar />
    <span class="text-sm leading-5 text-neutral-500">Invite ‘{{ currentText }}’ by email</span>
  </div>
</template>

<script>
import InviteUserAvatar from "@/components/InviteUserAvatar";
import {validate} from "email-validator";

export default {
  name: "NoOptionsTeamInput",
  components: { InviteUserAvatar },
  props: {
    currentText: {
      type: String,
      required: true,
    },
  },

  computed : {
    visibilityClasses(){
      return validate(this.currentText) ? "" : "hidden";
    }
  },

  methods: {
    inviteUser() {
      this.$emit("inviteUser");
    },
  },
};
</script>
