<template>
  <div class="fixed z-50 top-0 right-0 left-0 overlay-background flex justify-center items-center" @click="checkOut" :class="[isFooterVisible ? 'bottom-14' : 'bottom-0']">
      <div v-if="showArrows" class="h-full absolute flex left-0 p-8 items-center justify-center text-white">
        <button @click="movePrev" id="movePrev">
          <icon-chevron-left/>
        </button>
      </div>
      <component :is="currentComponent" :file="file" @close="$emit('close')"/>
      <div v-if="showArrows" class="h-full absolute flex right-0 p-8 items-center justify-center text-white">
        <button @click="moveNext" id="moveNext">
          <icon-chevron-right/>
        </button>
      </div>
  </div>
</template>

<script>


import { mapGetters} from "vuex";
import IconClose from "../icons/IconClose";
import IconChevronLeft from "../icons/IconChevronLeft";
import IconChevronRight from "../icons/IconChevronRight";
import PdfWithLoading from "./PdfWithLoading";
import PhotoView from "./PhotoView";
import PdfView from "./PdfView";
import ClickOutside from "vue-click-outside";

export default {
  name : "FileModal",
  directives : {
    ClickOutside
  },
  components : {
    IconClose,
    IconChevronLeft,
    IconChevronRight,
    PdfWithLoading,
    PdfView,
    PhotoView
    // pdf
  },
  props : {
    file : {},
    showArrows: {
      type : Boolean,
      default : true
    }
  },

  data(){
    return{
      currentWidth : null,
    }
  },


  // watch : {
  //   file : {
  //     deep : true,
  //     handler : "check"
  //   }
  // },
  methods : {
    //
    // check(){
    //   console.log(getContainedSize(document.getElementById('file-img')))
    //   setTimeout(() =>  this.currentWidth = getContainedSize(document.getElementById('file-img'))[0], 200)
    //
    // },
    moveNext(){
      this.$emit('moveNext')
    },

    movePrev(){
      this.$emit('movePrev')
    },

    close(){
      this.$emit('close')
    },

    checkOut(event){
      if(
        event.composedPath().includes(document.getElementById('movePrev')) ||
        event.composedPath().includes(document.getElementById('moveNext')) ||
        event.composedPath().includes(document.getElementById('file-header'))
      ){
        return;
      }

      if( event.composedPath().includes(document.getElementById('file-img')) && !this.isPdf)
      {
        return
      }

      if( event.composedPath().includes(document.getElementById('file-pdf')) && this.isPdf)
      {
        return
      }

      else
      {
        this.close()
      }
    }
  },
  computed : {
    ...mapGetters({
      categories : "estimates/currentEstimate/categories"
    }),

    isPdf(){
      const re = /.*\.pdf$/

      return re.test(this.file.url)
    },

    currentComponent(){
      return this.isPdf ? 'PdfView' : 'PhotoView'
    },

    isFooterVisible(){
      return this.categories.length > 0
    }
  },
  errorCaptured() {
    return false
  },
  //
  // mounted() {
  //   console.log(document.getElementById('file-img'));
  //   document.getElementById('file-img').addEventListener('load', () => this.check())
  // }
}

</script>

<style>
.overlay-background{
  background-color: rgba(17, 24, 39, 0.5);
}

.pdf{
  overflow: hidden;
  max-width: calc(100vw - 80px);
  overflow-x:scroll;
}


@media screen and (min-width: 824px) {
  .pdf{
    overflow: hidden;
    max-width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .pdf{
    overflow: hidden;
    max-width: 1024px;
  }
}

.global-cont{
  max-height: calc(90% - 80px);
  max-width: calc(100% - 130px);
  overflow-x:hidden;
}
</style>
