<template>
  <div class="pb-2">
    <h3 class="text-xl mb-6 leading-7" v-if="files.length || isEdit">Files:</h3>
    <ul role="list" class="grid-files">
      <li class="h-48" v-for="(form,index) in forms" :key="form.uid" :style="{order : index === 0 ? 0 : 100000}">
        <FileForm :project-id="projectId" @uploadCompleted="deleteFileForm" @add-new-file-form="addNewFileForm"  :uid="form.uid" @update="updateFiles" @largeSize="openLargeFileSizeModal"/>
      </li>
      <li v-for="file in files" :key="file.id" class="relative">
        <FileItem @openFile="openFile" :file="file" @deleteFile="deleteFile" />
      </li>
    </ul>
    <warning-too-large-file-size />
    <file-viewer v-if="showFileModal" :initialFileId="initialFileId" @close="showFileModal = false"/>
  </div>
</template>

<script>
import FileForm from "@/components/files/FileForm";
import FileItem from "@/components/files/FileItem";
import { getFiles, deleteFile } from "@/api/apiFiles";
import { mapGetters, mapActions } from "vuex";
import { v4 as uuid } from "uuid";
import WarningTooLargeFileSize from "../modals/WarningTooLargeFileSize";
import FileViewer from "../FileViewer";

export default {
  name: "FileUploader",
  components: { FileForm, FileItem, WarningTooLargeFileSize, FileViewer},
  props: {
    projectId: {
      type: Number,
      required: true,
    },

    initialState : {

    }
  },
  data() {
    return {
      files: [],
      forms : [],
      file : null,
      showFileModal : false,
      initialFileId : null
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
      estimateFiles : "estimates/currentEstimate/files"
    }),
  },
  methods: {
    ...mapActions({
      updateFilesList : 'estimates/currentEstimate/updateFiles'
    }),

    addNewFileForm(uid){
      this.forms = this.forms.map(f => f.uid === uid ? {...f,loading : true} : f)
      this.forms.unshift({
        uid: uuid(),
        loading: false
      })
    },

    async deleteFileForm(uid){
      this.forms = this.forms.filter(f => f.uid !== uid)
    },

    updateFiles(files)
    {
      this.files = files;
      this.updateFilesList(files)
    },

    openFile(file){
      this.initialFileId = file.id
     this.showFileModal =  true
    },

    openLargeFileSizeModal(){
      this.$modal.show('too-large-file-size-modal')
    },

    async deleteFile(fileId) {

      await deleteFile(this.projectId, fileId);
      this.files = this.files.filter(f => f.id !== fileId);
      this.updateFilesList(this.files)
    },

    async uploadFiles() {
      this.files = await getFiles(this.projectId);
    },
  },
   created() {
    this.forms.push({
      uid : uuid(),
      loading : false
    })
    this.files = this.estimateFiles;
  },
};
</script>

<style>
.grid-files {
  display: grid;
  grid-template-columns: repeat(auto-fit, 192px);
  gap: 8px;
}
</style>
