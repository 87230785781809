<template>
  <side-over @clickAway="closeAndSaveData">
    <div class="side-over h-screen flex flex-col" v-if="!loadingSpinner">
      <FormulateForm
        @submit="onSubmit"
        class="divide-gray-200 flex flex-col flex-1 bg-white shadow-xl h-full"
        v-model="fields"
      >
        <section-header-side-over
          :header="header"
          :subheader="subheader"
          @buttonCloseClick="closeAndSaveData"
          class="border-b border-gray-200"
        />
        <estimate-form
          :project-id="projectId"
          :is-empty-name-allowed="!projectId"
          :deadline-date="deadlineDate"
          class="flex-1"
          @leaveAndClose="$emit('leaveAndClose', $event)"
        />

        <side-over-footer
          @archive="onArchive"
          class="border-t border-neutral-500"
          :loading="loading"
          :can-archive="ownerId === user.id"
        />
      </FormulateForm>
    </div>
    <loading-spinner class="bg-white" v-else />
  </side-over>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import EstimateForm from "@/components/forms/EstimateForm";
import SideOver from "@/layout/SideOver";
import SectionHeaderSideOver from "@/components/SectionHeaderSideOver";
import SideOverFooter from "@/components/SideOverFooter";
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapGetters, mapActions } from "vuex";
import _debounce from "lodash.debounce";

export default {
  name: "SideOverNewProject",
  mixins: [clickaway],
  components: {
    EstimateForm,
    SectionHeaderSideOver,
    SideOver,
    SideOverFooter,
    LoadingSpinner,
  },
  props: {
    projectId: {
      type: Number,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: {
        name: "",
        deadline: null,
        team: [],
        categories: [],
      },
      loadingSpinner: false,
      archived: null,
      deadlineDate: new Date(),
      ownerId: null,
    };
  },
  methods: {
    ...mapActions({
      close : "sideOver/close",
      getAllUsers : "users/getAllUsers",
      updateEstimateName : "estimates/currentEstimate/updateEstimateName",
      updateEstimateDeadline : "estimates/currentEstimate/updateEstimateDeadline",
      updateEstimate: "estimates/currentEstimate/updateEstimate"
    }),

    async closeAndSaveData(){
      await this.updateEstimateName({estimateId : this.currentEstimate.id , name : this.fields.name})
      this.close()
    },

    onArchive(projectId) {
      this.$emit("archiveProject", projectId);
      this.close();
    },
    onSubmit() {
      if (!this.isCreating && this.user.id === this.ownerId) {
        this.$emit("updateEstimate", { ...this.fields, id: this.currentEstimate.id });
      }

      if (!this.isCreating && this.user.id !== this.ownerId) {
        this.$emit("updateEstimateByNotOwner", { ...this.fields, id: this.currentEstimate.id });
      }

      if (this.isCreating) {
        this.$emit("addEstimate", this.fields);
      }

      this.close()
    },

    updateEstimateDebounced : _debounce(function () {
      if(this.currentEstimate) {
        this.updateEstimateName({ estimateId: this.currentEstimate.id, name: this.fields.name })
      }
    }, 1000)
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isCreating : "sideOver/isCreating",
      currentEstimate : "sideOver/currentEstimate"
    }),

    header() {
      return !this.isCreating ? "Settings" : "New estimate";
    },
    subheader() {
      return !this.isCreating
        ? this.currentEstimate.owner_id === this.user.id
          ? "Setup the information about the project and control all parts of details."
          : "Setup the information about the project and control all parts of details. The name and deadline can only be changed by the owner of the estimate"
        : "Get started by filling in the information below to create your new project. ";
    },
  },
  async created() {
    this.loadingSpinner = true;

    await this.getAllUsers()

    this.fields = {};
    if (!this.isCreating) {
      const response = this.currentEstimate;
      this.archived = !!response.archived_at;
      this.fields = {
        ...response,
        deadline : response.deadline + "T00:00:00.001Z",
        team: [],
      };
      this.ownerId = response.owner_id;
    } else {
      this.fields = {
        name: "",
        deadline: new Date().toISOString(),
        team: [],
        categories: []
      };
    }

    this.loadingSpinner = false;
  },

  watch : {
    // 'fields.name': function() {
    //   this.updateEstimateName({estimateId : this.currentEstimate.id , name : this.fields.name})
    // }

    'fields.name' : {
      handler : "updateEstimateDebounced"
    },
    'fields.deadline' : async function(){
      await this.updateEstimateDeadline({estimateId : this.currentEstimate.id , deadline : this.fields.deadline.substr(0,10)})
    }
  },

  async destroyed() {
    // await this.updateEstimate({
    //   id : this.currentEstimate.id,
    //   name : this.fields.name,
    //   team : this.fields.team,
    //   deadline : this.fields.deadline.substr(0,10),
    //   categories : this.fields.categories
    // })
    // this.close({
    //   id : this.currentEstimate.id,
    //   name : this.fields.name,
    //   team : this.fields.team,
    //   deadline : this.fields.deadline.substr(0,10),
    //   categories : this.fields.categories
    // })

    this.close();
  }
};
</script>
