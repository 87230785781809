<template>
  <div class="flex -space-x-1 overflow-hidden" :class="[shrinkClasses, opacityClasses]">
    <user-avatar
      size="small"
      v-for="member in visibleListOfUsers"
      :key="member.id"
      class="inline-block ring-2 ring-white"
      :avatar="member.avatar"
      alt="team member"
    />
    <div
      v-if="maxCount < team.length"
      class="border rounded-full w-8 h-8 flex justify-center items-center shadow-sm z-20 bg-white"
    >
      +{{ this.team.length - this.maxCount }}
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "ListOfUserAvatars",
  components: { UserAvatar },
  props: {
    team: {
      type: Array,
    },
    active: {
      type: Boolean,
      default: true,
    },
    allowShrink: {
      type: Boolean,
      default: true,
    },

    maxCount: {
      type: Number,
    },
  },
  computed: {
    shrinkClasses() {
      return this.allowShrink ? "flex-1" : "";
    },

    visibleListOfUsers() {
      if (this.maxCount > this.team.length) {
        return this.team;
      } else {
        const result = [];

        for (let i = 0; i < this.maxCount; i++) {
          result.push(this.team[i]);
        }

        return result;
      }
    },

    opacityClasses() {
      return this.active ? "" : "opacity-50";
    },
  },
};
</script>
