<template>
  <div class="py-6 px-4 bg-gray-50 sm:px-6">
    <div class="flex items-center justify-between">
      <h2 class="text-xl font-normal text-gray-900" id="slide-over-title">{{ header }}</h2>
      <div class="ml-3 h-7 flex">
        <button-close-side-over @click="onButtonCloseClick" />
      </div>
    </div>
    <div class="mt-1">
      <p class="text-sm text-gray-500">
        {{ subheader }}
      </p>
    </div>
  </div>
</template>

<script>
import ButtonCloseSideOver from "@/components/ButtonCloseSideOver";

export default {
  name: "SectionHeaderSideOver",
  components: { ButtonCloseSideOver },
  props: {
    header: {
      type: String,
    },
    subheader: {
      type: String,
    },
  },
  methods: {
    onButtonCloseClick() {
      this.$emit("buttonCloseClick");
    },
  },
  emits: ["ButtonCloseClick"],
};
</script>
