<template>
  <div class="fixed bottom-0 py-4 px-6 feature-form w-screen bg-white flex flex-row items-start space-x-4">
    <part-select-input v-model="categoryId" :categories="categories" :estimate-id="estimateId"/>
    <test-add-feature-form :category-id="categoryId" class="flex-1"/>
  </div>
</template>

<script>
import PartSelectInput from "./PartSelectInput";
import TestAddFeatureForm from "./forms/TestAddFeatureForm";
import { mapGetters } from "vuex";
export default {
  name : "FeatureFormFooter",
  components : { TestAddFeatureForm, PartSelectInput},
  props : {
    categories : {

    },
  },
  data(){
    return{
      categoryId : this.categories[0].id
    }
  },

  computed : {
    ...mapGetters({
      estimateId : 'estimates/currentEstimate/currentEstimateId'
    })
  }
}
</script>

<style scoped>
.feature-form{
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.15);
}
</style>
