<template>
  <img
    class="rounded-full bg-gray-50 overflow-hidden"
    v-if="avatar && !isError"
    :src="avatar"
    alt="User avatar"
    :class="sizeClass"
    @error="changeSource"
  />
  <icon-user v-else class="rounded-full bg-gray-50 border border-purple-100" fill="#DEE6FF" :class="sizeClass" />
</template>

<script>
import IconUser from "@/components/icons/IconUser";

export default {
  components: { IconUser },
  data() {
    return {
      isError: false,
    };
  },
  props: {
    avatar: {},

    size: {
      type: String,
      default: "small",
      validator: (value) => ["small", "medium", "large"].indexOf(value) !== -1,
    },
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case "small":
          return "small";
        case "medium":
          return "medium";
        case "large":
          return "large";
        default:
          return "";
      }
    },
  },

  methods: {
    changeSource() {
      this.isError = true;
    },
  },
};
</script>

<style scoped>
.small {
  min-height: 2rem;
  min-width: 2rem;
  height: 2rem;
  width: 2rem;
}

.medium {
  min-height: 3rem;
  min-width: 3rem;
  height: 3rem;
  width: 3rem;
}

.large {
  min-height: 6.5rem;
  min-width: 6.5rem;
  height: 6.5rem;
  width: 6.5rem;
}
</style>
