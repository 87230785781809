<template>
  <div>
    <div class="align-middle inline-block min-w-full">
      <div class="shadow overflow-hidden border border-gray-200" :class="roundedClasses">
        <div class="min-w-full">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableLayout",
  props: {
    rounded: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    roundedClasses() {
      return this.rounded ? "rounded-lg" : "";
    },
  },
};
</script>
