<template>
  <div
    class="inset-0 flex items-end justify-center px-4 pt-6 pointer-events-none sm:pt-6 sm:items-start sm:justify-end"
  >
    <div
      class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <icon-x-circle />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-red-700">
              {{ item.title }}
            </p>
            <p class="mt-1 text-sm text-gray-500">
              {{ item.text }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              @click="close"
              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Close</span>
              <icon-x />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconXCircle from "@/components/icons/IconXCircle";
import IconX from "@/components/icons/IconX";

export default {
  name: "ErrorToast",
  components: { IconX, IconXCircle },
  props: {
    close: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
