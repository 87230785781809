<template>
  <div class="flex justify-between">
    <div class="flex flex-col space-y-3">
      <div class="text-2xl leading-7 font-bold">
        {{ name }}
      </div>
    </div>

    <div>
      <icon-nerd-logo-with-text />
    </div>
  </div>
</template>

<script>
import IconNerdLogoWithText from "@/components/icons/IconNerdLogoWithText";

export default {
  name: "PdfHeader",
  components: { IconNerdLogoWithText },
  props: {
    name: {
      type: String,
      required: true,
    },

    deadline: {},
  },
};
</script>
