<template>
  <div>
    <file-uploader :project-id="estimate.id" class="mt-2"/>
    <project-description :initial-state="estimate.description" :project-id="estimate.id" />
<!--    <file-viewer v-if="showFileViewer" @close="showFileViewer = false"/>-->
<!--    <pdf :src="estimate.files[0].url"></pdf>-->
  </div>
</template>

<script>
import FileUploader from "./files/FileUploader";
import ProjectDescription from "./ProjectDescription";
// import pdf from 'vue-pdf'

export default {
  name : "ProjectPageInfo",
  components : { FileUploader, ProjectDescription },
  props : {
    estimate : {}
  },
}
</script>
