<template>
  <div class="h-12 w-12 bg-green-100 rounded-full flex justify-center items-center">
    <IconEmail class="w-5 h-5" color="#059669" />
  </div>
</template>

<script>
import IconEmail from "@/components/icons/IconEmail";

export default {
  name: "SuccessEmail",
  components: { IconEmail },
};
</script>
