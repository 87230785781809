<template>
  <div
    class="flex justify-between cursor-pointer hover:bg-gray-200 w-full pr-8 border-l-4 border-indigo-600"
    :class="[current ? 'border-opacity-100 bg-gray-200' : 'border-opacity-0']"
    @click.prevent="chooseCurrent"
    @mouseover="showButtons = true"
    @mouseleave="showButtons = false"
  >
    <div class="pl-4 py-2 w-full">
      <status-select
        :status="category.status"
        :hidden="category.hidden"
        @select="changeStatus"
        :id="`status-select-${category.id}`"
      />
      <div class="w-48 truncate" :class="[hidden ? 'text-neutral-500' : '', current ? 'text-indigo-600' : '']">
        <span
          :title="category.name"
          class="text-gray-900"
          :class="[hidden ? 'text-neutral-500' : '', current ? 'text-indigo-600' : '']"
        >
          {{ category.name }}
        </span>
      </div>
    </div>

    <div class="flex items-center mr-2" v-if="true" :id="`hide-buttons-${category.id}`"    >
      <settings-list
        class="z-30"
        v-click-outside="closeMenu"
        @openMenu="openMenu"
        :is-settings-open="isMenuOpen"
        :id="`menu-content-${category.id}`"
      >
        <div class="py-1 z-50" role="none">
          <button class="menu-item" role="menuitem" @click="showDuplicateModal">
            <IconDuplicate class="mr-3 h-5 w-5 text-neutral-500 group-hover:text-gray-500" />
            Duplicate
          </button>
          <button class="menu-item" role="menuitem" @click="onOpenSettings">
            <IconPencilAltOutline class="mr-3 h-5 w-5 text-neutral-500 group-hover:text-gray-500" color="#818E9C" />
            Edit
          </button>
          <button class="menu-item" role="menuitem" @click="toggleHidden">
            <IconEye v-if="category.hidden" class="mr-2"/>
            <IconEyeOff class="mr-2" v-else/>
            {{ category.hidden ? "Unhide" : "Hide" }}
          </button>
        </div>
      </settings-list>
    </div>
  </div>
</template>

<script>

import StatusSelect from "@/components/StatusSelect";
import { mapGetters, mapActions } from "vuex";
import formatHideUnhideDate from "@/utils/formatHideUnhideDate";
import SettingsList from "../SettingsList";
import IconDuplicate from "../icons/IconDuplicate";
// import IconSettings from "../icons/IconSettings";

import ClickOutside from "vue-click-outside";
import IconEye from "../icons/IconEye";
import IconEyeOff from "../icons/IconEyeOff";
import IconPencilAltOutline from "../icons/IconPencilAltOutline"
export default {
  name: "ScrollNavbarOption",
  components: { IconEyeOff, IconEye, SettingsList, StatusSelect, IconDuplicate,  IconPencilAltOutline },
  directives : {
    ClickOutside
  },
  methods: {
    ...mapActions({
      hideCategory: "estimates/currentEstimate/hideCategory",
      unhideCategory: "estimates/currentEstimate/unhideCategory",
      updateCategoryStatus: "estimates/currentEstimate/updateCategory",
      openSettings: "sideOver/openSettingsMode",
      duplicateCategory: "estimates/currentEstimate/duplicateCategory"
    }),

    chooseCurrent(event) {
      const clickedToStatusSelect = event
        .composedPath()
        .includes(document.getElementById(`status-select-${this.category.id}`));

      const clickedToHideButtons = event
        .composedPath()
        .includes(document.getElementById(`hide-buttons-${this.category.id}`));

      if ((!clickedToStatusSelect || this.category.hidden) && !clickedToHideButtons)
        this.$emit("changeCurrent", this.category.id);
    },

    onOpenSettings() {
      this.closeMenu();
      this.openSettings(this.currentEstimate)
    },

    openMenu(){
      this.isMenuOpen = !this.isMenuOpen;
    },

    async toggleHidden(){
      if(this.category.hidden) await this.unhideCurrentCategory()
      else await this.hideCurrentCategory()
    },

    closeMenu(){
      this.isMenuOpen = false
    },

    onClickSettings(){
      this.closeMenu()
      // this.openSettings()
    },

    showDuplicateModal(){
      this.$modal.show('confirm-duplicate', {category : this.category});
    },

    async hideCurrentCategory() {
      await this.hideCategory({
        estimateId: this.estimateId,
        categoryId: this.category.id,
        updatedCategory: {
          ...this.category,
          hidden: formatHideUnhideDate(new Date()),
        },
      });
    },

    async unhideCurrentCategory() {
      await this.unhideCategory({
        estimateId: this.estimateId,
        categoryId: this.category.id,
        updatedCategory: {
          ...this.category,
          hidden: false,
        },
      });
    },

    async changeStatus(status) {
      await this.updateCategoryStatus({
        estimateId: this.estimateId,
        id: this.category.id,
        updatedCategory: {
          ...this.category,
          status,
        },
      });
    },
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    current: {
      type: Boolean,
      required: true,
    },

    hidden: {
      required: true,
    },
  },
  data() {
    return {
      showButtons: false,
      isMenuOpen : false
    };
  },
  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
      estimateId: "estimates/currentEstimate/currentEstimateId",
      currentEstimate: "estimates/currentEstimate/currentEstimate",
    }),

    statusClasses() {
      if (this.hidden) return "text-neutral-500";
      switch (this.category.status) {
        case "To do":
          return "text-blue-500";
        case "In progress":
          return "text-yellow-500";
        case "Done":
          return "text-green-500";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.menu-item {
  @apply group w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
}
</style>

