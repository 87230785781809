<template>
  <div>
    <div>
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-4" aria-label="Tabs">
          <span   v-for="tab in tabs" :key="tab.name" @click="changeTab(tab.name)"  :class="[tab.name == currentTab ? 'border-indigo-500 text-neutral-1000' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
            {{ tab.name }}
          </span>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
const tabs = [
  { name: 'Estimate'},
  { name: 'Details'},
]

export default {
  name : "ProjectPageTab",
  model : {
    prop : "currentTab",
    event : "change"
  },
  props : {
    currentTab : {}
  },
  data(){
    return{
      tabs
    }
  },
  methods : {
    changeTab(tab){
      this.$emit('change', tab);
    }
  }
}
</script>
