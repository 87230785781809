<template>
  <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.77 2.27L10 0.5L0 10.5L10 20.5L11.77 18.73L3.54 10.5L11.77 2.27Z" fill="#7F39FB" />
  </svg>
</template>
<script>
export default {
  name: "IconPrev",
};
</script>
