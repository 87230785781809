<template>
  <modal name="loading-spinner" width="400" height="300">
    <div class="flex items-center justify-center h-full">
      <loading-spinner :full-screen-height="false" />
    </div>
  </modal>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "LoadingSpinnerModal",
  components: {
    LoadingSpinner,
  },
};
</script>
