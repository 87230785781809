<template>
  <span class="text-sm py-3 whitespace-nowrap relative truncate" :class="spanClasses">
    <span
      v-if="showTooltip"
      v-tooltip.bottom="{
        content: tooltipValue,
        classes: 'tooltip-2',
        delay: {
          show: 200,
        },
      }"
    >
      <slot />
    </span>
    <span v-else> <slot /> </span>
  </span>
</template>

<script>
export default {
  name: "StaticContentLabel",
  props: {
    type: {
      type: String,
      default: "grey",
      validator: function (value) {
        return ["grey", "black"].indexOf(value) !== -1;
      },
    },

    showTooltip: {
      type: Boolean,
      default: true,
    },

    tooltipValue: {
      type: String,
    },
  },
  computed: {
    spanClasses() {
      switch (this.type) {
        case "grey":
          return "text-neutral-500";
        case "black":
          return "text-neutral-1000";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.tooltip-2 {
  @apply relative mt-4  shadow-lg px-4 py-2 rounded-md text-gray-900;
  background: ghostwhite;
}

.tooltip-2 .tooltip-arrow {
  position: absolute;
  top: -8px;
  border-width: 0 16px 16px 16px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: ghostwhite !important;
  width: 0;
  height: 0;
}
</style>
