<template>
  <div class="grid grid-cols-8">
    <v-select
      @input="setSelectedItems"
      :clearable="false"
      class="block mt-1 theme-brand col-span-6"
      :options="allItems"
      :getOptionLabel="(user) => user.email"
      autofocus
      @search="test"
      ref="select"
      v-model="selectValue"
    >
      <template #header>
        <label class="block text-sm font-medium mb-1 text-gray-700">Team</label>
      </template>
      <template slot="option" slot-scope="option">
        <multiple-select-option :option="option" />
      </template>
      <template #open-indicator>
        <div></div>
      </template>
    </v-select>
    <e-button class="col-span-2 self-end ml-2" v-if="existData || validEmail">
      {{ existData ? "+ Add" : "Invite" }}
    </e-button>
    <div class="mt-5 col-span-8" v-if="context.model.length > 0">
      <ul
        class="divide-y divide-gray-100 mt-1 outline-none bg-white shadow-lg max-h-72 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
      >
        <multiple-select-selected-option
          v-for="item in context.model"
          :key="item.id"
          :item="item"
          @removeItem="removeItem"
          :admin="!!admins.map((admin) => admin.id).find((adminId) => adminId === item.id)"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import EButton from "../base/EButton";
import { validate } from "email-validator";
import { mapGetters } from "vuex";
import MultipleSelectOption from "@/components/MultipleSelectOption";
import MultipleSelectSelectedOption from "@/components/MultipleSelectSelectedOption";
import { getUsersByEmail } from "@/api/apiUsers";
import { getEstimateById } from "@/api/apiEstimates";

export default {
  name: "MultipleSelect",
  components: { MultipleSelectSelectedOption, MultipleSelectOption, EButton },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allItems: [],
      value: "",
      existData: false,
      validEmail: false,
      selectValue: "",
      admins: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),

    // array() {
    //   return this.mounted ? this.$refs.select.filteredOptions.length > 0 : true;
    // },

    // validEmail() {
    //   return validate(this.value);
    // },
  },
  methods: {
    removeItem(selectedItem) {
      /* eslint-disable vue/no-mutating-props */ this.context.model = this.context.model.filter(
        (item) => selectedItem.id !== item.id
      );
      this.allItems.push(selectedItem);
      this.test();
    },
    setSelectedItems(selectedItem) {
      this.selectValue = null;
      this.allItems = this.allItems.filter((item) => item.id !== selectedItem.id);
      /* eslint-disable vue/no-mutating-props */ this.context.model = [...this.context.model, selectedItem];

      // this.$emit("selectedItemsChanged", [...this.selectedItems, selectedItem]);
      this.test();
    },

    test(value) {
      if (value) {
        this.existData = this.allItems.find((user) => user.email.toLowerCase().includes(value.toLowerCase()));
        this.validEmail = validate(value);
      } else {
        this.existData = this.allItems.length > 0;
        this.validEmail = false;
      }
    },
  },
  async created() {
    const response = await getEstimateById(this.context.attributes.projectId);

    this.admins = response.users;
    this.allItems = await getUsersByEmail("");

    this.context.model.sort(
      (a, b) =>
        this.admins.find((adminUser) => adminUser.id === a.id) > this.admins.find((adminUser) => adminUser.id === b.id)
    );

    this.context.model.reverse();

    this.allItems = this.allItems.filter(
      (item) => !this.context.model.map((user) => user.id).find((userId) => item.id === userId)
    );
    this.existData = this.allItems.length > 0;
  },
  mounted() {
    this.mounted = true;
  },
};
</script>
