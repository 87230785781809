<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        class="absolute inset-y-0 left-0 pl-3 mr-7 flex items-center pointer-events-none max-height-dollar"
        v-if="existPrefix"
      >
        <span class="text-gray-500 sm:text-sm"> $ </span>
      </div>
      <FormulateInput
        type="text"
        :input-class="[
          'block w-full text-sm leading-5 border-gray-300 rounded-md',
          textAlignClasses,
          existPrefixClasses,
          existSuffixClasses,
        ]"
        input-has-errors-class="placeholder-red-300 border-red-300 focus:ring-0 focus:rind-red-300"
        :placeholder="placeholder"
        v-model="value"
        @keydown.enter.stop.prevent="onSubmit"
        @blur="onSubmit"
        error-behavior="live"
        error-class="hidden"
        ref="formulateInput"
        :validation="validation"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center" v-if="existSuffix">
        <slot name="suffix" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormulateEditableContent",
  props: {
    initialValue: {},
    existSuffix: {
      type: Boolean,
      default: false,
    },
    existPrefix: {
      type: Boolean,
      default: false,
    },
    textAlignRight: {
      type: Boolean,
      default: false,
    },
    needRightPadding: {
      type: Boolean,
      default: false,
    },
    placeholder: {},
    validation: {},
    inputMaxLength : {
      type : Number,
      default : 1000000
    }

    // context: {
    //   type: Object,
    //   required: true,
    // },
  },

  data() {
    return {
      value: this.initialValue,
    };
  },

  watch: {
    initialValue() {
      this.value = this.initialValue;
    },
  },

  computed: {
    textAlignClasses() {
      return this.textAlignRight ? "text-right" : "";
    },

    existPrefixClasses() {
      return this.existPrefix ? "pl-7" : "";
    },

    existSuffixClasses() {
      return this.existSuffix ? "pr-7" : "";
    },
  },

  methods: {
    onSubmit($event) {
      if (!this.$refs.formulateInput.isValid) {
        this.value = this.initialValue;
        return;
      }
      $event.target.blur();
      this.$emit("update", this.value);
    },

    change(value) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = value;
    },
  },

  mounted() {
    this.$refs.formulateInput.$el.querySelector("input").maxLength = this.inputMaxLength;
    // console.log(this.context.attributes);
    // console.log(this.context.attributes.validation);
    // // eslint-disable-next-line vue/no-mutating-props
    // this.context.model = this.context.attributes.initialValue;
  },
};
</script>

<style>
.max-height-dollar {
  max-height: 38.48px;
}
</style>

<!--<template>-->
<!--  <div>-->
<!--    <div class="mt-1 relative rounded-md shadow-sm">-->
<!--      <div-->
<!--        class="absolute inset-y-0 left-0 pl-3 mr-7 flex items-center pointer-events-none max-height-dollar"-->
<!--        v-if="context.attributes.existPrefix"-->
<!--      >-->
<!--        <span class="text-gray-500 sm:text-sm"> $ </span>-->
<!--      </div>-->
<!--      <FormulateInput-->
<!--        type="text"-->
<!--        :input-class="[-->
<!--          'block w-full pr-7 text-sm leading-5 border-gray-300 rounded-md',-->
<!--          textAlignClasses,-->
<!--          existPrefixClasses,-->
<!--        ]"-->
<!--        input-has-errors-class="placeholder-red-300 border-red-300 focus:ring-0 focus:rind-red-300"-->
<!--        :placeholder="context.attributes.placeholder"-->
<!--        @input="change"-->
<!--        :value="context.model"-->
<!--        @keydown.enter.stop.prevent="onSubmit"-->
<!--        @blur="onSubmit"-->
<!--        error-behavior="blur"-->
<!--        error-class="hidden"-->
<!--        ref="formulateInput"-->
<!--      />-->
<!--      <div class="absolute inset-y-0 right-0 pr-3 flex items-center" v-if="context.attributes.existSuffix">-->
<!--        <slot name="suffix" />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "FormulateEditableContent",-->
<!--  props: {-->
<!--    // initialValue: {},-->
<!--    // existSuffix: {-->
<!--    //   type: Boolean,-->
<!--    //   default: false,-->
<!--    // },-->
<!--    // existPrefix: {-->
<!--    //   type: Boolean,-->
<!--    //   default: false,-->
<!--    // },-->
<!--    // textAlignRight: {-->
<!--    //   type: Boolean,-->
<!--    //   default: false,-->
<!--    // },-->
<!--    // placeholder: {},-->

<!--    context: {-->
<!--      type: Object,-->
<!--      required: true,-->
<!--    },-->
<!--  },-->

<!--  watch: {-->
<!--    initialValue() {-->
<!--      this.value = this.initialValue;-->
<!--    },-->
<!--  },-->

<!--  computed: {-->
<!--    textAlignClasses() {-->
<!--      return this.textAlignRight ? "text-right" : "";-->
<!--    },-->

<!--    existPrefixClasses() {-->
<!--      return this.context.attributes.existPrefix ? "pl-7" : "";-->
<!--    },-->
<!--  },-->

<!--  methods: {-->
<!--    onSubmit($event) {-->
<!--      if (!this.$refs.formulateInput.isValid) return;-->
<!--      $event.target.blur();-->
<!--      this.$emit("update", this.context.model);-->
<!--    },-->

<!--    change(value) {-->
<!--      // eslint-disable-next-line vue/no-mutating-props-->
<!--      this.context.model = value;-->
<!--    },-->
<!--  },-->

<!--  created() {-->
<!--    console.log(this.context.attributes);-->
<!--    console.log(this.context.attributes.validation);-->
<!--    // eslint-disable-next-line vue/no-mutating-props-->
<!--    this.context.model = this.context.attributes.initialValue;-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style>-->
<!--.max-height-dollar {-->
<!--  max-height: 38.48px;-->
<!--}-->
<!--</style>-->
