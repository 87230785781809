<template>
  <modal name="confirm-leave" width="350" height="175" @before-open="initData">
    <div class="flex flex-col justify-center items-center p-8 space-y-4">
      <div class="grid grid-flow-col space-x-6">
        <warning-mark />
        <div class="space-y-2">
          <h3 class="font-bold leading-6 text-neutral-1000">Leave estimate</h3>
          <p class="text-sm leading-5 text-neutral-500">Are you sure you want to leave a estimate?</p>
        </div>
      </div>
      <div class="flex justify-end space-x-4 w-full block">
        <EButton type="secondary" @click="close">Cancel</EButton>
        <EButton type="error" @click="leaveAndClose">Leave</EButton>
      </div>
    </div>
  </modal>
</template>

<script>
import EButton from "@/components/base/EButton";
import WarningMark from "@/components/marks/WarningMark";

export default {
  name: "WarningLeaveEstimate",
  components: { EButton, WarningMark },
  data() {
    return {
      estimateId: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide("confirm-leave");
    },

    leaveAndClose() {
      this.$emit("leave", this.estimateId);
    },

    initData(event) {
      this.estimateId = event.params.estimateId;
    },
  },
};
</script>
