<template>
  <div class="h-12 w-12 bg-red-100 rounded-full flex justify-center items-center text-error-500">
    <IconExclamation class="w-5 h-5" />
  </div>
</template>

<script>
import IconExclamation from "@/components/icons/IconExclamation";

export default {
  name: "WarningMark",
  components: { IconExclamation },
};
</script>
