<template>
  <div>
    <div class="align-middle inline-block min-w-full">
      <div class="shadow overflow-hidden border border-gray-200 rounded-lg">
        <div class="min-w-full flex flex-col divide-y divide-gray-200" :class="isEdit ? 'table-min-height' : ''">
          <header-row />
          <empty-data-technologies v-if="!categories.length" />
          <draggable v-model="listOfCategories" tag="div" draggable=".draggable" class="flex flex-auto flex-col divide-y divide-gray-200" v-else>
            <technology-row
              v-for="(technology, index) in listOfCategories"
              class="h-16 py-2"
              :class="[isEdit ? 'draggable' : '']"
              :key="technology.id"
              :project-id="projectId"
              :technology="technology"
              :with-border="
                categories.length < 1 &&
                !additionalExpenses.length &&
                index === categories.length - 1 &&
                !(!additionalExpenses.length && showAdditionalExpenseForm)
              "
            />
          </draggable>
          <div
            class="w-full bg-neutral-100 py-3 px-4 text-neutral-1000 text-xs uppercase flex flex-row items-center"
            v-if="isEdit || additionalExpenses.length > 0"
          >
            <span class="mr-2">Additional expenses</span>
            <icon-information-circle v-if="isEdit" class="h-5 w-5" v-tooltip.bottom="{
            content: 'If You have some additional expenses, You can add them here',
            classes: 'tooltip-info-icon',
            delay: {
              show: 200,
            },
          }"/>
          </div>
          <additional-expense
            v-for="additionalExpense in additionalExpenses"
            :key="additionalExpense.name"
            :additional-expense="additionalExpense"
          />
          <additional-expense-form v-if="isEdit" :project-id="projectId"  />
<!--          <additional-expense-add-button-row-->
<!--            v-if="isEdit"-->
<!--            :is-additional-expense-form-open="isEdit && showAdditionalExpenseForm"-->
<!--            @toggleAdditionExpenseForm="showAdditionalExpenseForm = !showAdditionalExpenseForm"-->
<!--          />-->
          <total
            v-if="categories.length || totalHours || totalCost"
            :total-hours="totalHours"
            :total-cost="totalCost"
            :show-total-information="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TableLayout from "../../layout/TableLayout";
import HeaderRow from "../../components/tables/rows/total/HeaderRow";
import TechnologyRow from "../../components/tables/rows/total/TechnologyRow";
import AdditionalExpense from "../../components/tables/rows/total/AdditionalExpenseRow";
import Total from "../../components/tables/rows/total/TotalRow";
import AdditionalExpenseForm from "../../components/tables/rows/total/AdditionalExpenseForm";
import EmptyDataTechnologies from "@/components/empty-data/EmptyDataTechnologies";
// import AdditionalExpenseAddButtonRow from "@/components/tables/rows/total/AdditionalExpenseAddButtonRow";
import IconInformationCircle from "../icons/IconInformationCircle";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PartsAndAdditionalExpensesTable",
  props: {
    projectId: {
      type: Number,
    },
    categories: {
      type: Array,
      required: true,
    },
    additionalExpenses: {
      type: Array,
      required: true,
    },
  },
  components: {
    IconInformationCircle,
    HeaderRow,
    TechnologyRow,
    AdditionalExpense,
    AdditionalExpenseForm,
    Total,
    EmptyDataTechnologies,
    // AdditionalExpenseAddButtonRow,
    draggable
  },
  data() {
    return {
      showAdditionalExpenseForm: false,
    };
  },
  methods : {
    ...mapActions({
      updateCategoriesOrder : "estimates/currentEstimate/updateCategoriesOrder"
    })
  },
  computed: {
    totalHours() {
      return this.categories.map((technology) => technology.hours).reduce((sum, value) => sum + value, 0);
    },
    totalCost() {
      return (
        Number(
          this.categories
            .map((technology) => technology.ratePerHour * technology.hours)
            .reduce((sum, value) => sum + value, 0)
        ) +
        Number(
          this.additionalExpenses
            .map((additionalExpense) => Number(additionalExpense.cost))
            .reduce((sum, value) => sum + value, 0)
        )
      );
    },

    listOfCategories : {
      get(){
        return [...this.categories].sort((a,b) => a.order - b.order)
      },
      async set(value){
        await this.updateCategoriesOrder({
          estimateId :  this.projectId,
          newOrder : value.map((c, index) => { return {...c, order : index}  })
        })
      }
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
};
</script>

<style>
.edit-mode-cols {
  grid-template-columns: 1fr repeat(4, 8rem);
}

.view-mode-cols {
  grid-template-columns: 1fr repeat(3, 8rem);
}

.table-min-height {
  min-height: 16rem;
}

.tooltip-info-icon{
  @apply bg-gray-800 text-white text-sm p-2 rounded-lg mt-2
}
</style>
