<template>
  <div class="mt-8">
    <h4 class="leading-7 font-bold whitespace-nowrap">{{ category.name }} :</h4>
    <test-e-table
      class="mt-1"
      :isDraggable="false"
      :isEditable="false"
      :is-input-visible="false"
      :features="category.features"
      :rate-per-hour="category.ratePerHour"
    />
  </div>
</template>

<script>
import TestETable from "@/components/forms/TestETable";

export default {
  name: "CategoryOverview",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  components: { TestETable },
};
</script>
