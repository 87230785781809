import { axiosInstance } from "@/api/axiosInstances";
import { noAuthAxiosInstance } from "@/api/axiosInstances/noAuth";
// import axios from "axios";

export async function createHashId(estimateId) {
  const { data } = await axiosInstance.post(`/api/customer/estimates/${estimateId}/hash-id`);

  return data.hash_id;
}

export async function getEstimateById(hash_id) {
  const { data } = await noAuthAxiosInstance.get(`/api/customer/estimates/hash-id?hash-id=${hash_id}`);

  return data.data;
}
