<template>
  <loading-spinner />
</template>

<script>
import { mapActions } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "AuthCallbackPage",
  components: { LoadingSpinner },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      setToken: "user/setToken",
    }),
  },
  created: async function () {
    this.setToken(this.$route.query.token);
    try {
      await this.getUser();
      await this.$router.push({ name: "projects" });
    } catch (err) {
      throw new Error(err);
    }
  },
};
</script>
