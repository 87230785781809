import { deleteAvatar, getUser, updateAvatar, updateUserName } from "@/api/apiUser";

export default {
  state: {
    user: {
      avatar: null,
      name: "",
      email: "",
    },
    token: localStorage.getItem("user-token") || null,
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
  },
  mutations: {
    SET_TOKEN: (state, token) => (state.token = token),
    SET_USER: (state, user) => (state.user = user),
    DELETE_TOKEN: (state) => (state.token = null),
  },
  actions: {
    setToken: ({ commit }, payload) => {
      const decodedToken = decodeURI(payload);

      commit("SET_TOKEN", decodedToken);
      localStorage.setItem("user-token", decodedToken);
    },
    deleteToken: ({ commit }) => {
      localStorage.removeItem("user-token");
      commit("DELETE_TOKEN");
    },
    getUser: async ({ commit, getters }) => {
      const user = await getUser(getters.token);
      commit("SET_USER", user);
    },
    updateUser: ({ commit }, user) => {
      commit("SET_USER", user);
    },
    updateUserName: async ({ commit }, name) => {
      await updateUserName(name);
      commit("SET_USER", await getUser());
    },

    updateAvatar: async ({ commit }, avatar) => {
      const updatedUser = await updateAvatar(avatar);
      commit("SET_USER", updatedUser);
    },

    deleteAvatar: async ({ commit, state }) => {
      await deleteAvatar();
      commit("SET_USER", { ...state.user, avatar: null });
    },
  },
  namespaced: true,
};
