export default function formatHideUnhideDate(date) {
  let month = date.getUTCMonth() + 1;
  let day = date.getUTCDate();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  const result = date.getFullYear() + "." + month + "." + day;

  return result;
}
