<template>
  <div class="min-h-screen flex flex-col">
    <e-header />
    <main class="flex-1">
      <div :class="mainClasses">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import EHeader from "@/layout/EHeader";
export default {
  name: "Layout",
  props: {
    mainClasses: {
      type: String,
      default: "max-w-7xl mx-auto"
    }
  },
  components: { EHeader }
};
</script>

<style scoped></style>
