<template>
  <div class="w-full flex flex-1 items-center justify-center flex-col px-12 text-center py-12">
    <div class="text-neutrals-1000 leading-9 text-xl">No data yet</div>
    <div class="text-neutral-500 text-sm">
      An estimate description will display here, as soon, as you enter some data in the edit mode
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyDataDescription",
};
</script>
