import {
  addNewEstimate,
  deleteEstimate,
  getEstimates,
  archiveEstimate,
  restoreEstimate,
  updateEstimate,
  getFilteredEstimates,
} from "@/api/apiEstimates";

import categories from "@/store/estimate-modules/categories";
import additionalExpenses from "@/store/estimate-modules/additionalExpenses";
import currentEstimate from "@/store/estimate-modules/currentEstimate";

export default {
  state: {
    estimates: [],
    filteredEstimates: [],
    counts: {} ,
    pagination: 0,
  },
  getters: {
    estimates: (state) => state.estimates.filter((estimate) => !estimate.archived_at),
    archivedEstimates: (state) => state.estimates.filter((estimate) => estimate.archived_at),
    filteredEstimates: (state) => state.filteredEstimates,
    toDoCount: (state) => state.counts["To do"] ? state.counts["To do"] : 0,
    inProgressCount: (state) => state.counts["In progress"] ? state.counts["In progress"] : 0,
    doneCount: (state) => state.counts["Done"] ? state.counts["Done"] : 0,
    allCount: (state) => {
      const toDo = state.counts["To do"] ? state.counts["To do"] : 0
      const inProgress = state.counts["In progress"] ? state.counts["In progress"] : 0
      const done = state.counts["Done"] ? state.counts["Done"] : 0

      return toDo + inProgress + done;
    },
    archivedCount: (state) => state.counts.archived,
    estimateById: (state) => (id) => {
      return state.estimates.find((estimate) => estimate.id === id);
    },
    pagination_count: (state) => state.pagination,
  },
  mutations: {
    SET_ESTIMATES: (state, payload) => {
      state.estimates = [...payload];
    },

    SET_FILTERED_ESTIMATES: (state, payload) => {
      state.filteredEstimates = [...payload];
    },

    SET_COUNTS: (state, payload) => {
      state.counts = { ...payload };
    },

    SET_PAGINATION_COUNT: (state, payload) => {
      state.pagination = payload;
    },

    CHANGE_CURRENT_ESTIMATE_NAME: (state, {id, name}) => {
      state.filteredEstimates = state.filteredEstimates.map(
        e => {
          if (e.id === id) {
            return {
              ...e,
              name,
            }
          } else {
            return e;
          }
        }
      )
      if (state.estimates.length) {
        state.estimates = state.estimates.map(
          e => {
            if (e.id === id) {
              return {
                ...e,
                name,
              }
            } else {
              return e;
            }
          }
        )
      }
    },

    CHANGE_CURRENT_ESTIMATE_DEADLINE: (state, {id, deadline}) => {
      state.filteredEstimates = state.filteredEstimates.map(
        e => {
          if (e.id === id) {
            return {
              ...e,
              deadline,
            }
          } else {
            return e;
          }
        }
      )
      if (state.estimates.length) {
        state.estimates = state.estimates.map(
          e => {
            if (e.id === id) {
              return {
                ...e,
                deadline,
              }
            } else {
              return e;
            }
          }
        )
      }
    },

    CHANGE_CURRENT_ESTIMATE_ADD_USER: (state, {id, user}) => {
      state.filteredEstimates = state.filteredEstimates.map(
        e => {
          if (e.id === id) {
            return {
              ...e,
              users : [...e.users , user],
            }
          } else {
            return e;
          }
        }
      )
      if (state.estimates.length) {
        state.estimates = state.estimates.map(
          e => {
            if (e.id === id) {
              return {
                ...e,
                users : [...e.users , user],
              }
            } else {
              return e;
            }
          }
        )
      }
    },

    CHANGE_CURRENT_ESTIMATE_DELETE_USER: (state, {id, userId}) => {


      state.filteredEstimates = state.filteredEstimates.map(
        e => {
          if (e.id === id) {
            return {
              ...e,
              users : [...e.users].filter(u => u.id !== userId)
            }
          } else {
            return e;
          }
        }
      )
      if (state.estimates.length) {
        state.estimates = state.estimates.map(
          e => {
            if (e.id === id) {
              return {
                ...e,
                users : [...e.users].filter(u => u.id !== userId)
              }
            } else {
              return e;
            }
          }
        )
      }
    }
  },
  actions: {
    getEstimates: async ({ commit }, { status }) => {
      const result = await getEstimates({ status });

      commit("SET_COUNTS", result.counts);
      commit("SET_ESTIMATES", result.data);
      commit("SET_PAGINATION_COUNT", result.meta.last_page);

      return result;
    },

    getFilteredEstimates: async ({ commit }, { page, name, status, is_archived }) => {
      const result = await getFilteredEstimates({ page, name, status, is_archived });

      commit("SET_FILTERED_ESTIMATES", result.data);
      commit("SET_COUNTS", result.counts);
      commit("SET_PAGINATION_COUNT", result.meta.last_page);
      return result;
    },

    addEstimate: async ({ commit }, payload) => {
      const newEstimate = await addNewEstimate(payload);

      const response = await getEstimates();

      commit("SET_ESTIMATES", response.data);
      commit("SET_COUNTS", response.counts);

      return newEstimate.data.data;
    },

    deleteEstimate: async ({ commit }, id) => {
      await deleteEstimate(id);
      const response = await getEstimates();

      commit("SET_ESTIMATES", response.data);
      commit("SET_COUNTS", response.counts);
    },

    archiveEstimate: async ({ commit }, id) => {
      await archiveEstimate(id);
      const response = await getEstimates();

      commit("SET_ESTIMATES", response.data);
      commit("SET_COUNTS", response.counts);
    },

    restoreEstimate: async ({ commit }, id) => {
      await restoreEstimate(id);
      const response = await getEstimates();

      commit("SET_ESTIMATES", response.data);
      commit("SET_COUNTS", response.counts);
    },

    updateEstimate: async ({ commit }, payload) => {
      await updateEstimate(payload);
      const response = await getEstimates();

      commit("SET_ESTIMATES", response.data);
      commit("SET_COUNTS", response.counts);
    },
  },
  namespaced: true,
  modules: {
    categories,
    additionalExpenses,
    currentEstimate,
  },
};
