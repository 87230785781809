<template>
  <div class="relative flex-shrink-0 setting-list z-30">
    <button
      v-if="!stopPropagationClick"
      @click="$emit('openMenu')"
      class="w-6 h-6 inline-flex items-center justify-center text-gray-900 hover:text-indigo-600 focus:text-indigo-600"
    >
      <span class="sr-only">Open options</span>
      <icon-triple-dot />
    </button>
    <button
      v-else
      @click.stop="$emit('openMenu')"
      class="w-6 h-6 inline-flex items-center justify-center text-gray-900 hover:text-indigo-600 focus:text-indigo-600"
    >
      <span class="sr-only">Open options</span>
      <icon-triple-dot />
    </button>
    <div
      v-show="isSettingsOpen"
      class="origin-top-right absolute z-30 right-0 mt-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import IconTripleDot from "@/components/icons/IconTripleDot";
import ClickOutside from "vue-click-outside";

export default {
  name: "SettingsList",
  components: { IconTripleDot },
  props : {
    isSettingsOpen : {
      type : Boolean,
      default : false
    },

    stopPropagationClick : {
      type : Boolean,
      default : false
    }
  },
  directives : {
    ClickOutside
  },
};
</script>
