<template>
  <loading-spinner v-if="loading && $route.name !== 'Login'" />
  <div v-else>
    <overlay-notifications />
    <router-view />
    <vue-progress-bar />

  </div>
</template>

<script>
import OverlayNotifications from "@/components/OverlayNotifications";
import isDebug from "@/utils/isDebug";
import { mapActions } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: { OverlayNotifications, LoadingSpinner },
  errorCaptured(err) {
    const text = isDebug() && err.response ? err.response.data.message : err.message;

    this.$notify({
      title: "Oops, something went wrong...",
      text: text,
    });
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
    }),
  },
  async mounted() {
    this.loading = true;
    if (!this.$route.meta.ignoreAuth) await this.getUser();
    this.loading = false;

  },
};
</script>
