<template>
  <row :class="[rowClasses, borderClass]">
    <div class="py-4 whitespace-nowrap text-sm font-medium text-neutral-1000 cursor-move" v-if="isEdit">
      <icon-drag />
    </div>
    <static-content-label
      :show-tooltip="technology.name.length >= 40"
      :tooltip-value="technology.name"
      type="black"
    >
      {{ technology.name }}</static-content-label
    >
    <formulate-editable-content
      type="editableContent"
      v-if="isEdit"
      :existPrefix="true"
      :initialValue="technology.ratePerHour"
      placeholder="Cost"
      validation="required|min:0,value|max:1000000,value|number"
      @update="onChangeRatePerHour"
    >
    </formulate-editable-content>
    <static-content-label class="text-right" v-else>${{ technology.ratePerHour }} </static-content-label>
    <static-content-label class="text-right">{{ technology.hours }} h</static-content-label>
    <static-content-label class="text-right">${{ cost }}</static-content-label>
  </row>
</template>

<script>
import Row from "../../../../components/tables/rows/Row";
import StaticContentLabel from "../../../../components/tables/StaticContentLabel";
// import EditableContent from "@/components/EditableContent";
import { mapGetters, mapActions } from "vuex";
import FormulateEditableContent from "@/components/formulate-inputs/FormulateEditableContent";
import IconDrag from "../../../icons/IconDrag";

export default {
  name: "TechnologyRow",
  components: { StaticContentLabel, Row, FormulateEditableContent, IconDrag },
  props: {
    technology: {
      type: Object,
      default: () => {},
    },

    projectId: {
      type: Number,
    },

    withBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rowClasses() {
      return this.isEdit ? "grid-cols-technologies-edit-mode" : "grid-cols-technologies-view-mode";
    },
    cost() {
      return this.technology.ratePerHour * this.technology.hours;
    },

    borderClass() {
      return this.withBorder ? "border-b border-gray-200" : "";
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
  methods: {
    ...mapActions({
      updateCategory: "estimates/currentEstimate/updateCategory",
    }),

    async onChangeName(value) {
      await this.updateCategory({
        estimateId: this.projectId,
        updatedCategory: {
          ...this.technology,
          name: value,
        },
        id: this.technology.id,
      });
    },

    async onChangeRatePerHour(value) {
      await this.updateCategory({
        estimateId: this.projectId,
        updatedCategory: {
          ...this.technology,
          ratePerHour: value,
          features: this.technology.features.map((feature) => {
            return { ...feature, ratePerHour: value, cost: value * feature.hours };
          }),
        },
        id: this.technology.id,
      });
    },
  },
};
</script>
