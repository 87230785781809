<template>
  <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.1" />
  </svg>
</template>

<script>
export default {
  name: "IconPlus",
};
</script>

<style scoped></style>
