<template>
  <div class="flex flex-row items-center">
    <div class="w-2 h-2 mr-3" :class="markClass"></div>
    <span class="block truncate">
      {{ estimateType }}
    </span>
  </div>
</template>

<script>
export default {
  name: "EstimateStatusOption",
  props: {
    estimateType: {
      type: String,
      required: true,
    },
  },
  computed: {
    markClass() {
      switch (this.estimateType) {
        case "To do":
          return "bg-blue-accent";
        case "In progress":
          return "bg-yellow-accent";
        case "Done":
          return "bg-green-accent";
        default:
          return "";
      }
    },
  },
};
</script>
