import Vue from "vue";
import Vuex from "vuex";
// import Api from "../api/Api";

Vue.use(Vuex);

import project from "./project";
import user from "./user";
import estimates from "@/store/estimates";
import sideOver from "./sideOver";
import users from "./users"

export default new Vuex.Store({
  modules: {
    project: project,
    user: user,
    estimates: estimates,
    sideOver: sideOver,
    users : users
  },
});
