import { axiosInstance } from "@/api/axiosInstances";

export async function getUser() {
  const { data } = await axiosInstance.get("api/auth/me");

  return data?.data;
}

export async function updateUserName(name) {
  await axiosInstance.put(
    "/api/profile",
    {},
    {
      params: { name },
    }
  );
}

export async function updateAvatar(avatar) {
  const formData = new FormData();
  formData.append("profile_avatar", avatar);

  const { data } = await axiosInstance.post("/api/avatar", formData);

  return data.data;
}

export async function deleteAvatar() {
  await axiosInstance.delete("/api/avatar");
}
