<template>
  <FormulateForm name="addNewFeature" @submit="onSubmit" class="grid grid-cols-addFeatureForm gap-x-6">
    <FormulateInput
      ref="name"
      placeholder="New feature"
      error-behavior="submit"
      type="text"
      input-class="block flex-1 w-full rounded-none rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      validation="bail|required:trim|max:255,length"
      v-model="fields.name"
    />
    <FormulateInput
      placeholder="Hours"
      name="hours"
      input-class="block flex-1 w-full rounded-none rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      validation="bail|number|min:0,value|max:10000,value"
      error-behavior="submit"
      :error-class="['whitespace-nowrap text-xs']"
      v-model="fields.time"
    />
    <button
      id="form-feature-add"
      class="h-10 px-2 sm:px-4 py-2 border border-transparent w-full sm:w-auto text-sm text-center leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
    >
      Add
    </button>
  </FormulateForm>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TestAddFeatureForm",
  props: {
    projectId: {
      type: Number,
    },
    categoryId: {},
  },
  data() {
    return {
      fields: {
        name: "",
        time: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentCategory: "estimates/currentEstimate/currentCategory",
      currentEstimateId : "estimates/currentEstimate/currentEstimateId"
    }),
  },
  methods: {
    ...mapActions({
      addFeature: "estimates/currentEstimate/addFeature",
    }),

    async onSubmit() {
      await this.addFeature({
        estimateId: this.currentEstimateId,
        categoryId: this.categoryId,
        feature: {
          name: this.fields.name,
          ratePerHour: this.currentCategory(this.categoryId).ratePerHour,
          hours: this.fields.time ? this.fields.time : 0,
          cost: this.currentCategory(this.categoryId).ratePerHour * this.fields.time,
          order: this.currentCategory(this.categoryId).length
        },
      });
      this.reset();
    },
    reset() {
      this.$formulate.reset("addNewFeature");
      this.fields.name = "";
      this.fields.time = 0;
      this.$refs.name.$el.querySelector("input").focus();
    },
  },
};
</script>
