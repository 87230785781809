<template>
  <div class="max-w-4xl mx-auto pt-8 pb-8" v-if="!loading">
    <file-page-header-section :project="estimate" :show-arrow="false" :show-status="false">
      <div class="space-x-3 items-center flex">
        <button
          type="button"

          class="inline-flex items-center text-gray-900 px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
          @click="downloadPdf"
        >
          <icon-pdf class="mr-2" color="#111827" />
          Download
        </button>
      </div>
    </file-page-header-section>
    <h4 class="font-bold text-gray-900 leading-7 mt-8">General overview</h4>
    <div class="w-full shadow-xl rounded-md mt-1 border border-gray-200">
      <pie-chart :categories="estimate.categories.filter((category) => !category.hidden)" :show-stroke="false" :show-if-empty="true"/>
    </div>
    <test-total-table
      class="mt-8"
      :categories="estimate.categories.filter((category) => !category.hidden).sort((a,b) => a.order - b.order)"
      :additional-expenses="estimate.additional_expenses"
    />
    <all-features :categories="estimate.categories.filter((category) => !category.hidden).sort((a,b) => a.order - b.order)" />
  </div>
  <loading-spinner v-else />
</template>

<script>
import FilePageHeaderSection from "@/components/kit/FilePageHeaderSection";
import IconPdf from "@/components/icons/IconPdf";
import TestTotalTable from "@/components/kit/PartsAndAdditionalExpensesTable";
import PieChart from "@/components/pie-chart/PieChart";
import AllFeatures from "@/components/AllFeatures";
import { getEstimateById } from "@/api/apiCustomer";
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapActions} from "vuex";

export default {
  name: "ClientPage",
  components: { FilePageHeaderSection, IconPdf, TestTotalTable, PieChart, LoadingSpinner, AllFeatures },
  data() {
    return {
      loading: false,
      estimate: null,
    };
  },

  methods: {
    ...mapActions({
      switchToViewMode : "project/switchToView",
    }),

    downloadPdf() {
      const link = document.createElement("a");
      link.href = `${process.env.VUE_APP_API_URL}/api/export/pdf/estimate/${this.estimate.id}/?height=${document.getElementsByTagName('BODY')[0].offsetHeight + 10}`;
      link.dispatchEvent(new MouseEvent("click"));
    },
  },

  async created() {
    this.loading = true;
    this.switchToViewMode();
    this.estimate = await getEstimateById(this.$route.query.hash_id);
    this.loading = false;
  },
};
</script>
