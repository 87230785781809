<template>
  <div>
    <pdf v-show="result" :src="file.url" @loaded="result = true">
    </pdf>
    <loading-spinner class="bg-white" v-if="!result"/>
  </div>
</template>

<script>
import  pdf from  'vue-pdf'
import LoadingSpinner from "../LoadingSpinner";

export default {
  name : "PdfWithLoading",
  components : {pdf, LoadingSpinner},
  data(){
    return{
      result : false
    }
  },
  watch: {
    file(){
      this.result  = false
    }
  },
  props : {
    file : {

    }
  }
}
</script>
