// const createRequest = ({ request, onMutate, onError, onSuccess }) => {
//   return (data) => {
//     requestQueue.queue({ request, onMutate, onError, onSuccess }, data);
//   };
// };
//
// function queue({ request, onMutate, onError, onSuccess }, data) {
//   const context = onMutate(data);
//
//   this.queue.push({
//     context,
//     data,
//     request,
//     onMutate,
//     onError,
//     onSuccess,
//   });
//
//   this.launchRequestIfNotRunning();
// }
//
// async function launchRequestIfNotRunning() {
//   const { data, request, onError, onSuccess, context } = this.shiftFromQueue();
//
//   try {
//     const res = await request(data);
//
//     onSuccess(res, context);
//   } catch (error) {
//     let req;
//
//     while ((req = this.popFromQueue())) {
//       req.onError(error, req.context);
//     }
//
//     onError(error, context);
//   }
//
//   await launchRequestIfNotRunning();
// }

const TIME = 50;

class RequestQueue {
  constructor() {
    this.isFetching = false;
    this.requestQueue = [];
    setInterval(() => this.fetch(), TIME);
  }

  async fetch() {
    if (this.isFetching || !this.requestQueue.length) {
      return;
    }

    this.isFetching = true;
    const { request, onSuccess, onError, data } = this.requestQueue.shift();

    try {
      const response = await request(data);

      onSuccess(response);
    } catch (error) {
      for (let i = this.requestQueue.length - 1; i >= 1; i--) {
        this.requestQueue[i].onError();
      }

      onError(error);

      this.requestQueue = [];
    } finally {
      this.isFetching = false;
    }
  }

  queue({ request, onMutate, onError, onSuccess }, data) {
    const context = onMutate(data);

    this.requestQueue.push({
      context,
      data,
      request,
      onMutate,
      onError,
      onSuccess,
    });
  }

  popFromQueue() {
    return this.requestQueue.pop();
  }

  createRequest = ({ request, onMutate, onError, onSuccess }) => {
    return (data) => {
      this.queue({ request, onMutate, onError, onSuccess }, data);
    };
  };

  get() {
    return this.requestQueue;
  }
}

export default RequestQueue;
