<template>
  <div class="h-12 w-12 bg-green-100 rounded-full flex justify-center items-center">
    <IconChecked class="w-5 h-5" color="#059669" />
  </div>
</template>

<script>
import IconChecked from "@/components/icons/IconChecked";

export default {
  name: "SuccessMark",
  components: { IconChecked },
};
</script>
