<template>
  <nav class="bg-white border-b border-gray-200" @keydown.escape="isProfileDropdownOpen = false">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex justify-between h-16">
        <div class="mr-4 inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <button
            aria-controls="mobile-menu"
            aria-expanded="false"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            type="button"
            @click="isMobileMenuDropdownOpen = !isMobileMenuDropdownOpen"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              aria-hidden="true"
              class="block h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                v-if="!isMobileMenuDropdownOpen"
                d="M4 6h16M4 12h16M4 18h16"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path v-else d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
          <router-link tag="div" to="/projects" class="flex-shrink-0 cursor-pointer flex items-center">
<!--            <icon-nerd-logo class="block lg:hidden h-8 w-auto" />-->
            <icon-nerd-logo-with-text class="block h-8 w-auto" />
          </router-link>
          <div class="hidden sm:ml-20 sm:flex sm:space-x-8">
            <router-link
              v-for="link in links"
              :key="link.label"
              :to="link.route"
              active-class="active"
              class="desktop link"
            >
              {{ link.label }}
            </router-link>
          </div>
        </div>
        <div class="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto  sm:ml-6 sm:pr-0">
          <div v-on-clickaway="away" class="ml-3 relative">
              <router-link to="/profile">
                <div class="flex flex-row items-center">
                  <span class="mr-3 font-bold">{{ user.name }}</span>
                  <user-avatar type="small" :avatar="user.avatar" />
                </div>
              </router-link>
<!--              <button @click="isProfileDropdownOpen = !isProfileDropdownOpen">-->
<!--                <icon-chevron-down class="ml-3 h-5 w-5" />-->
<!--              </button>-->
            <div
              v-if="isProfileDropdownOpen"
              aria-labelledby="user-menu"
              aria-orientation="vertical"
              class="menu z-50"
              role="menu"
            >
              <router-link class="menu-item" to="/profile">
                <div class="flex flex-row items-center">
                  <icon-user-outline class="h-4 w-4 mr-3" />
                  <span class="font-normal text-base">Your profile</span>
                </div>
              </router-link>
              <div class="menu-item cursor-pointer" @click="logOut">
                <div class="flex flex-row items-center">
                  <icon-log-out class="h-4 w-4 mr-3" />
                  <span class="font-normal text-base">Sign Out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobileMenuDropdownOpen" id="mobile-menu" class="sm:hidden">
      <div class="pt-2 pb-4 space-y-1">
        <router-link v-for="link in links" :key="link.label" :to="link.route" active-class="active" class="mobile link">
          {{ link.label }}
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import IconNerdLogoWithText from "../components/icons/IconNerdLogoWithText";
import IconUserOutline from "../components/icons/IconUserOutline";
import IconLogOut from "../components/icons/IconLogOut";
// import IconChevronDown from "../components/icons/IconChevronDown";
import UserAvatar from "@/components/UserAvatar";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EHeader",
  components: { IconNerdLogoWithText, IconUserOutline, IconLogOut,  UserAvatar },
  mixins: [clickaway],
  data() {
    return {
      isProfileDropdownOpen: false,
      isMobileMenuDropdownOpen: false,

      links: [{ route: { name: "projects" }, label: "My Estimates" }],
    };
  },
  methods: {
    ...mapActions({
      deleteToken: "user/deleteToken",
    }),

    away() {
      this.isProfileDropdownOpen = false;
    },

    logOut() {
      this.deleteToken();
      this.$router.push({ name: "Login" });
    },
  },
  computed: {
    ariaExpanded() {
      return this.isProfileDropdownOpen ? "true" : "false";
    },

    ...mapGetters({
      user: "user/user",
    }),
  },
};
</script>

<style scoped>
.link {
  @apply border-transparent text-gray-500;
}

.desktop.link {
  @apply inline-flex items-center px-1 pt-1 border-b-2 font-medium;
  @apply hover:border-gray-300 hover:text-gray-700;
}

.desktop.link.active {
  @apply border-indigo-500 text-gray-900;
  @apply hover:border-indigo-500 hover:text-gray-900;
}

.mobile.link {
  @apply block pl-3 pr-4 py-2 border-l-4 text-base font-medium;
  @apply hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700;
}

.mobile.link.active {
  @apply bg-indigo-50 border-indigo-500 text-indigo-700;
  @apply hover:bg-indigo-50 hover:border-indigo-500 hover:text-indigo-700;
}

.menu {
  @apply origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white;
  @apply ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.menu-item {
  @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100;
}
</style>
