<template>
  <div class="overflow-x-auto" v-if="draggable" ref="features-table">
    <div class="align-middle inline-block min-w-full">
      <div class="overflow-hidden">
          <div class="min-w-full">
            <header-row />
            <draggable v-model="listOfFeatures" tag="div" draggable=".draggable" class="divide-y divide-gray-200">
              <feature-row
                v-for="(feature, index) in listOfFeatures"
                class="draggable"
                :key="feature.id"
                :feature="feature"
                :project-id="projectId"
                :category-id="categoryId"
                :with-border="!!index && index !== listOfFeatures.length - 1"
                @scroll-to-bottom="scrollToBottom"
                :rate-per-hour="ratePerHour"
              />
            </draggable>
          </div>
<!--        </vuescroll>-->
      </div>
    </div>
  </div>

  <table-layout v-else>
    <header-row/>
    <draggable v-model="listOfFeatures" tag="div" draggable=".draggable" class="divide-y divide-gray-200">
      <feature-row
        v-for="(feature, index) in listOfFeatures"
        class="draggable"
        :key="feature.id"
        :feature="feature"
        :project-id="projectId"
        :category-id="categoryId"
        :rate-per-hour="ratePerHour"
        :with-border="!!index && index !== listOfFeatures.length - 1"
        @scroll-to-bottom="scrollToBottom"
      />
    </draggable>
  </table-layout>
</template>
<script>
import draggable from "vuedraggable";
import FeatureRow from "@/components/tables/rows/draggable/FeatureRow";
import TableLayout from "@/layout/TableLayout";
import HeaderRow from "../tables/rows/draggable/HeaderRow";
import { mapGetters, mapActions } from "vuex";
// import vuescroll from "vuescroll";

export default {
  name: "ETable",
  components: { FeatureRow, draggable, HeaderRow, TableLayout },
  props: {
    features: {
      type: Array,
    },
    projectId: {
      type: Number,
    },
    categoryId: {},
    draggable: {
      type: Boolean,
      default: false,
    },

    ratePerHour: {},
  },

  data() {
    return {
      dataFeatures: [...this.features],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: "#04ffc6",
        },
      },
    };
  },
  watch: {
    features(value) {
      this.dataFeatures = value;
      this.$nextTick(() => (this.$refs["features-table"].scrollTop = this.$refs["features-table"].scrollHeight));
    },
  },
  methods: {
    ...mapActions({
      updateFeaturesOrder : "estimates/currentEstimate/updateFeaturesOrder"
    }),

    deleteFeature(id) {
      this.testData = this.testData.filter((elem) => elem.id !== id);
    },
    addFeature({ time, name }) {
      this.testData.unshift({
        id: new Date().getMilliseconds(),
        ratePerHours: 0,
        cost: 0,
        featureName: name,
        comments: "",
        hours: time,
      });
    },

    scrollToBottom() {

    },
  },
  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
    }),

    listOfFeatures : {
      get() {
        return [...this.features].sort((a,b) => a.order - b.order)
      },
      async set(value) {
          await this.updateFeaturesOrder({
            categoryId : this.categoryId,
            estimateId : this.projectId,
            newOrder : value.map((f,index) => {
              return {
                ...f,
                order : index
              }
            })
          })
      }
    },

    sorted(){
      return [...this.dataFeatures].sort((a,b) => a.order - b.order)
    }
  },
};
</script>
