<template>
  <div class="my-10 border border-gray-200 shadow p-4 rounded-md block">
    <h3 class="text-xl mb-6">Description:</h3>
    <empty-data-description v-if="!isEdit && !description" />
    <p v-if="!isEdit" class="text-gray-500 text-sm break-all">{{ description }}</p>
    <linkify-textarea v-model="value" @outFocus="saveDescription" class="p-2 border rounded-md focus:outline-none" :class="[isValid ? 'w-full  border-neutral-400 text-neutral-1000' : 'placeholder-red-300 border-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400']"/>
    <div v-if="isEdit" class="flex w-full justify-end mt-2 text-xs" :class="counterLettersClasses">
      {{ value ? value.length : 0 }} / 9999
    </div>
<!--    <button @click="check">Print window.getSelection()</button>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmptyDataDescription from "@/components/empty-data/EmptyDataDescription";
import LinkifyTextarea from "./LinkifyTextarea";
export default {
  name: "ProjectDescription",
  components: { EmptyDataDescription, LinkifyTextarea },
  model: {
    prop: "description",
    event: "change",
  },
  props: {
    description: {
      type: String,
    },

    initialState: {},

    projectId: {},
  },
  data() {
    return {
      value: this.description,
      snapshot : null,
    };
  },

  watch:{
    // value(){
    //   this.$refs.descriptionInput.$el.querySelector('textarea').style.height = Math.max(this.$refs.descriptionInput.$el.querySelector('textarea').scrollHeight, 160) + "px";
    // }
  },

  // mounted(){
  //   this.$refs.descriptionInput.$el.querySelector('textarea').style.height = Math.max(this.$refs.descriptionInput.$el.querySelector('textarea').scrollHeight, 160) + "px";
  // },

  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
    }),

    isValid() {
      return !this.value || this.value.length < 9999;
    },

    counterLettersClasses() {
      return this.isValid ? "text-neutral-500" : "text-red-500";
    },
  },

  methods: {
    ...mapActions({
      updateDescription: "estimates/currentEstimate/updateDescription",
    }),

    async saveDescription() {
      if (!this.value || this.value.length === 0 || this.snapshot === this.value) return;
      if(this.value.length == 1 && this.value.charCodeAt(0) === 10) return

      if(!this.isValid)
      {
        this.value = this.value.slice(0,9998);
      }

      await this.updateDescription({
        id: this.projectId,
        description: this.value,
      });
    },

    snapshotDescription(){
      this.snapshot = this.value
    },
  },

  created() {
    this.value = this.initialState
  }
};
</script>

<style>
textarea {
  min-height: 11rem;
}
</style>


