export default function formatDate(date) {
  if (typeof date === "string") return date.slice(0, 10) + " 00:00:00";

  const b = date.split(/\D+/);
  const formatedDate = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  const finalDate =
    [formatedDate.getFullYear(), formatedDate.getUTCMonth() + 1, formatedDate.getDate()].join("-") + " 00:00:00";

  return finalDate;
}
