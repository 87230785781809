<template>
  <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex justify-start">
      <pagination-link-move-prev @click="$emit('changePage', currentPage - 1)" v-if="currentPage !== 1" />
    </div>
    <div class="hidden md:-mt-px md:flex">
      <pagination-link
        v-for="index in count"
        :key="index"
        @click="$emit('changePage', index)"
        :current="index === currentPage"
      >
        {{ index }}
      </pagination-link>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <pagination-link-move-next @click="$emit('changePage', currentPage + 1)" v-if="currentPage !== count" />
    </div>
  </nav>
</template>

<script>
import PaginationLink from "@/components/pagination/PaginationLink";
import PaginationLinkMovePrev from "@/components/pagination/PaginationLinkMovePrev";
import PaginationLinkMoveNext from "@/components/pagination/PaginationLinkMoveNext";

export default {
  props: {
    count: {
      required: true,
    },
    currentPage: {
      required: true,
    },
  },
  components: {
    PaginationLink,
    PaginationLinkMovePrev,
    PaginationLinkMoveNext,
  },
};
</script>
