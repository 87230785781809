<template>
  <div id="linkify-textarea" @focusout="$emit('outFocus')"  contenteditable="true">
  </div>
</template>

<script>
const urlRegex = require('url-regex');
import _debounce from "lodash.debounce";
export default {
  name : 'LinkifyTextarea',
  props : {
    value : {
    }
  },
  model : {
    prop : 'value',
    event : 'update'
  },
  data(){
    return{
      snapshot : "",
      disableUpdate : false
    }
  },
  methods : {
    debouncedChange : _debounce(function(){
      this.buildLinks();
    } , 800),
    buildLinks(){
      const linkifyTextarea = document.getElementById('linkify-textarea')
      if(this.disableUpdate)
      {
        this.disableUpdate = false;
        return;
      }
      function getLengthOfNode(node){
        if (node.nodeValue || node.innerText)
          return (node.nodeValue || node.innerText).length
        return  0
      }

      let selectedElement = window.getSelection().anchorNode;
      let selectedOffset = window.getSelection().anchorOffset;
      let position = 0;

      let text = linkifyTextarea.innerText
      for(let i = 0 ; i <linkifyTextarea.childNodes.length ; i++ )
      {
        if(linkifyTextarea.childNodes[i] === selectedElement) {
          position += selectedOffset
          break;
        }
        else if(linkifyTextarea.childNodes[i].lastChild === selectedElement)
        {
          position += selectedOffset;
          break;
        }
        else {
          if((linkifyTextarea.childNodes[i].nodeValue || linkifyTextarea.childNodes[i].innerText)) {
            position += (linkifyTextarea.childNodes[i].nodeValue || document.getElementById('linkify-textarea').childNodes[i].innerText).length
          }
        }
      }
      for(let i  = 0 ; i < linkifyTextarea.childNodes.length ; i++)
      {
        const currentNode = linkifyTextarea.childNodes[i];

        if(currentNode.tagName == 'A')
        {
          linkifyTextarea.replaceChild(document.createTextNode(currentNode.innerText) , currentNode
          )
        }
      }
      for(let i = linkifyTextarea.childNodes.length - 1 ; i > 0 ; i-- )
      {
        if(linkifyTextarea.childNodes[i].nodeName == 'BR' || document.getElementById('linkify-textarea').childNodes[i-1].nodeName == 'BR')
        {
          continue;
        }
        const text1 = linkifyTextarea.childNodes[i].nodeName !== 'BR' ? document.getElementById('linkify-textarea').childNodes[i].nodeValue : '';
        linkifyTextarea.removeChild( document.getElementById('linkify-textarea').childNodes[i])
        const text2 =  linkifyTextarea.childNodes[i-1].nodeName !== 'BR' ? document.getElementById('linkify-textarea').childNodes[i-1].nodeValue : ''
        linkifyTextarea.replaceChild(document.createTextNode(text2 + text1), document.getElementById('linkify-textarea').childNodes[i-1])
      }
      const links = text.match(urlRegex({strict : false}));

      // text = document.getElementById('linkify-textarea').innerText;

      if(links) {
        for (let link of links) {
          for(let i = 0 ; i <linkifyTextarea.childNodes.length ; i++) {
            if (linkifyTextarea.childNodes[i].nodeValue) {
              if (linkifyTextarea.childNodes[i].nodeValue.indexOf(link) >= 0) {
                let startIndex = linkifyTextarea.childNodes[i].nodeValue.indexOf(link);

                let leftSide = linkifyTextarea.childNodes[i].nodeValue.substr(0, startIndex);
                let linkText = linkifyTextarea.childNodes[i].nodeValue.substr(startIndex, link.length);
                let rightSide = linkifyTextarea.childNodes[i].nodeValue.substr(startIndex + link.length);
                const a = document.createElement('a');
                a.href = linkText.indexOf('https://') >= 0 || linkText.indexOf('http://') >= 0 ? linkText : 'https://' + linkText;
                a.innerHTML = linkText;
                linkifyTextarea.insertBefore(document.createTextNode(rightSide), document.getElementById('linkify-textarea').childNodes[i + 1]);
                linkifyTextarea.insertBefore(a, document.getElementById('linkify-textarea').childNodes[i + 1]);
                linkifyTextarea.insertBefore(document.createTextNode(leftSide), document.getElementById('linkify-textarea').childNodes[i + 1]);
                linkifyTextarea.removeChild(document.getElementById('linkify-textarea').childNodes[i]);
                break;
              }
            }
          }
        }
      }

      for(let i = 0;  i < linkifyTextarea.childNodes.length ; i++)
      {
        let len = getLengthOfNode(linkifyTextarea.childNodes[i]);

        if(len < position)
          position -= len;
        else{
          let range = new Range();
          if(linkifyTextarea.childNodes[i].tagName == 'A'){
            range.setStart(linkifyTextarea.childNodes[i].lastChild, position);
            range.setEnd(linkifyTextarea.childNodes[i].lastChild, position)
          }
          else {
            range.setStart(linkifyTextarea.childNodes[i], position);
            range.setEnd(linkifyTextarea.childNodes[i], position)
          }
          window.getSelection().removeAllRanges()
          window.getSelection().addRange(range)
          break;
        }
      }
    }
  },
  mounted() {
    document.getElementById('linkify-textarea').addEventListener('input', () => {
      this.debouncedChange();
      this.$emit('update', document.getElementById('linkify-textarea').innerText)
    })
    document.getElementById('linkify-textarea').addEventListener('keypress', e => {
      if(e.key == 'Enter')
      {
        this.disableUpdate = true;
        document.execCommand('insertLineBreak')
        event.preventDefault()
        return false
      }})

    document.getElementById('linkify-textarea').addEventListener('click', (event) => {
      if(event.target.href) {
        const link = document.createElement('a');
        link.href = event.target.href.indexOf('https://') >= 0  || event.target.href.indexOf('http://') >= 0 ? event.target.href : `https://'${event.target.href}`;
        link.target = "_blank"
        link.dispatchEvent(new MouseEvent('click'));
        event.target.dispatchEvent(new MouseEvent('click'))
      }
    })

    document.getElementById('linkify-textarea').addEventListener('paste', function(e){
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData('text/plain');

      // insert text manually
      document.execCommand("insertHTML", false, text);
    })

    document.getElementById('linkify-textarea').innerText = this.value
    this.buildLinks();
  },

}
</script>

<style>
#linkify-textarea > a{
  color : #5897fb;
  cursor: pointer;

}

#linkify-textarea{
  min-height: 160px;
}
</style>
