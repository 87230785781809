const estimateDeadline = (deadline) =>  {
  const date = new Date(deadline);

  const month = date.toLocaleString('en-US', { month: 'short' });
  const day =  date.toLocaleString('en-US', { day: 'numeric' });
  const year = date.toLocaleString('en-US', { year : 'numeric'});

  return `${day}  ${month}. ${year}`;
}

export default estimateDeadline;
