<template>
  <div class="fixed z-50 inset-0 overflow-hidden" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div class="absolute inset-0 overflow-hidden">
      <div class="absolute bg-opacity-70 bg-black inset-0" aria-hidden="true"></div>
      <div v-on-clickaway="clickAway" class="fixed inset-y-0 pl-16 max-w-full right-0 flex overflow-y-scroll">
        <div class="w-screen max-w-md">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickway } from "vue-clickaway";

export default {
  name: "sideOver",
  mixins: [clickway],
  methods: {
    clickAway() {
      this.$emit("clickAway");
    },
  },
};
</script>
