import axios from "axios";
import store from "@/store/index";
import router from "@/router";
import VueRouter from "vue-router";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + store.getters["user/token"];

  return config;
});

const { isNavigationFailure, NavigationFailureType } = VueRouter;

axiosInstance.interceptors.response.use(
  (r) => r,
  async (error) => {
    switch (error?.response?.status) {
      case 401:
        await store.dispatch("user/deleteToken");
        await router.push({ name: "Login" }).catch((error) => {
          if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw new Error(error);
          }
        });
        throw new Error(error);
      case 403:
      case 404:
        await router.push({ name: "404" });
        throw new Error(error);
      // eslint-disable-next-line no-fallthrough
      default:
        throw error;
    }
  }
);
