<template>
  <span class="py-3 text-left text-xs text-neutral-1000 uppercase tracking-wider whitespace-nowrap">
    <slot />
  </span>
</template>

<script>
export default {
  name: "HeaderLabel",
};
</script>
