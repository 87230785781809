<template>
  <div class="scroll-navbar h180">
    <div v-if="categories.length" class="w-full flex flex-col-reverse">
      <ScrollNavbarOption
        @changeCurrent="changeCurrent"
        v-for="category in reversedCategories"
        :key="category.id"
        :category="category"
        :current="currentCategoryId === category.id"
        :hidden="category.hidden"
        :show-buttons="!!currentHoverOption && currentHoverOption.id === category.id"
        @openSettings="openSettings(category.id)"
      />
    </div>
    <empty-data-scroll-navbar v-else class="" />
    <warning-duplicate-part @accept="duplicate"/>
  </div>
</template>

<script>
import ScrollNavbarOption from "@/components/scroll-navbar/ScrollNavbarOption";
import { mapGetters, mapActions } from "vuex";
import EmptyDataScrollNavbar from "@/components/empty-data/EmptyDataScrollNavbar";
import WarningDuplicatePart from "../modals/WarningDuplicatePart";

export default {
  name: "ScrollNavbar",
  components: { EmptyDataScrollNavbar, ScrollNavbarOption, WarningDuplicatePart },
  model: {
    prop: "currentCategoryId",
    event: "changeCurrentCategoryId",
  },
  props: {
    currentCategoryId: {},
  },
  data() {
    return {
      currentHoverOption: null,
    };
  },

  methods: {
    ...mapActions({
      duplicateCategory : "estimates/currentEstimate/duplicateCategory"
    }),

    openSettings(categoryId) {
      this.$emit("openSettings", categoryId);
    },

    changeCurrent(id) {
      const element = document.getElementById("form-feature-add");
      const coords = element.getBoundingClientRect();
      let isVisible =   (coords.top >= 0 &&
        coords.left >= 0 &&
        coords.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        coords.right <= (window.innerWidth || document.documentElement.clientWidth))
      if(!isVisible) {
        setTimeout( () => {
          document.getElementById('parts-block').scrollIntoView({
            behavior: "smooth"
          });
        }, 0 )

        // document.getElementById('category-content').scrollIntoView({
        //   behavior: "smooth"
        // });
        // document.getElementById('category-content').scrollIntoView({
        //   behavior: "smooth"
        // });
      }
      this.$emit("changeCurrentCategoryId", id);
    },

    async duplicate(duplicatedCategory){
      await this.duplicateCategory(
        {
          estimateId : this.currentEstimate.id,
          duplicatedCategory : duplicatedCategory
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
      categories: "estimates/currentEstimate/categories",
      currentEstimate: "estimates/currentEstimate/currentEstimate"
    }),

    reversedCategories(){
      return [...this.categories].reverse()
    }
  },

  watch: {
    categories(newValue, oldValue){
      const ids = newValue.map(c => c.id);
      if(((oldValue.length === 0 && newValue.length > 0) || ids.indexOf(this.currentCategoryId) === -1) && this.categories.length)
        this.$emit("changeCurrentCategoryId", this.categories[0].id);
    }
  },

  async created() {
    if (this.categories.length) this.$emit("changeCurrentCategoryId", this.categories[0].id);
  },
};
</script>

<style scoped>
.scroll-navbar {
  max-width: 16rem;
  /*height: 45rem;*/
  /*overflow-y: scroll;*/
}
.h180{
  min-height: 40rem;
}
</style>
