import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import FormulateEstimateStatusSelect from "@/components/formulate-inputs/FormulateEstimateStatusSelect";
import FormulateTeamInput from "@/components/formulate-inputs/FormulateTeamInput";
import FormulateCategoryUsersInput from "@/components/formulate-inputs/FormulateCategoryUsersInput";
import FormulateProjectPartsInput from "@/components/formulate-inputs/FormulateProjectPartsInput";
import FormulateEditableContent from "@/components/formulate-inputs/FormulateEditableContent";
import FormulateDateInput from "@/components/formulate-inputs/FormulateDateInput";

import { Datetime } from "vue-datetime";
import { Settings } from 'luxon'

Settings.defaultLocale = 'en'
import "vue-datetime/dist/vue-datetime.css";

Vue.component("FormulateTeamInput", FormulateTeamInput);
Vue.component("FormulateEstimateStatusSelect", FormulateEstimateStatusSelect);
Vue.component("FormulateCategoryUsersInput", FormulateCategoryUsersInput);
Vue.component("FormulateProjectPartsInput", FormulateProjectPartsInput);
Vue.component("datetime", Datetime);
Vue.component("FormulateEditableContent", FormulateEditableContent);
Vue.component("FormulateDateInput", FormulateDateInput)

Vue.use(VueFormulate, {
  library: {
    formulateSelect: {
      classification: "text",
      component: "FormulateEstimateStatusSelect",
    },
    team: {
      classification: "text",
      component: "FormulateTeamInput",
    },
    categoryUsers: {
      classification: "text",
      component: "FormulateCategoryUsersInput",
    },
    parts: {
      classification: "text",
      component: "FormulateProjectPartsInput",
    },
    editableContent: {
      classification: "text",
      component: "FormulateEditableContent",
      slotProps: {
        component: ["prefix", "suffix"],
      },
    },

    deadline : {
      classification: "date",
      component : "FormulateDateInput"
    }
  },
});
