<template>
  <section class="mx-4 sm:mx-6 lg:mx-8" :class="{ 'bg-white shadow': !transparent, 'sm:rounded-lg': rounded }">
    <slot />
  </section>
</template>

<script>
export default {
  name: "SectionContainer",
  props: {
    transparent: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
