import { axiosInstance } from "@/api/axiosInstances";
import formatDate from "@/utils/formatDate";

export async function getEstimates() {
  const result = await axiosInstance.get(`/api/estimates?with_archived=true`);

  return result.data;
}

export async function getFilteredEstimates({ status, name, is_archived, page }) {
  if (status !== "All") {
    if (!is_archived) {
      const { data } = await axiosInstance.get(
        `/api/estimates?page=${page}&filter[status]=${status}&filter[name]=${name ? name : ""}&showArchived=${
          is_archived ? 1 : 0
        }`
      );

      return data;
    } else {
      const { data } = await axiosInstance.get(`/api/estimates/archived?page=${page}&filter[name]=${name ? name : ""}`);

      return data;
    }
  } else {
    if (is_archived) {
      const { data } = await axiosInstance.get(`/api/estimates/archived?page=${page}&filter[name]=${name ? name : ""}`);

      return data;
    } else {
      const { data } = await axiosInstance.get(
        `/api/estimates?page=${page}&filter[name]=${name ? name : ""}&showArchived=${is_archived ? 1 : 0}`
      );

      return data;
    }
  }
}

export async function getArchivedEstimates() {
  const { data } = await axiosInstance.get("/api/estimates?showArchived=true");

  return data.data;

  // return await Promise.all(
  //   estimates.map(async (estimate) => {
  //     const team = await getEstimateUsers(estimate.id);
  //
  //     return { ...estimate, team };
  //   })
  // );
}

export async function addNewEstimate({ name, deadline, team, categories }) {
  if (!categories) categories = [];

  categories = categories.map((category) => {
    return { name: category.name , id  : category.id };
  });

  const newEstimate = await axiosInstance.post(
    "/api/estimates",
    categories.length
      ? {
          categories,
          users: team.map((user) => user.id),
          name,
          deadline: formatDate(deadline),
        }
      : { users: team.map((user) => user.id), name, deadline: formatDate(deadline) }
  );

  return newEstimate;
}

export async function deleteEstimate(id) {
  await axiosInstance.delete(`/api/estimates/${id}`);
}

export async function archiveEstimate(id) {
  await axiosInstance.post(`/api/estimates/archived/${id}`);
}

export async function updateEstimate({ id, team, name, deadline, categories }) {
  categories = categories.map((category) => {
    return { ...category, rate_per_hour: category.ratePerHour };
  });

  const result = await axiosInstance.put(`/api/estimates/${id}`, {
    name,
    users: team.map((user) => user.id),
    deadline: formatDate(deadline),
    categories,
  });

  return result.data.data;
}

export async function restoreEstimate(id) {
  await axiosInstance.delete(`/api/estimates/archived/${id}`);
}

export async function getEstimateById(id) {
  const { data } = await axiosInstance.get(`/api/estimates/${id}`);

  return data.data;
}

export async function updateEstimateByNotOwner({ id, categories, team }) {
  categories = categories.map((category) => {
    return { ...category, rate_per_hour: category.ratePerHour };
  });

  const result = await axiosInstance.put(`/api/estimates/${id}`, {
    users: team.map((user) => user.id),
    categories,
  });

  return result.data.data;
}

export async function updateDescription({ id, description }) {
  const result = await axiosInstance.put(`/api/estimates/${id}`, {
    description,
  });

  return result.data.data;
}
