<template>
  <notifications position="top right" width="500px" :max="3">
    <template #body="{ item, close }">
      <error-toast :item="item" :close="close" />
    </template>
  </notifications>
</template>

<script>
import ErrorToast from "@/components/kit/ErrorToast";
export default {
  name: "OverlayNotifications",
  components: { ErrorToast },
};
</script>

<style scoped></style>
