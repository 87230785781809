<template>
  <layout v-if="!loading">
    <section-container :transparent="true">
      <section-header
        :tabs="tabs"
        :current-tab="filters.tab"
        @change="changeTab"
        action-name="Create new project"
        @openSideOver="createNewEstimate"
      >
        <div class="flex items-center">
          <e-search class="h-10 w-80" placeholder="Estimate name" v-model="search" :initial-state="search" />
        </div>
      </section-header>
    </section-container>
    <section-container v-if="filters.tab !== 'Archive' && allCount">
      <filter-section @change="changeFilter" :selected-filter="filters.filter" :tabs="filterData" />
    </section-container>
    <transition name="slide-fade" mode="out-in">
      <side-over-new-project
        @addEstimate="addNewEstimate($event)"
        @updateEstimate="updateProject($event)"
        @updateEstimateByNotOwner="updateProjectByNotOwner"
        @archiveProject="showArchiveConfirmModal"
        @leaveAndClose="leaveProject"
        v-if="isOpen"
        :project-id="projectId"
        :loading="sideOverNewProjectLoading"
      />
    </transition>
    <transition name="slide-fade" mode="out-in">
      <empty-projects-view
        v-if="!filteredEstimates.length && !search && allCount === 0"
        @action="createNewEstimate"
      />
      <no-result v-else-if="!filteredEstimates.length && !search && allCount" :status="filters.filter" />
      <section-container v-else transparent rounded>
        <transition name="slide-fade" mode="out-in">
          <div
            v-if="!estimatesLoading && filteredEstimates.length"
            class="estimate-lists py-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            <new-project-item
              v-for="(project, index) in filteredEstimates"
              :key="project.id"
              :project="project"
              class="l-item"
              :order="index"
              @archiveProj="showArchiveConfirmModal($event)"
              @deleteProj="openDeleteModal($event)"
              @restoreProject="restoreProject"
              :active="!project.archived_at"
            />
          </div>
          <loading-spinner v-else-if="estimatesLoading" />
          <empty-filtered-list key="docState" v-else :searched-value="search" />
        </transition>
      </section-container>
    </transition>
    <pagination
      v-if="filteredEstimates.length && !estimatesLoading && paginationPagesCount >= 2"
      class="pb-4"
      :count="paginationPagesCount"
      :current-page="pagination.currentPage"
      @changePage="changePage"
    />
    <warning-archive-estimate @archive="archiveProject" />
    <congratulations-archive-estimate />
    <modal name="delete-estimate" width="512" height="194">
      <warning-delete-estimate
        :estimate-id="currentDeleteEstimateId"
        @close="closeDeleteModal"
        @deleteAndClose="deleteProject"
      />
    </modal>
  </layout>
  <loading-spinner v-else />
</template>

<script>
import SectionHeader from "@/layout/SectionHeader";
import Layout from "@/layout/Layout";
import SectionContainer from "@/layout/SectionContainer";
import EmptyProjectsView from "@/components/empty-data/EmptyProjectsView";
import NewProjectItem from "@/components/NewProjectItem";
import EmptyFilteredList from "@/components/empty-data/EmptyFilteredList";
import SideOverNewProject from "@/views/SideOverNewProject";
import FilterSection from "@/layout/SectionFilter";
import ESearch from "@/components/forms/ESearch";
import Pagination from "@/components/pagination/Pagination";
import LoadingSpinner from "@/components/LoadingSpinner";
import NoResult from "@/components/empty-data/NoResult";
import CongratulationsArchiveEstimate from "@/components/modals/CongratulationsArchiveEstimate";
import WarningDeleteEstimate from "@/components/modals/WarningDeleteEstimate";
import WarningArchiveEstimate from "@/components/modals/WarningArchiveEstimate";
import { mapGetters, mapActions } from "vuex";
import VueRouter from "vue-router";
import { leaveFromEstimate } from "@/api/apiEstimateUsers";

export default {
  name: "ProjectsPage",
  components: {
    WarningArchiveEstimate,
    NoResult,
    SideOverNewProject,
    EmptyFilteredList,
    NewProjectItem,
    SectionContainer,
    Layout,
    SectionHeader,
    EmptyProjectsView,
    FilterSection,
    ESearch,
    Pagination,
    LoadingSpinner,
    CongratulationsArchiveEstimate,
    WarningDeleteEstimate,
  },

  data() {
    return {
      filters: {
        tab: "All estimates",
        filter: "To do",
      },
      currentDeleteEstimateId: null,
      search: "",
      projectId: null,
      loading: false,
      page: 1,
      pagination: {
        currentPage: 0,
      },
      estimatesLoading: false,
      sideOverNewProjectLoading: false,
    };
  },
  async created() {
    this.loading = true;
    this.filters = {
      filter: this.$route.query.filter ? this.$route.query.filter : "To do",
      tab: this.$route.query.tab ? this.$route.query.tab : "All estimates",
    };
    this.search = this.$route.query.search ? this.$route.query.search : "";
    this.page = this.$route.query.page ? this.$route.query.page : 1;
    await this.getEstimates({ status: "To do" });

    const response = await this.getFilteredEstimates({
      page: this.page,
      name: this.search,
      status: this.filters.filter,
      is_archived: this.filters.tab === "Archive",
    });

    this.pagination.currentPage = response.meta.current_page;

    if (this.paginationPagesCount < this.pagination.currentPage) {
      this.page = this.paginationPagesCount;
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters({
      estimates: "estimates/estimates",
      filteredEstimates: "estimates/filteredEstimates",
      archivedEstimates: "estimates/archivedEstimates",
      todoCount: "estimates/toDoCount",
      inProgressCount: "estimates/inProgressCount",
      doneCount: "estimates/doneCount",
      allCount: "estimates/allCount",
      paginationPagesCount: "estimates/pagination_count",
      isOpen : "sideOver/isOpen",
      archivedCount : "estimates/archivedCount",
      user : "user/user"
    }),

    filterData() {
      return [
        {
          text: "To do",
          count: this.todoCount,
        },
        {
          text: "In progress",
          count: this.inProgressCount,
        },
        {
          text: "Done",
          count: this.doneCount,
        },
        {
          text: "All",
          count: this.allCount,
        }
      ];
    },

    tabs() {
      const tabs = ["All estimates"];

      if (this.archivedEstimates.length || this.archivedCount > 0) tabs.push("Archive");

      return tabs;
    },
  },

  watch: {
    page() {
      this.updateUrl();
    },

    search(value) {
      this.page = 1;
      const { path, query } = this.$route;

      this.$router.replace({ path, query: { ...query, search: value } });
    },

    $route: {
      handler: "getDataFromUrl",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      addEstimate: "estimates/addEstimate",
      getEstimates: "estimates/getEstimates",
      getFilteredEstimates: "estimates/getFilteredEstimates",
      deleteEstimate: "estimates/deleteEstimate",
      restoreEstimate: "estimates/restoreEstimate",
      archiveEstimate: "estimates/archiveEstimate",
      updateEstimate: "estimates/updateEstimate",
      updateEstimateByNotOwner: "estimates/currentEstimate/updateEstimateByNotOwner",
      openCreatingNewEstimateMode: "sideOver/openCreatingNewEstimateMode",
      close: "sideOver/close"
    }),

    async updateEstimates() {
      const response = await this.getFilteredEstimates({
        page: this.page,
        status: this.filters.filter,
        name: this.search,
        is_archived: this.filters.tab === "Archive",
      });

      return Math.min(response.meta.current_page, response.meta.last_page);
    },

    async updateRouter() {
      const { path, query } = this.$route;
      const { isNavigationFailure, NavigationFailureType } = VueRouter;

      this.page = 1;

      this.$router
        .push({
          path,
          query: {
            ...query,
            tab: this.filters.tab,
            filter: this.filters.filter,
            page: this.page,
          },
        })
        .catch((error) => {
          if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error);
          }
        });
    },

    changePage(page) {
      this.page = page;
    },

    changeTab(tab) {
      this.filters.tab = tab;

      this.updateRouter();
    },

    changeFilter(filter) {
      this.filters.filter = filter;

      this.updateRouter();
    },

    async addNewEstimate(newEstimate) {
      try {
        this.sideOverNewProjectLoading = true;

        const estimate = await this.addEstimate(newEstimate);

        this.$router.push({ name: "project", params: { projectId: estimate.id } });
      }
      finally {
        this.sideOverNewProjectLoading = false;
      }
    },

    showArchiveConfirmModal(projectId) {
      this.$modal.show("confirm-archive", { estimateId: projectId });
    },

    async archiveProject(projectId) {
      await this.archiveEstimate(projectId);
      const lastPage = await this.updateEstimates();

      this.pagination.currentPage = lastPage;
      this.page = lastPage;
      this.$modal.hide("confirm-archive");
      this.$modal.show("archive-estimate-congratulation");
    },
    async deleteProject(projectId) {
      await this.deleteEstimate(projectId);
      const lastPage = await this.updateEstimates();

      this.pagination.currentPage = lastPage;
      this.page = lastPage;
      this.closeDeleteModal();
    },
    async restoreProject(projectId) {
      await this.restoreEstimate(projectId);
      await this.updateEstimates();
    },

    showArchiveModal() {
      this.$modal.show("archive-estimate-congratulation");
    },

    closeArchiveModal() {
      this.$modal.hide("archive-estimate");
    },

    closeDeleteModal() {
      this.currentDeleteEstimateId = null;
      this.$modal.hide("delete-estimate");
    },

    openDeleteModal(estimateId) {
      this.currentDeleteEstimateId = estimateId;
      this.$modal.show("delete-estimate");
    },

    async updateProject(project) {
      try
      {
        this.sideOverNewProjectLoading = true;
        await this.updateEstimate(project);
        await this.updateEstimates();
        // await updateCategories({ estimateId: project.id, categories: project.categories });
      }
      finally
      {
        this.close();

        this.sideOverNewProjectLoading = false;
      }
    },

    async updateProjectByNotOwner(project) {
      try {
        this.sideOverNewProjectLoading = true;
        await this.updateEstimateByNotOwner(project);
        await this.updateEstimates();
      }
      finally {
        this.sideOverNewProjectLoading = false;
        this.close();
      }
    },

    updateUrl() {
      const { path, query } = this.$route;
      const { isNavigationFailure, NavigationFailureType } = VueRouter;
      this.$router.push({ path, query: { ...query, ...this.filters, page: this.page } }).catch((error) => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
          throw Error(error);
        }
      });
    },

    async leaveProject(estimateId) {
      this.sideOverNewProjectLoading = true;

      await leaveFromEstimate({ estimateId });

      const lastPage = await this.updateEstimates();

      this.pagination.currentPage = lastPage;
      this.page = lastPage;


      this.projectId = null;
      this.close();
      this.sideOverNewProjectLoading = false;
    },

    async getDataFromUrl() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.search = this.$route.query.search ? this.$route.query.search : "";

      this.filters = {
        tab: this.$route.query.tab ? this.$route.query.tab : "All estimates",
        filter: this.$route.query.filter ? this.$route.query.filter : "All",
      };

      this.estimatesLoading = true;

      const response = await this.getFilteredEstimates({
        status: this.filters.filter,
        name: this.search,
        is_archived: this.filters.tab === "Archive",
        page: this.page,
      });

      this.estimatesLoading = false;

      this.pagination.currentPage = response.meta.current_page;

      if (this.paginationPagesCount < this.pagination.currentPage) {
        this.page = this.paginationPagesCount;
      }
    },

    async createNewEstimate(){
      const newEstimate = await this.addEstimate({
        name: "",
        deadline: new Date().toISOString(),
        team: [this.user],
        categories: []
      })

      this.$router.replace({ name: "project", params: { projectId: newEstimate.id }, query : {
        openSettings : true
        } });
    }
  },
};
</script>

<style scoped>
.l-item {
  display: inline-block;
  transition: all 0.5s;
}

.l-enter {
  transform: translateX(10px);
  opacity: 0;
}

.l-enter-to {
  opacity: 1;
}

.l-leave {
  opacity: 1;
}

.l-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.l-leave-active,
.l-enter-active {
  position: absolute;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.estimate-lists {
  min-height: 56rem;
  align-content: start;
}
</style>
