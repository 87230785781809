<template>
  <nav class="bg-white border-b border-gray-100 mt-2 sm:mt-4">
    <div class="max-w-7xl mx-auto">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex space-x-4 mr-12">
            <tab v-for="tab in tabs" :key="tab" @click="$emit('change', tab)" :current="tab === currentTab">
              {{ tab }}
            </tab>
          </div>
          <slot />
        </div>

        <div class="flex items-center ml-8">
          <e-button type="primary" class="w-40" @click="$emit('openSideOver')"
            ><icon-plus class="mr-2 plus-icon" /> New estimate</e-button
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import EButton from "@/components/base/EButton";
import Tab from "@/components/Tab";
import IconPlus from "@/components/icons/IconPlus";

export default {
  name: "SectionHeader",
  components: { EButton, Tab, IconPlus },
  props: {
    actionName: {
      type: String,
      required: false,
    },
    tabs: {
      type: Array,
    },
    currentTab: {},
  },
  watch: {
    tabs() {
      if (this.tabs.indexOf(this.currentTab) === -1) {
        this.$emit("change", this.tabs[0]);
      }
    },
  },

  created() {
    if (this.tabs.indexOf(this.currentTab) === -1) {
      this.$emit("change", this.tabs[0]);
    }
  },
  emits: ["action"],
};
</script>

<style></style>
