<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16667 4.66658H14.8333M3.16667 4.66658C2.24619 4.66658 1.5 3.92039 1.5 2.99992C1.5 2.07944 2.24619 1.33325 3.16667 1.33325H14.8333C15.7538 1.33325 16.5 2.07944 16.5 2.99992C16.5 3.92039 15.7538 4.66658 14.8333 4.66658M3.16667 4.66658L3.16667 12.9999C3.16667 13.9204 3.91286 14.6666 4.83333 14.6666H13.1667C14.0871 14.6666 14.8333 13.9204 14.8333 12.9999V4.66658M7.33333 7.99992H10.6667"
      stroke="#818E9C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArchiveMenu",
};
</script>
