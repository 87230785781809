import { axiosInstance } from "@/api/axiosInstances";

export async function getFeatures(estimateId, categoryId) {
  const { data } = await axiosInstance.get(`/api/estimates/${estimateId}/categories/${categoryId}/features`);

  return data.data;
}

export async function addFeature({ estimateId, categoryId, feature }) {
  const formData = new FormData();

  formData.append("name", feature.name);
  formData.append("hours", feature.hours);
  formData.append("id", feature.id);
  formData.append("ratePerHour", 30);

  const response = await axiosInstance.post(`/api/estimates/${estimateId}/categories/${categoryId}/features`, formData);

  return response.data.data;
}

export async function updateFeature({ estimateId, categoryId, id, feature }) {
  await axiosInstance.put(
    `/api/estimates/${estimateId}/categories/${categoryId}/features/${id}?name=${feature.name}&hours=${feature.hours}`
  );
}

export async function deleteFeature({ estimateId, categoryId, id }) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/categories/${categoryId}/features/${id}`);
}

export async function updateFeaturesOrder({estimateId, categoryId, features})
{
  const newOrder = features.map((f, index) => {
    return {
      id: f.id,
      order: index
    }
  })

  await axiosInstance.put(`/api/estimates/${estimateId}/categories/${categoryId}/features/order`, newOrder)
}
