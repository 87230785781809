var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadingDelete)?_c('div',{staticClass:"file cursor-pointer h-48 bg-white shadow-md hover:shadow-2xl flex flex-col rounded-t-xl border rounded-b-xl"},[_c('a',{ref:"link",staticClass:"h-32 grid justify-items-center items-center overflow-hidden rounded-t-xl",attrs:{"href":_vm.file.url,"download":_vm.file.name,"target":"_blank"},on:{"click":function($event){return _vm.onClick(_vm.file, $event)}}},[(_vm.file.preview && !_vm.isError)?_c('img',{staticClass:"h-32 w-full object-cover rounded-t-xl",attrs:{"src":_vm.file.preview,"alt":""},on:{"error":_vm.onError}}):_c('IconExport',{staticClass:"w-12 h-12",attrs:{"color":"#111827"}})],1),_c('button'),(_vm.isEdit)?_c('button',{staticClass:"bg-gray-200 rounded-full absolute top-2 right-2 p-1.5 hover:bg-gray-300",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onDelete.apply(null, arguments)}}},[_c('icon-trash',{staticClass:"w-4 h-4 rounded-full",attrs:{"color":"#111827"}})],1):_vm._e(),_c('div',{staticClass:"border-t border-t-solid p-4 h-16 rounded-b-xl"},[_c('div',{staticClass:"relative flex justify-between"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: _vm.file.name,
            classes: 'tooltip-1',

            delay: {
              show: 200,
            },
          }),expression:"{\n            content: file.name,\n            classes: 'tooltip-1',\n\n            delay: {\n              show: 200,\n            },\n          }",modifiers:{"bottom":true}}],staticClass:"file text-gray-900 leading-5 block items-center break-all whitespace-nowrap overflow-hidden overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")])])])]):_c('div',{staticClass:"shadow-md rounded-t-xl border rounded-b-xl animate-pulse"},[_c('a',{ref:"link",staticClass:"h-32 grid justify-items-center items-center overflow-hidden rounded-t-xl",attrs:{"href":_vm.file.url,"download":_vm.file.name,"target":"_blank"}},[_c('div',{staticClass:"w-full h-full bg-gray-300"})]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-t border-t-solid p-4 h-16 rounded-b-xl"},[_c('div',{staticClass:"relative flex justify-between"},[_c('span',{staticClass:"w-40 h-4 bg-gray-400"})])])}]

export { render, staticRenderFns }