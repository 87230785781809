import { axiosInstance } from "@/api/axiosInstances";

export async function getEstimateUsers(id) {
  const { data } = await axiosInstance.get(`/api/estimates/${id}/users`);

  return data.data;
}

export async function addEstimateUsers({ id, team }) {
  await axiosInstance.post(`/api/estimates/${id}/users`, {
    users: team.map((user) => {
      return { id: user.id, is_admin: true };
    }),
  });
}

export async function detachEstimateUser(estimateId, userId) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/users/${userId}`);
}

export async function updateEstimateUsers({ id, team }) {
  const currentTeam = await getEstimateUsers(id);

  const toDelete = currentTeam.filter((user) => team.map((u) => u.id).indexOf(user.id) === -1);
  const toAdd = team.filter((user) => currentTeam.map((u) => u.id).indexOf(user.id) === -1);

  await Promise.all(
    toDelete.map(async (user) => {
      await detachEstimateUser(id, user.id);
    })
  );

  if (toAdd.length) await addEstimateUsers({ id, team: toAdd });
}

export async function inviteUser({ email }) {
  const result = await axiosInstance.post(`/api/invitations`, {
    user_email: email,
  });

  return result.data.data;
}

export async function leaveFromEstimate({ estimateId }) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/leave`);
}``

export async function updateEstimateUsersList({estimateId , users}){
  const response = await axiosInstance.put(`/api/estimates/${estimateId}/users`, { users : users.map(user => user.id)});

  return response;
}

export async function addUser({estimateId , userId}){
  await axiosInstance.post(`/api/estimates/${estimateId}/users/${userId}`)
}

export async function deleteUser({estimateId, userId}){
  await axiosInstance.delete(`/api/estimates/${estimateId}/users/${userId}`)
}
