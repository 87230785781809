<template>
  <div class="flex flex-col global-cont items-center relative">
    <div v-if="!loading" :style="{
        'width' : currentWidth + 'px'
      }" class="bg-gray-100 px-6 py-4 justify-between flex flex-row z-10" id="file-header">
      <span class="w-full truncate"> {{ file.name }} </span>
      <button @click="$emit('close')">
        <IconClose/>
      </button>
    </div>
    <div class="absolute top-0 left-0 right-0 bottom-0 z-0"></div>
    <img v-show="!loading" id="file-img" class="overflow-y-scroll object-contain z-20" :src="file.url" @load="loaded"/>
  </div>
</template>

<script>

function getContainedSize(img) {
  var ratio = img.naturalWidth/img.naturalHeight
  var width = img.height*ratio
  var height = img.height
  if (width > img.width) {
    width = img.width
    height = img.width/ratio
  }
  return [width, height]
}
import IconClose from "../icons/IconClose";
export default {
  name : 'PhotoView',
  data(){
    return{
      currentWidth : null,
      loading : true
    }
  },
  methods : {
    loaded(){
      this.loading = false
      setTimeout(() => this.currentWidth = getContainedSize(document.getElementById('file-img'))[0],0)
      // this.$nextTick(() => {
      //
      // })
    }
  },
  components : {IconClose},
  props : {
    file : {}
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
        this.currentWidth = getContainedSize(document.getElementById('file-img'))[0]
    })

    resizeObserver.observe(document.getElementById('file-img'))
  }
}
</script>

<style scoped>
.overlay-background{
  background-color: rgba(17, 24, 39, 0.5);
}

.pdf{
  overflow: hidden;
  max-width: calc(100vw - 80px);
  overflow-x:scroll;
}


@media screen and (min-width: 824px) {
  .pdf{
    overflow: hidden;
    max-width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .pdf{
    overflow: hidden;
    max-width: 1024px;
  }
}

.global-cont{
  max-height: 90%;
  max-width: calc(100% - 130px);
  overflow-x:hidden;
}
</style>
