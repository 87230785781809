<template>
  <div>
    <datetime
      class="theme-brand w-11/12 left-1  z-10"
      :min-datetime="context.attributes.minDatetime"
      name="deadline"
      v-model="
/* eslint-disable vue/no-mutating-props */ context.model"
      type="date"
      :hide-backdrop="false"
      :auto="true"
      label="Deadline"
      input-class="block rounded-md mt-1 w-full border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      :input-id="'deadline-input'"
    >
      <label for="deadline-input" slot="before" class="block text-sm font-medium text-gray-700">Deadline</label>
    </datetime>

<!--    <FormulateInput-->
<!--      type="date"-->
<!--      name="deadline"-->
<!--      input-class="block rounded-md mt-1 w-full border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"-->
<!--      :min="context.attributes.minDatetime"-->
<!--      v-model="-->
<!--/* eslint-disable vue/no-mutating-props */ context.model"-->
<!--      input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"-->
<!--      outer-class="w-full absolute top-0  height-38"-->
<!--      label="Deadline"-->
<!--      label-class="block text-sm font-medium text-gray-700"-->
<!--      error-class="mt-2 text-sm text-red-600"-->
<!--      error-behavior="submit"-->
<!--    />-->
<!--    <span>{{ this.context.model}}</span>-->
  </div>
</template>

<script>
export default {
  name: "FormulateDateInput",
  props : {
    context : {
      type : Object,
      required : true
    }
  }
}
</script>


<style>
.vdatetime-popup {
  position: absolute !important;
  top: 0 !important;
  transform: none !important;
  right: 0 !important;
  max-width: 500px !important;
  left: auto !important;
}

/*#deadline-input{*/
/*  height: 38px;*/
/*}*/
</style>
