<template>
  <div class="w-full flex flex-1 items-center justify-center flex-col px-12 text-center">
    <div class="text-neutrals-1000 leading-9 font-xl">No data yet</div>
    <div class="text-neutral-500 text-sm">Features of parts will display here. Start adding new category</div>
    <icon-empty-list />
  </div>
</template>

<script>
import IconEmptyList from "@/components/icons/IconEmptyList";

export default {
  name: "EmptyDataFeaturesTable",
  components: { IconEmptyList },
};
</script>
