<template>
  <div class="mt-1 flex rounded-lg">
    <div class="relative flex items-stretch flex-grow">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <IconSearch class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <FormulateInput
        type="text"
        :input-class="classes"
        class="w-full"
        v-model="searchText"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>
<script>
import IconSearch from "@/components/icons/IconSearch";
import _debounce from "lodash.debounce";

export default {
  name: "ESearch",
  components: { IconSearch },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
      validator: (s) => ["primary", "secondary"].includes(s),
    },

    initialState: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      switch (this.type) {
        case "primary":
          return "block w-full pl-10 p-2 text-sm border-gray-300 rounded-lg bg-gray-50";
        case "secondary":
          return "block w-full pl-10 p-2 text-sm border-gray-300 rounded-md bg-white shadow-sm";
        default:
          return "";
      }
    },
  },
  methods: {
    debouncedChange: _debounce(function (value) {
      this.$emit("input", value);
    }, 200),
  },
  watch: {
    searchText: {
      handler: "debouncedChange",
    },
  },
  data() {
    return {
      searchText: this.initialState,
    };
  },
};
</script>
