<template>
  <div class="flex flex-col justify-center items-center p-8 space-y-4">
    <div class="grid grid-flow-col space-x-6">
      <warning-mark />
      <div class="space-y-2">
        <h3 class="font-bold leading-6 text-neutral-1000">Delete estimate</h3>
        <p class="text-sm leading-5 text-neutral-500">
          Are you sure you want to delete a estimate? All of your data will be permanently removed from our servers
          forever. This action cannot be undone.
        </p>
      </div>
    </div>
    <div class="flex justify-end space-x-4 w-full block">
      <EButton type="secondary" @click="close">Cancel</EButton>
      <EButton type="error" @click="deleteAndClose">Delete</EButton>
    </div>
  </div>
</template>

<script>
import WarningMark from "@/components/marks/WarningMark";
import EButton from "@/components/base/EButton";

export default {
  name: "WarningDeletePartModal",
  components: { WarningMark, EButton },
  props: {
    estimateId: {
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    deleteAndClose() {
      this.$emit("deleteAndClose", this.estimateId);
    },
  },
};
</script>
