<template>
  <span class="flex py-2 items-center space-x-4">
    <user-avatar size="small" :avatar="option.avatar" />
    <div class="block">
      <span class="font-normal block truncate text-gray-900">{{ option.name }} </span>
      <span class="font-normal block truncate text-gray-500">{{ option.email }}</span>
    </div>
  </span>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "MultipleSelectOption",
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  components: { UserAvatar },
};
</script>
