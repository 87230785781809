export default [
  "#922FEE",
  "#BB8AEA",
  "#EC9BA0",
  "#E5616A" ,
  "#FF9D47",
  "#FFCE80",
  "#8DADE1",
  "#3A78DE",
  "#FFC6FF",
  "#9B76F1",
  "#FF7A78",
  "#F8BA57"
]
