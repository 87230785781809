<template>
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10H18V8H0V10ZM0 0V2H18V0H0ZM0 6H18V4H0V6Z" fill="#4F46E5" />
  </svg>
</template>
<script>
export default {
  name: "IconDrag",
};
</script>
