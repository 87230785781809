<template>
  <div class="w-full shadow-xl rounded-xl border border-gray-200 mb-6 h180" id="parts-block">
    <h3 class="p-4 border-b border-gray-200 leading-7 text-xl shadow-sm">Parts of estimation:</h3>
    <div class="grid grid-cols-navWithTable">
      <ScrollNavbar
        class="flex-1"
        v-model="currentCategoryId"
        :team="team"
        @openSettings="openSettings"
        :project-id="projectId"
      />
      <CategoryContent
        :project-id="projectId"
        :current-category-id="currentCategoryId"
        @changeCurrentCategory="resetCurrentCategory"
        @openSettings="openSettings"
      />
    </div>
  </div>
</template>

<script>
import ScrollNavbar from "@/components/scroll-navbar/ScrollNavbar";
import { mapGetters, mapActions } from "vuex";
import CategoryContent from "@/components/CategoryContent";
import { getEstimateCategories } from "@/api/apiEstimateCategories";

export default {
  name: "PartsOfEstimations",
  components: {
    ScrollNavbar,
    CategoryContent,
  },
  props: {
    team: {
      type: Array,
    },
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      currentCategoryId: null,
    };
  },
  watch: {
    currentCategory: {
      handler: "updateFeatures",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateStatus: "estimates/categories/updateCategoryStatus",
      deleteCategory: "estimates/categories/deleteCategory",
      getFeatures: "estimates/categories/features/getFeatures",
    }),

    openSettings(categoryId) {
      this.$emit("openSettings", categoryId);
    },

    async resetCurrentCategory() {
      const allCategories = await getEstimateCategories(this.projectId);

      if (allCategories.length > 0) this.currentCategoryId = allCategories[0].id;
    },

    async deleteEstimateCategory() {
      await this.deleteCategory({ estimateId: this.projectId, categoryId: this.currentCategory.id });
    },

    async changeStatus(status) {
      await this.updateStatus({
        estimateId: this.projectId,
        id: this.currentCategory.id,
        status,
      });
    },

    async updateFeatures() {
      await this.getFeatures({ estimateId: this.projectId, categoryId: this.currentCategory.id });
    },
  },

  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
      features: "estimates/categories/features/features",
      categoryById: "estimates/categories/category",
    }),
  },
};
</script>

<style scoped>
div >>> .__view {
  @apply flex flex-1 w-full;
}

.temp-cont {
  display: grid;
  grid-template-rows: auto 1fr;
}

.main {
  grid-template-rows: 1fr 5rem;
}

.h120 {
  height: 30rem;
}

.h104 {
  height: 26rem;
}


</style>
