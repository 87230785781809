<template>
  <div class="w-full h-full flex items-center justify-center flex-col px-12 text-center">
    <div class="text-neutrals-1000 leading-9 font-xl">No data yet</div>
    <div class="text-neutral-500 text-sm">Add new part to get started</div>
  </div>
</template>

<script>
export default {
  name: "EmptyDataScrollNavbar",
};
</script>
