<template>
  <modal name="too-large-file-size-modal" height="250" width="375">
    <div class="flex flex-col justify-center items-center p-8">
    <FailMark class="mb-4" />
    <h4 class="leading-6 font-bold text-center">Oops , the file is too large</h4>
    <span class="leading-5 text-sm text-center text-neutral-500">Max size -10mb</span>
    <EButton type="primary" class="block w-full mt-4" @click="close">Okay</EButton>
    </div>
  </modal>
</template>

<script>
// import SuccessMark from "@/components/marks/SuccessMark";
import EButton from "@/components/base/EButton";
import FailMark from "../marks/FailMark";

export default {
  name: "CongratulationsUpdateProfileModal",
  components: { FailMark, EButton },
  methods: {
    close() {
      this.$modal.hide('too-large-file-size-modal')
    },
  },
};
</script>
