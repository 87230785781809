<template>
  <div>
    <v-select
      :clerable="false"
      autofocus
      @search="onSearch"
      ref="partsInput"
      class="block mt-1 theme-brand col-span-8 parts-input"
      placeholder="iOS, UI/UX, etc."
    >
      <template #header>
        <label class="block text-sm font-medium mb-1 text-gray-700">Project parts</label>
      </template>
      <template #open-indicator>
        <span class="text-primary-500 pr-1 cursor-pointer leading-6" @click="addNewPart">
          <icon-plus class="h-5 w-5"/>
        </span>
      </template>
      <template #no-options>
        <div class="hidden">...</div>
      </template>
    </v-select>

    <div class="mt-4 space-y-4">
      <selected-part
        v-for="selectedPart in context.model"
        :key="selectedPart.id"
        :part="selectedPart.name"
        :part-id="selectedPart.id || selectedPart.clientId"
        @deletePart="deletePart(selectedPart.id || selectedPart.clientId)"
        @changeCurrentPart="changePart"
        :allow-delete="selectedPart.allowDelete || context.attributes.allowDelete || selectedPart.user_id === user.id"
      />
    </div>
    <modal name="delete-part-modal" width="510" height="190">
      <warning-delete-part-modal
        @close="hideModal"
        @deleteAndClose="deletePartAndClose"
        :id-of-category="currentPartToDeleteId"
      />
    </modal>
  </div>
</template>

<script>
import SelectedPart from "@/components/SelectedPart";
import WarningDeletePartModal from "@/components/modals/WarningDeletePartModal";
import { v4 as uuid } from "uuid";
import { mapGetters, mapActions } from "vuex";
import IconPlus from "../icons/IconPlus";

export default {
  name: "FormulateProjectPartsInput",
  components: { IconPlus, SelectedPart, WarningDeletePartModal },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentText: "",
      currentPartToDeleteId: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/user",
      currentEstimateId : "estimates/currentEstimate/currentEstimateId"
    }),

    validCurrentText() {
      return this.currentText.length > 2;
    },
  },

  methods: {
    ...mapActions({
      updateCategories : "estimates/currentEstimate/updateCategories",
      addCategory: "estimates/currentEstimate/addCategory",
      deleteCategory : "estimates/currentEstimate/deleteCategory"
    }),

    onSearch(value) {
      this.currentText = value;
    },

    async addNewPart() {
      if (!this.currentText.trim()) return;

      const emptyCategory = {
        name: this.currentText,
        status: "To do",
        hidden: false,
        ratePerHour: 30,
        hours: 0,
        cost: 0,
        features: [],
        id: uuid(),
        allowDelete: true,
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = [...this.context.model, emptyCategory];


      // await this.updateCategories({estimateId : this.currentEstimateId, categories: this.context.model})

      await this.addCategory({estimateId : this.currentEstimateId, category : emptyCategory})
      this.$refs.partsInput.search = "";
    },

    deletePart(id) {
      this.currentPartToDeleteId = id;
      this.$modal.show("delete-part-modal");
    },

    async deletePartAndClose(id) {
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = this.context.model.filter((category) => {
        return category.id !== id && category.clientId !== id;
      });


      // await this.updateCategories({estimateId : this.currentEstimateId, categories: this.context.model})
      await this.deleteCategory({estimateId : this.currentEstimateId, categoryId : id});
      this.hideModal();
    },

    async changePart({ id, value }) {
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = this.context.model.map((category) => {
        if (category.id === id || category.clientId === id) {
          return { ...category, name: value };
        } else {
          return category;
        }
      });


      await this.updateCategories({estimateId : this.currentEstimateId, categories: this.context.model})
    },

    hideModal() {
      this.$modal.hide("delete-part-modal");
    },
  },

  mounted() {
    this.$refs.partsInput.$refs.search.addEventListener("keydown", (e) => {
      if (e.code == "Enter") {
        this.addNewPart();
      }
    });

    this.$refs.partsInput.$refs.search.maxLength = 100;

    this.$refs.partsInput.onSearchBlur = () => {};
  },

  async created() {
    if (this.context.attributes.initialState) {
      // eslint-disable-next-line vue/no-mutating-props
      this.context.model = this.context.attributes.initialState;
    }
  },
};
</script>

<style>
.parts-input .vs__search::placeholder {
  color: #818e9c;
}

.parts-input .vs__dropdown-menu {
  padding: 0 !important;
  border: none !important;
  margin-top: 0.25rem !important;
  @apply rounded-sm border;
}

.parts-input .vs__dropdown-toggle {
  @apply border border-neutral-400 shadow;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 1 !important;
}
</style>
