<template>
  <row :class="rowClasses">
    <formulate-editable-content
      v-if="isEdit"
      :initial-value="additionalExpense.name"
      placeholder="Name"
      @update="onChangeName"
      validation="required"
      class="col-start-1 col-end-3"
    >
    </formulate-editable-content>
    <static-content-label v-else type="black">{{ additionalExpenseName }}</static-content-label>
    <div v-if="isEdit" class="col-start-4 col-end-6 flex justify-end">
      <formulate-editable-content
        class="cost-input"
        :initial-value="additionalExpense.cost"
        placeholder="Cost"
        :exist-prefix="true"
        @update="onChangeCost"
        validation="number|required|min:0,value|max:10000000,value"
      >
        <template #prefix> <span class="text-gray-500 sm:text-sm"> $ </span></template>
      </formulate-editable-content>
    </div>
    <static-content-label v-else class="col-start-4 text-right">${{ additionalExpense.cost }}</static-content-label>
    <div
      v-if="isEdit"
      class="cursor-pointer col-start-6 text-purple-500 flex justify-center items-center mb-2"
      @click="deleteExpense"
    >
      <icon-trash-outline class="h-5 w-5" color="#7F39FB" />
    </div>
  </row>
</template>

<script>
// import ClickToEditLabel from "../../../../components/kit/ClickToEditLabel";

import StaticContentLabel from "../../../../components/tables/StaticContentLabel";
import IconTrashOutline from "@/components/icons/IconTrashOutline";
import { mapActions, mapGetters } from "vuex";
import Row from "../../../../components/tables/rows/Row";
import FormulateEditableContent from "@/components/formulate-inputs/FormulateEditableContent";
import stringWithLimit from "@/utils/StringWithLimit";

export default {
  name: "AdditionalExpenseRow",
  components: { Row, StaticContentLabel, IconTrashOutline, FormulateEditableContent },
  model: {
    prop: "additionalExpense",
    event: "change",
  },
  props: {
    additionalExpense: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dataAdditionalExpense: { ...this.additionalExpense },
    };
  },
  watch: {
    dataAdditionalExpense: {
      handler: "onChange",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateAdditionalExpense: "estimates/currentEstimate/updateAdditionalExpense",
      deleteAdditionalExpense: "estimates/currentEstimate/deleteAdditionalExpense",
    }),

    // async onChange(value) {
    //   await this.updateAdditionalExpense({
    //     estimateId: this.$route.params.projectId,
    //     additionalExpense: value,
    //   });
    // },

    async onChangeName(value) {
      await this.updateAdditionalExpense({
        estimateId: this.$route.params.projectId,
        additionalExpense: {
          ...this.additionalExpense,
          name: value,
        },
      });
    },

    async onChangeCost(value) {
      await this.updateAdditionalExpense({
        estimateId: this.$route.params.projectId,
        additionalExpense: {
          ...this.additionalExpense,
          cost: value,
        },
      });
    },
    async deleteExpense() {
      await this.deleteAdditionalExpense({
        estimateId: this.$route.params.projectId,
        additionalExpenseId: this.additionalExpense.id,
      });
      // this.$emit("delete", this.additionalExpense);
    },
  },
  computed: {
    rowClasses() {
      return this.isEdit ? "grid-cols-technologies-edit-mode" : "grid-cols-technologies-view-mode";
    },

    additionalExpenseName() {
      return stringWithLimit(this.additionalExpense.name, 30);
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
};
</script>


<style scoped>
.cost-input{
  max-width: 100px;
}
</style>
