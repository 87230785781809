<template>
  <div
    v-if="!loadingDelete"
    class="file cursor-pointer h-48 bg-white shadow-md hover:shadow-2xl flex flex-col rounded-t-xl border rounded-b-xl"
  >
    <a
      :href="file.url"
      :download="file.name"
      target="_blank"
      class="h-32 grid justify-items-center items-center overflow-hidden rounded-t-xl"
      ref="link"
      @click="onClick(file, $event)"
    >
      <img v-if="file.preview && !isError" :src="file.preview" @error="onError" class="h-32 w-full object-cover rounded-t-xl" alt="" />
      <IconExport v-else class="w-12 h-12" color="#111827" />
    </a>
    <button></button>
    <button
      v-if="isEdit"
      @click.prevent.stop="onDelete"
      class="bg-gray-200 rounded-full absolute top-2 right-2 p-1.5 hover:bg-gray-300"
    >
      <icon-trash class="w-4 h-4 rounded-full" color="#111827" />
    </button>
    <div class="border-t border-t-solid p-4 h-16 rounded-b-xl">
      <div class="relative flex justify-between">
        <span
          class="file text-gray-900 leading-5 block items-center break-all whitespace-nowrap overflow-hidden overflow-ellipsis"
          v-tooltip.bottom="{
            content: file.name,
            classes: 'tooltip-1',

            delay: {
              show: 200,
            },
          }"
        >
          {{ file.name }}
        </span>
      </div>
    </div>
  </div>
  <div v-else class="shadow-md rounded-t-xl border rounded-b-xl animate-pulse">
<!--    <div class="animate-pulse flex space-x-4">-->
<!--      <div class="rounded-full bg-blue-400 h-12 w-12"></div>-->
<!--      <div class="flex-1 space-y-4 py-1">-->
<!--        <div class="h-4 bg-blue-400 rounded w-3/4"></div>-->
<!--        <div class="space-y-2">-->
<!--          <div class="h-4 bg-blue-400 rounded"></div>-->
<!--          <div class="h-4 bg-blue-400 rounded w-5/6"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <a
      :href="file.url"
      :download="file.name"
      target="_blank"
      class="h-32 grid justify-items-center items-center overflow-hidden rounded-t-xl"
      ref="link"
    >
      <div class="w-full h-full bg-gray-300">

      </div>
    </a>
    <div class="border-t border-t-solid p-4 h-16 rounded-b-xl">
      <div class="relative flex justify-between">
       <span class="w-40 h-4 bg-gray-400"></span>
      </div>
    </div>
  </div>
</template>

<script>
import IconExport from "@/components/icons/IconExport";
import IconTrash from "@/components/icons/IconTrash";
import { mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "FileItem",
  props: {
    file: {
      require: true,
      type: Object,
    },
  },
  components: { IconExport, IconTrash },
  mixins: [clickaway],
  data() {
    return {
      isSettingsOpen: false,
      isError : false,
      loadingDelete : false
    };
  },
  methods: {
    onClick(file , event){
      const re = /.*\.pdf$/
      const photoRe = /\.(jpeg|jpg|gif|png)$/
      if(re.test(file.url)){
        this.$emit('openFile' , file)
        event.preventDefault()
        return false
      }

      if(photoRe.test(file.url)){
        this.$emit('openFile' , file)
        event.preventDefault()
        return false
      }

    },

    away() {
      this.isSettingsOpen = false;
    },
    onDelete() {
      this.loadingDelete = true;
      this.$emit("deleteFile", this.file.id);
    },
    downloadFile() {
      this.$refs.link.dispatchEvent(new MouseEvent("click"));
    },
    onError(){
      this.isError = true;
    }
  },
  computed: {
    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
};
</script>

<style>
.menu-item {
  @apply group w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
}

.tooltip-1 {
  @apply relative mt-10 bg-white border border-gray-200 shadow-lg px-4 py-2 rounded-md text-gray-900;
}

.tooltip-1 .tooltip-arrow {
  position: absolute;
  top: -8px;
  border-width: 0 16px 16px 16px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: white !important;
  width: 0;
  height: 0;
}
</style>
