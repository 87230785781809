<template>
  <div class="cursor-pointer">
    <a
      @click.prevent="$emit('click')"
      class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
    >
      Next
      <IconArrowRight class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
    </a>
  </div>
</template>

<script>
import IconArrowRight from "@/components/icons/IconArrowRight";

export default {
  name: "PaginationLinkMoveNext",
  components: { IconArrowRight },
};
</script>
