<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5237 7.47631C9.22194 6.17456 7.11139 6.17456 5.80964 7.47631L2.47631 10.8096C1.17456 12.1114 1.17456 14.2219 2.47631 15.5237C3.77806 16.8254 5.88861 16.8254 7.19036 15.5237L8.10832 14.6057M7.47631 10.5237C8.77806 11.8254 10.8886 11.8254 12.1904 10.5237L15.5237 7.19036C16.8254 5.88861 16.8254 3.77806 15.5237 2.47631C14.2219 1.17456 12.1114 1.17456 10.8096 2.47631L9.8933 3.39265"
      stroke="#111827"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClip",
};
</script>
