<template>
  <div class="flex flex-row mt-6 items-center space-x-8 mb-10">
    <div class="relative">
      <user-avatar size="large" :avatar="user.avatar" />
      <button @click="triggerFileInput" type="button" class="absolute top-0 left-22 bg-white border border-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center">
        <icon-camera class="h-3 w-3"/>
      </button>
      <button @click="deleteAvatar" v-if="user.avatar" type="button" class="absolute bottom-0 left-22 bg-white border border-gray-200 rounded-full p-2 h-8 w-8 flex items-center justify-center text-red-500">
        <icon-trash-outline class="h-3 w-3"/>
      </button>
    </div>
    <formulate-form name="avatarPicker">
      <FormulateInput
        v-show="false"
        type="image"
        name="avatar"
        ref="photoInput"
        @file-upload-complete="updatePhoto"
        validation="mime:image/jpeg,image/png,image/gif"
      />
    </formulate-form>
    <slot>

    </slot>
    <loading-spinner-modal />
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import { axiosInstance } from "@/api/axiosInstances";
import { mapGetters, mapActions } from "vuex";
import { getUser } from "@/api/apiUser";
import LoadingSpinnerModal from "@/components/modals/LoadingSpinnerModal";
import IconCamera from "../icons/IconCamera";
import IconTrashOutline from "../icons/IconTrashOutline";

export default {
  name: "AvatarPicker",
  components: { IconTrashOutline, LoadingSpinnerModal, UserAvatar, IconCamera},
  data(){
    return{
      showErrorMessage : false
    }
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
      updateAvatar: "user/updateAvatar",
    }),

    triggerFileInput() {
      this.showErrorMessage = false
      this.$refs.photoInput.$el.querySelector("input").click();
    },
    async updatePhoto({ file }) {
      if(this.$refs.photoInput.hasErrors)
      {
        this.showErrorMessage = true
        return;
      }
      this.$modal.show("loading-spinner");
      await this.updateAvatar(file);
      this.$modal.hide("loading-spinner");
      this.$emit("success");
    },

    async deleteAvatar() {
      this.$modal.show("loading-spinner");
      await axiosInstance.delete("/api/avatar");
      this.$modal.hide("loading-spinner");
      this.$emit("success");
      this.updateUser(await getUser());
    },
  },
};
</script>

<style scoped>
.left-22{
  left : 5.25rem;
}
</style>
