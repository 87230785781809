<template>
  <layout>
    <div>
      <div class="pt-8 space-y-6">
        <section-container rounded>
          <div class="py-5 px-4 sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">General</h3>
                <p class="mt-1 text-sm text-gray-500">Basic information regarding the project.</p>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <FormulateForm class="grid grid-cols-6 gap-6">
                  <FormulateInput
                    error-behavior="submit"
                    error-class="mt-2 text-sm text-red-600"
                    input-class="block mt-1 w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                    label="Project name"
                    label-class="block text-sm font-medium text-gray-700"
                    name="name"
                    outer-class="col-span-6 sm:col-span-4"
                    validation="bail|required|email"
                  />

                  <datetime
                    class="col-span-6 row-start-2 md:col-span-3 lg:col-span-2 theme-brand"
                    v-model="date"
                    type="date"
                    :hide-backdrop="false"
                    :auto="true"
                    input-class="block mt-1 w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <label class="block text-sm font-medium text-gray-700" slot="before">Deadline</label>
                  </datetime>

                  <FormulateInput
                    error-behavior="submit"
                    error-class="mt-2 text-sm text-red-600"
                    input-class="block mt-1 w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                    label="Description"
                    label-class="block text-sm font-medium text-gray-700"
                    name="description"
                    outer-class="col-span-6"
                    rows="5"
                    type="textarea"
                    validation="bail|required|email"
                  />
                </FormulateForm>
              </div>
            </div>
          </div>
        </section-container>
        <section-container rounded>
          <div class="py-5 px-4 sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Team</h3>
                <p class="mt-1 text-sm text-gray-500">Select users that will work on this estimate.</p>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">
                <FormulateForm class="grid grid-cols-6 gap-6">
                  <v-select
                    @input="setSelected"
                    :clearable="false"
                    class="col-span-6 sm:col-span-3 block mt-1 theme-brand"
                    :options="allTeam"
                    label="label"
                  >
                    <template #header>
                      <div class="block text-sm font-medium mb-1 text-gray-700">Select all members</div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span class="flex py-2 items-center">
                        <img :src="option.src" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" />
                        <span class="ml-3 block truncate">
                          {{ option.label }}
                        </span>
                      </span>
                    </template>
                  </v-select>
                </FormulateForm>
                <div class="mt-5 grid grid-cols-6 gap-6" v-if="selectedTeam.length > 0">
                  <ul
                    class="mt-1 col-span-6 sm:col-span-4 outline-none bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto sm:text-sm"
                    tabindex="-1"
                    role="listbox"
                    aria-labelledby="listbox-label"
                  >
                    <li
                      class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                      id="listbox-option-0"
                      role="option"
                      v-for="member in selectedTeam"
                      :key="member.id"
                    >
                      <div class="flex items-center">
                        <img :src="member.src" alt="" class="flex-shrink-0 h-6 w-6 rounded-full" />
                        <span class="font-normal ml-3 block truncate">{{ member.label }} </span>
                      </div>
                      <span
                        @click="removeMember(member)"
                        class="cursor-pointer group text-red-500 absolute inset-y-0 right-0 flex items-center pr-4"
                      >
                        <IconClose />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section-container>

        <section-container rounded>
          <div class="py-5 px-4 sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Resources</h3>
                <p class="mt-1 text-sm text-gray-500">Resources that can be used during estimation.</p>
              </div>
              <div class="mt-5 grid gap-6 grid-cols-6 md:mt-0 md:col-span-2">
                <FormulateForm class="col-span-3">
                  <FormulateInput
                    type="image"
                    name="resources"
                    multiple
                    label="Cover photo"
                    label-class="block pb-1 text-sm font-medium text-gray-700"
                    help="Select a png, jpg or gif to upload."
                    help-class="text-gray-500 text-xs"
                    validation="mime:image/jpeg,image/png,image/gif"
                  />
                </FormulateForm>
              </div>
            </div>
          </div>
        </section-container>

        <section-container class="flex justify-end" transparent>
          <button
            class="inline-flex justify-center py-2 px-4 m-1 text-sm font-medium text-white bg-indigo-600 rounded-md border border-transparent hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            type="submit"
          >
            Save
          </button>
        </section-container>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/layout/Layout";
import SectionContainer from "@/layout/SectionContainer";
import IconClose from "@/components/icons/IconClose";

export default {
  name: "NewProjectPage",
  components: {
    IconClose,
    SectionContainer,
    Layout,
  },
  data() {
    return {
      selectedTeam: [],
      allTeam: [
        {
          id: 1,
          label: "Anton",
          src:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=qWmp2rGaqc&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
        {
          id: 2,
          label: "Anananstas",
          src:
            "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixqx=qWmp2rGaqc&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
        {
          id: 3,
          label: "Btyghton Anananstas",
          src:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixqx=qWmp2rGaqc&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
        {
          id: 4,
          label: "Wade Cooper",
          src:
            "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixqx=qWmp2rGaqc&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        },
      ],
      date: "",
    };
  },
  methods: {
    setSelected(member) {
      this.selectedTeam.push(member);
      this.allTeam = this.allTeam.filter((team) => team.id !== member.id);
    },
    removeMember(member) {
      this.selectedTeam = this.selectedTeam.filter((team) => team.id !== member.id);
      this.allTeam.push(member);
    },
  },
};
</script>

<style>
.theme-brand .vdatetime-popup__header,
.theme-brand .vdatetime-calendar__month__day--selected > span > span,
.theme-brand .vdatetime-calendar__month__day--selected:hover > span > span {
  @apply bg-indigo-600;
}

.theme-brand .vdatetime-year-picker__item--selected,
.theme-brand .vdatetime-time-picker__item--selected,
.theme-brand .vdatetime-popup__actions__button {
  @apply text-indigo-600;
}

.theme-brand .vs__selected-options {
  @apply sm:text-sm py-1;
}
.theme-brand .vs__no-options {
  @apply sm:text-xs text-gray-700;
}
</style>
