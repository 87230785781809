<template>
  <div class="pie-chart-minimal-height flex flex-col">
    <h2
      class="flex justify-center text-neutral-1000 bg-neutral-100 header border-b border-neutral-300 uppercase text-xs px-4 py-3 grid place-items-center rounded-t-md"
    >
      {{ title }}
    </h2>
    <div
      class="flex justify-between items-center px-4 py-4"
      :class="apexchartContainerClasses"
      v-if="(categories.length && isAnyHour) || showIfEmpty"
    >
      <div class="grid border-r border-gray-200 w-1/2 legend overflow-hidden" :class="legendClasses">
        <legend-item
          v-for="(item, index) in legendArray"
          :key="index"
          :label="item.label"
          :percents="item.percents"
          :color="item.color"
          :order="index"
          :is-pdf-page="isPdfPage"
        />
      </div>
      <apexchart
        class="w-1/2 flex justify-center"
        :class="apexchartClasses"
        type="pie"
        width="280"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <empty-data-pie-chart v-else />
  </div>
</template>

<script>
import LegendItem from "./LegendItem";
import EmptyDataPieChart from "@/components/empty-data/EmptyDataPieChart";
import stringWithLimit from "@/utils/StringWithLimit";
import PieChartColors from "./PieChartColors";

export default {
  name: "PieChart",
  components: { LegendItem, EmptyDataPieChart },
  props: {
    title: {
      type: String,
      default: "Estimate breakdown",
    },
    inClientPage: {
      type: Boolean,
      default: true,
    },
    showStroke: {
      type: Boolean,
      default: true,
    },

    alwaysEmitIfReady: {
      type: Boolean,
      default: false,
    },

    tooltipTheme: {
      type: String,
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
      default: "light",
    },

    categories: {
      type: Array,
      required: true,
    },

    showIfEmpty: {
      type: Boolean,
      default: false,
    },

    isPdfPage : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: this.tooltipTheme,
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            show: true,
            format: "dd MMM",
            formatter: undefined,
            title: {
              formatter: () => "working",
            },
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName, { seriesIndex }) => {
                return stringWithLimit(this.categories[seriesIndex].name, 30);
              },
            },
          },
          z: {
            formatter: undefined,
            title: "Size: ",
          },
          marker: {
            show: true,
          },

          fixed: {
            enabled: false,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
        },
        chart: {
          id: "basic",
          type: "pie",
          events: {
            animationEnd: function () {
                window.pageIsReady = true;
            },
          },
          animations: {
            enabled: true,
            easing: "easeout",
            animateGradually: {
              enabled: true,
              delay: 100,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 500,
            },
          },
        },
        colors: [ ...PieChartColors ],
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        stroke: {
          show: this.showStroke,
          colors: ["#111"],
          width: 0.05,
        },
        labels: [],

        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  computed: {
    legendArray() {
      const result = [];
      let sum = 0;
      for (let i = 0; i < this.series.length; i++) sum += this.series[i];
      for (let i = 0; i < this.chartOptions.labels.length; i++)
        result.push({
          label: this.chartOptions.labels[i],
          percents: sum ? Number(((this.series[i] / sum) * 100).toFixed(1)) : 0,
          color: this.chartOptions.colors[i],
        });
      return result;
    },

    isAnyHour() {
      let result = 0;
      this.series.forEach((hours) => {
        result += hours;
      });

      return result > 0;
    },

    apexchartContainerClasses() {
      return this.inClientPage ? "pie-chart-max-width mx-auto" : "";
    },

    apexchartClasses() {
      return this.inClientPage ? "pl-32" : "";
    },

    legendClasses() {
      return this.categories.length > 3 ? "gap-x-6 grid-cols-2" : "";
    },
  },
  methods: {
    updateData() {
      this.series = [...this.categories].sort((a,b) => a.order - b.order).map((technology) => technology.hours);
      this.chartOptions.labels = [...this.categories].sort((a,b) => a.order - b.order).map((technology) => technology.name);
    },
  },
  watch: {
    categories: {
      handler: "updateData",
      deep: true,
    },
  },
  created() {
    this.updateData();
  },

  mounted() {
    if (this.alwaysEmitIfReady && !this.isAnyHour) {
      window.pageIsReady = true;
    }
  },
};
</script>

<style>
.apexcharts-pie-label {
  visibility: hidden !important;
}

.legend {
  min-height: 12rem;
}

.pie-chart-minimal-height {
  min-height: 16rem;
}

.pie-chart-max-width {
  width: 700px;
}
</style>
