import { getEstimateById, updateEstimate, updateEstimateByNotOwner, updateDescription } from "@/api/apiEstimates";
import requestQueue from "@/plugins/RequestQueue";
import { hideEstimateCategory, unhideEstimateCategory, updateCategories} from "@/api/apiEstimateCategories";
import { addFeature, deleteFeature, updateFeature } from "@/api/apiFeatures";
import { addUser, deleteUser} from "../../api/apiEstimateUsers";
import { addCategory, deleteCategory } from "../../api/apiEstimateCategories";
import { v4 as uuid } from "uuid";
import { addAdditionalExpenses, deleteAdditionalExpense, updateAdditionalExpense } from "@/api/apiAdditionalExpenses";
import { updateFeaturesOrder } from "../../api/apiFeatures";
import { duplicateCategory } from "../../api/apiEstimateCategories";
import getEstiamateStatus from "../../utils/getEstimateStatus";


export default {
  state: {
    estimate: {},
  },

  getters: {
    currentEstimate: (state) => state.estimate,
    categories: (state) => state.estimate.categories ? state.estimate.categories.sort((a,b) => a.order - b.order) : [],
    additionalExpenses: (state) => state.estimate.additional_expenses,
    team: (state) => state.estimate.users,
    currentEstimateId: (state) => state.estimate.id,
    currentCategory: (state) => (id) => state.estimate.categories.find((category) => category.id === id),
    isUserOwner: (state) => (id) => state.estimate.owner_id === id,
    isArchived: (state) => !state.estimate.archived_at,
    files : (state) => state.estimate.files,
    avalibleToModalFiles : (state) => {
      return state.estimate.files.filter(file => {
        const re = /.*\.pdf$/
        const photoRe = /\.(jpeg|jpg|gif|png)$/

        if(re.test(file.url) || photoRe.test(file.url))
          return true
        return false
      })
    }
  },

  mutations: {
    INIT_CURRENT_ESTIMATE: (state, estimate) => {
      state.estimate = estimate;
    },

    UPDATE_FILES: (state, files) => {
      state.estimate.files = files
    },

    UPDATE_ESTIMATE: (state, estimate) => {
      state.estimate = estimate;
    },

    UPDATE_CATEGORY: (state, { id, updatedCategory }) => {
      state.estimate.categories = state.estimate.categories.map((category) => {
        if (category.id === id) {
          return updatedCategory;
        } else {
          return category;
        }
      });
    },
  },

  actions: {
    initEstimate: ({ commit } , estimate) => {
      commit("INIT_CURRENT_ESTIMATE", estimate)
    },

    getEstimate: async ({ commit }, id) => {
      commit("INIT_CURRENT_ESTIMATE", await getEstimateById(id));
    },

    updateFiles({commit} , files){
      commit('UPDATE_FILES', files)
    },

    updateEstimate: async ({ commit }, payload) => {
      commit("UPDATE_ESTIMATE", await updateEstimate(payload));

      // const oldData = state.estimate;
      // console.log('payload', payload);
      // requestQueue.createRequest({
      //   request : updateEstimate,
      //   onMutate : () => {
      //     commit('UPDATE_ESTIMATE', { ...state.estimate, name : payload.name , users : payload.team, categories : payload.categories, deadline : payload.deadline})
      //   },
      //   onError : (error) => {
      //     console.log(error)
      //   },
      //   onSuccess : () => {}
      // })(payload)
    },

    updateDescription: async ({ commit }, payload) => {
      commit("UPDATE_ESTIMATE", await updateDescription(payload));
    },

    updateEstimateByNotOwner: async ({ commit }, payload) => {
      commit("UPDATE_ESTIMATE", await updateEstimateByNotOwner(payload));
    },

    updateCategory: async ({ commit, state }, { estimateId, id, updatedCategory }) => {
      const oldData = state.estimate;

      requestQueue.createRequest({
        request: updateCategories,
        onMutate: () => {
          commit("UPDATE_ESTIMATE", { ...state.estimate , categories : state.estimate.categories.map( c => c.id === id ? updatedCategory : c  ) , status : getEstiamateStatus( state.estimate.categories.map( c => c.id === id ? updatedCategory : c  ).map(c => c.status)) });
          },
        onError: (error) => {
          console.log(error);
          commit("UPDATE_ESTIMATE", oldData);
        },
        onSuccess: () => {},
      })({
        estimateId,
        categories:  state.estimate.categories.map((category) =>
          category.id !== id && category.clientId !== id ? category : { ...updatedCategory }
        ),
      });
    },

    updateCategoriesOrder: async ({commit , state} , {estimateId , newOrder}) => {
      const oldEstimate = state.estimate;

      requestQueue.createRequest({
        request: updateCategories,
        onMutate: () => {
          commit('UPDATE_ESTIMATE', {
            ...oldEstimate,
            categories: newOrder
          })
        },
        onError: () => {
          commit('UPDATE_ESTIMATE', {
            oldEstimate
          })
        },
        onSuccess: () => {
        }
      })({ estimateId , categories : newOrder})
    },

    hideCategory: async ({ commit, getters }, { estimateId, categoryId, updatedCategory }) => {
      const oldData = getters.categories;

      requestQueue.createRequest({
        request: hideEstimateCategory,
        onMutate: () => {
          commit("UPDATE_CATEGORY", { id: categoryId, updatedCategory });
        },
        onError: (error) => {
          console.log(error);
          commit("SET_CATEGORIES", oldData);
        },
        onSuccess: () => {},
      })({ estimateId, id: categoryId });
    },

    unhideCategory: async ({ commit, getters }, { estimateId, categoryId, updatedCategory }) => {
      const oldData = getters.categories;

      requestQueue.createRequest({
        request: unhideEstimateCategory,
        onMutate: () => {
          commit("UPDATE_CATEGORY", { id: categoryId, updatedCategory });
        },
        onError: (error) => {
          console.log(error);
          commit("SET_CATEGORIES", oldData);
        },
        onSuccess: () => {},
      })({ estimateId, id: categoryId });
    },

    duplicateCategory: async( {commit, state}, { estimateId,  duplicatedCategory}) => {
      const oldData = state.estimate.categories;

      const uid = uuid()

      requestQueue.createRequest({
        request: duplicateCategory,
        onMutate: () => {
          commit("UPDATE_ESTIMATE", {...state.estimate, categories :  [...state.estimate.categories, { ...duplicatedCategory, id : uid}] })
        },
        onError: () => {
          commit("UPDATE_ESTIMATE", {...state.estimate, categories : oldData})
        },
        onSuccess : () => {}
      })({
        estimateId,
        duplicatedCategoryId : duplicatedCategory.id,
        newCategoryId : uid
      })
    },

    updateCategories: async( {commit, state}, {estimateId, categories}) => {
        const oldData = state.estimate.categories;

        requestQueue.createRequest({
          request : updateCategories,
          onMutate: () => {
            commit('UPDATE_ESTIMATE', {...state.estimate, categories})
          },
          onError : () => {
            commit("UPDATE_ESTIMATE", {...state.estimate, categories : oldData})
          },
          onSuccess : () => {}
        })({estimateId, categories})
    },

    addFeature: async ({ commit, state }, { estimateId, categoryId, feature }) => {
      const category = state.estimate.categories.find((category) => category.id === categoryId);

      feature.id = uuid();

      requestQueue.createRequest({
        request: addFeature,
        onMutate: () =>
          commit("UPDATE_CATEGORY", {
            id: categoryId,
            updatedCategory: {
              ...category,
              features: [...category.features, feature],
              hours: category.hours + Number(feature.hours),
              cost: category.hours + Number(feature.hours) * category.ratePerHour,
            },
          }),
        onError: (error) => {
          console.log(error);
          commit("UPDATE_CATEGORY", {
            id: categoryId,
            updatedCategory: {
              ...category,
              features: category.features.filter((f) => f.id !== feature.id),
              hours: category.hours - Number(feature.hours),
            },
          });
        },
        onSuccess: () => {},
      })({ estimateId, categoryId, feature });
    },

    updateFeature: async ({ commit, state }, { estimateId, categoryId, feature }) => {
      const category = state.estimate.categories.find((category) => category.id === categoryId);

      const differenceHours = feature.hours - category.features.find((f) => f.id === feature.id).hours;

      const request = requestQueue.createRequest({
        request: updateFeature,
        onMutate: () => {
          commit("UPDATE_CATEGORY", {
            id: categoryId,
            updatedCategory: {
              ...category,
              features: category.features.map((f) => {
                if (f.id === feature.id) {
                  return feature;
                } else {
                  return f;
                }
              }),

              hours: category.hours + differenceHours,
              cost: category.cost + differenceHours * category.ratePerHour,
            },
          });
        },
        onError: (error) => {
          console.log(error);
          commit("UPDATE_CATEGORY", {
            id: categoryId,
            updatedCategory: category,
          });
        },
        onSuccess: () => {},
      });

      request({ estimateId, categoryId, id: feature.id, feature });
    },

    deleteFeature: async ({ commit, state }, { estimateId, categoryId, featureId }) => {
      const category = state.estimate.categories.find((category) => category.id === categoryId);

      const featureHours = category.features.find((f) => f.id === featureId).hours;

      requestQueue.createRequest({
        request: deleteFeature,
        onMutate: () =>
          commit("UPDATE_CATEGORY", {
            id: categoryId,
            updatedCategory: {
              ...category,
              features: category.features.filter((feature) => feature.id !== featureId),
              hours: category.hours - featureHours,
              cost: category.cost - category.ratePerHour * featureHours,
            },
          }),
        onError: (error) => {
          console.log(error);
          commit("UPDATE_CATEGORY", {
            id: categoryId,
            updatedCategory: category,
          });
        },
        onSuccess: () => {},
      })({ estimateId, categoryId, id: featureId });
    },

    updateFeaturesOrder: async ({commit, state}, {estimateId, categoryId,  newOrder}) => {
      const oldOrder = state.estimate.categories.find(c => c.id === categoryId).features;

      const category = state.estimate.categories.find(c => c.id === categoryId);


      requestQueue.createRequest({
        request : updateFeaturesOrder,
        onMutate : () => {
          commit("UPDATE_CATEGORY" , {
            id : categoryId ,
            updatedCategory : {
              ...category,
              features : newOrder
            }
          })
        },
        onError : () => {
          commit("UPDATE_CATEGORY" , {
            id : categoryId ,
            updatedCategory : {
              ...category,
              features : oldOrder
            }
          })
        },
        onSuccess : () => {}
      })({estimateId, categoryId, features : newOrder})

    },

    addAdditionalExpense: async ({ commit, state }, { estimateId, additionalExpense }) => {
      const estimate = state.estimate;
      additionalExpense.id = uuid();

      requestQueue.createRequest({
        request: addAdditionalExpenses,
        onMutate: () => {
          commit("UPDATE_ESTIMATE", {
            ...estimate,
            additional_expenses: [...estimate.additional_expenses, additionalExpense],
          });
        },
        onError: (error) => {
          console.log(error);
          commit("UPDATE_ESTIMATE", estimate);
        },
        onSuccess: () => {},
      })({ estimateId, additionalExpense });
    },

    updateAdditionalExpense: async ({ commit, state }, { estimateId, additionalExpense }) => {
      const estimate = state.estimate;

      requestQueue.createRequest({
        request: updateAdditionalExpense,
        onMutate: () => {
          commit("UPDATE_ESTIMATE", {
            ...estimate,
            additional_expenses: estimate.additional_expenses.map((additional_expense) => {
              if (additional_expense.id === additionalExpense.id) {
                return additionalExpense;
              } else {
                return additional_expense;
              }
            }),
          });
        },
        onError: (error) => {
          console.log(error);
          commit("UPDATE_ESTIMATE", estimate);
        },
        onSuccess: () => {},
      })({ estimateId, additionalExpense });
    },

    deleteAdditionalExpense: async ({ commit, state }, { estimateId, additionalExpenseId }) => {
      const estimate = state.estimate;

      requestQueue.createRequest({
        request: deleteAdditionalExpense,
        onMutate: () => {
          commit("UPDATE_ESTIMATE", {
            ...estimate,
            additional_expenses: estimate.additional_expenses.filter((a) => a.id !== additionalExpenseId),
          });
        },
        onError: (error) => {
          console.log(error);
          commit("UPDATE_ESTIMATE", estimate);
        },
        onSuccess: () => {},
      })({ estimateId, additionalExpenseId });
    },

      updateEstimateName : async ({state, commit}, {estimateId , name}) => {
      const oldData = state.estimate;

      commit('estimates/CHANGE_CURRENT_ESTIMATE_NAME', {id : estimateId, name}, { root : true})

      requestQueue.createRequest({
        request: updateEstimate,
        onMutate: () => {
          commit('UPDATE_ESTIMATE', {...state.estimate,  name })
        },
        onError: (error) => {
          console.log(error)
          commit('UPDATE_ESTIMATE', oldData)
        },
        onSuccess: () => {
        }
      })({ id : estimateId, name, team : state.estimate.users, deadline : state.estimate.deadline, categories : state.estimate.categories })
    },

    updateEstimateDeadline : async ({state, commit}, {estimateId, deadline}) => {
      const oldData = state.estimate;

      commit('estimates/CHANGE_CURRENT_ESTIMATE_DEADLINE', {id : estimateId, deadline}, { root : true})

      requestQueue.createRequest({
        request: updateEstimate,
        onMutate: () => {
          commit('UPDATE_ESTIMATE', {...state.estimate,  deadline })
        },
        onError: (error) => {
          console.log(error)
          commit('UPDATE_ESTIMATE', oldData)
        },
        onSuccess: () => {
        }
      })({ id : estimateId, name : state.estimate.name, team : state.estimate.users, deadline, categories : state.estimate.categories })
    },

    // updateEstimateUsers : async ({state, commit}, {estimateId, users}) => {
    //   const oldData = state.estimate;
    //
    //   requestQueue.createRequest({
    //     request : updateEstimateUsersList,
    //     onMutate : () => {
    //       commit('UPDATE_ESTIMATE', {...state.estimate, users })
    //     },
    //     onError : (error) => {
    //       console.log(error)
    //         commit('UPDATE_ESTIMATE', oldData)
    //     },
    //     onSuccess : (response) => {
    //       console.log(response)
    //       commit('UPDATE_ESTIMATE', {...state.estimate, users : users.map(user => { return {...user, loading : false}})})
    //     }}
    //   )({estimateId, users})
    // }

    addUser : async ({state, commit}, {estimateId , user}) => {
      const oldData = state.estimate;

      requestQueue.createRequest({
        request : addUser,
        onMutate : () =>  commit('UPDATE_ESTIMATE', {...state.estimate, users : [...state.estimate.users, user]}),
        onError : () => commit('UPDATE_ESTIMATE', {...state.estimate, users : oldData}),
        onSuccess : () => { commit('UPDATE_ESTIMATE', {...state.estimate, users : state.estimate.users.map(u => {
          if (u.id === user.id)
          {
            return {...user , loading : false}
          }
          else {
            return u;
          }
          })});
          commit('estimates/CHANGE_CURRENT_ESTIMATE_ADD_USER', {id : estimateId , user}, { root : true})
        }
      })({estimateId : estimateId , userId : user.id})
    },

    deleteUser : async ({state, commit}, {estimateId , user}) => {
      const oldData = state.estimate;

      requestQueue.createRequest({
        request : deleteUser,
        onMutate : () =>  commit('UPDATE_ESTIMATE', {...state.estimate, users : state.estimate.users.filter(u => u.id !== user.id)}),
        onError : () => commit('UPDATE_ESTIMATE', oldData),
        onSuccess : () => {
            commit('estimates/CHANGE_CURRENT_ESTIMATE_DELETE_USER', {id : estimateId, userId : user.id}, { root : true})
        }
      })({estimateId : estimateId , userId : user.id})
    },

    addCategory : async({state, commit}, {estimateId , category}) => {
      const oldData = state.estimate;

      requestQueue.createRequest({
        request : addCategory,
        onMutate : () =>  commit('UPDATE_ESTIMATE', {...state.estimate, categories : [...state.estimate.categories, category], status : getEstiamateStatus([...state.estimate.categories, category].map(c => c.status))}),
        onError : () => commit('UPDATE_ESTIMATE', oldData),
        onSuccess : () => {}
      })({estimateId, category})
    },

    deleteCategory : async({state, commit}, {estimateId , categoryId}) => {
      const oldData = state.estimate;

      requestQueue.createRequest({
        request: deleteCategory,
        onMutate: () =>  commit('UPDATE_ESTIMATE', {...state.estimate, categories : state.estimate.categories.filter(c => c.id !== categoryId) , status : getEstiamateStatus( state.estimate.categories.filter(c => c.id !== categoryId).map(c => c.status))}),
        onError: () => commit('UPDATE_ESTIMATE', oldData),
        onSuccess: () => {}
      })({estimateId, categoryId})
    }
  },

  namespaced: true,
};
