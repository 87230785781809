const getEstimateStatus = ( statuses ) => {
  const toDoParts = statuses.filter(status => status === 'To do');
  const doneParts = statuses.filter(status => status === 'Done')

  if(toDoParts.length === statuses.length) return 'To do'
  else if(doneParts.length == statuses.length) return 'Done'
  else return 'In progress'
}

 export default getEstimateStatus;
