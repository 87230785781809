<template>
  <li v-if="item.loading" class="flex flex-row w-full">
    <div class="rounded-md w-full">
      <div class="animate-pulse flex space-x-4">
        <div class="rounded-full bg-gray-400 h-12 w-12"></div>
        <div class="flex-1 space-y-4 py-1">
          <div class="h-4 bg-gray-400 rounded w-3/4"></div>
          <div class="space-y-2">
            <div class="h-4 bg-gray-400 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li v-else class="text-gray-900 cursor-default select-none relative" role="option">
    <div class="flex items-center">
      <div class="flex items-center justify-center mr-3">
        <user-avatar size="medium" :avatar="item.avatar" />
      </div>
      <div class="space-y-1">
        <span class="font-normal block truncate text-gray-900 text-base"
          >{{ item.name }}{{ item.id === user.id ? "(You)" : "" }}</span
        >
        <span class="font-normal block truncate text-gray-500 text-base">{{ item.email }}</span>
      </div>
    </div>
<!--    <span-->
<!--      v-if="allowDelete"-->
<!--      @click="removeItem(item)"-->
<!--      class="cursor-pointer group text-red-500 absolute inset-y-0 right-0 flex items-center pr-4"-->
<!--    >-->
<!--      Delete-->
<!--    </span>-->
    <button
      type="button"
      v-if="allowDelete"
      @click="removeItem(item)"
      class="cursor-pointer group text-red-500 absolute inset-y-0 right-0 flex items-center pr-4"
    >
      <icon-trash/>
    </button>
<!--    <span-->
<!--      v-if="allowLeave"-->
<!--      @click="leave"-->
<!--      class="cursor-pointer group text-red-500 absolute inset-y-0 right-0 flex items-center pr-4"-->
<!--    >-->
<!--      Leave-->
<!--    </span>-->
    <button
      type="button"
      v-if="allowLeave"
      @click="leave"
      class="cursor-pointer group text-red-500 absolute inset-y-0 right-0 flex items-center pr-4"
    >
      <icon-log-out class="h-6 w-6"/>
    </button>
    <warning-leave-estimate @leave="leaveAndClose" />
  </li>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import { mapGetters } from "vuex";
import WarningLeaveEstimate from "@/components/modals/WarningLeaveEstimate";
import IconTrash from "./icons/IconTrash";
import IconLogOut from "./icons/IconLogOut";
// import IconLogOut from "./icons/IconLogOut";

export default {
  name: "MultipleSelectSelectedOption",
  components: { IconLogOut,   UserAvatar, WarningLeaveEstimate, IconTrash },
  props: {
    item: {
      type: Object,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },

    allowLeave: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      user: "user/user",
      currentEstimate: "sideOver/currentEstimate"
    }),
  },

  methods: {
    removeItem(item) {
      this.$emit("removeItem", item);
    },

    leave() {
      this.$modal.show("confirm-leave", { estimateId: this.currentEstimate.id });
    },

    leaveAndClose(estimateId) {
      this.$emit("leaveAndClose", estimateId);
    },
  },
};
</script>

<style>
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0) 0,
    rgba(#fff, 0.2) 20%,
    rgba(#fff, 0.5) 60%,
    rgba(#fff, 0)
  );
  animation: shimmer 2s infinite;
  content: '';
}
.blog-post__headline {
   font-size: 1.25em;
   font-weight: bold;
 }

</style>
