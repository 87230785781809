<template>
  <div class="flex flex-col items-center pt-14">
    <h1 class="text-3xl text-center text-gray-900 mb-6">Welcome to Scopo.</h1>
    <p class="font-medium text-center px-4 sm:px-1 text-neutral-500">
      Here you will be able to manage all of your estimates and create new.
    </p>
    <icon-empty-projects class="mt-2" />
    <e-button @click="$emit('action')" type="secondary">
      <icon-plus class="mr-2" />
      Add estimate
    </e-button>
  </div>
</template>

<script>
import IconEmptyProjects from "@/components/icons/IconEmptyProjects";
import EButton from "@/components/base/EButton";
import IconPlus from "@/components/icons/IconPlus";

export default {
  name: "EmptyProjectsView",
  components: { IconEmptyProjects, EButton, IconPlus },
};
</script>

<style scoped>
.w-75 {
  width: 18.75rem;
}

.h-75 {
  height: 18.75rem;
}
</style>
