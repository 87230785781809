import axios from "axios";
import router from "@/router";

export const noAuthAxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

noAuthAxiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  async (error) => {
    switch (error?.response?.status) {
      case 401:
        await router.push({ name: "Login" });
    }
  }
);
