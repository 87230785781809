<template>
  <div>
    <router-link
      :to="'/projects/' + project.id"
      tag="div"
      class="relative col-span-1 cursor-pointer hover:shadow-2xl shadow-lg rounded-xl estimate"
    >
      <div class="relative rounded-t-xl bg-pink-400 h-32" :class="[active ? '' : 'opacity-50']">
        <img v-if="project.avatar" class="absolute rounded-t-xl h-full w-full object-cover" :src="project.avatar" alt="" />
        <img
          v-if="!project.avatar"
          class="absolute rounded-t-xl h-full w-full object-cover"
          src="../assets/img/default-estimate-card-background.png"
          alt=""
        />
        <div
          v-if="!project.avatar"
          class="absolute rounded-t-xl h-full w-full blur-sm"
          :class="defaultBackgroundImageClass"
        ></div>
        <div class="flex pt-6 pl-6 pr-6 justify-between items-center">
          <badge>{{ project.done_categories_count }}/{{ project.categories_count }}</badge>
          <estimate-status v-if="active" :type="project.status" :archived-date="project.archivedDate" />
          <archived-estimate-status v-else :archived_at="project.archived_at" />
        </div>
      </div>
      <div class="p-6">
        <div
          class="flex font-medium items-center justify-between space-x-2"
          :class="[active ? 'text-gray-900' : 'text-neutral-500']"
        >
          <div class="flex-1 w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
            {{ project.name }}
          </div>
          <div class="text-sm text-neutral-500">
            <span class="mr-1">Due to:</span>
            <span>{{ formatDate }}</span>
          </div>
        </div>
        <div class="pt-4 flex justify-between items-center">
          <list-of-user-avatars :team="project.users" :max-count="7" :active="active" />
          <settings-list
            class="z-30"
            v-on-clickaway="away"
            @openMenu="isSettingsOpen = !isSettingsOpen"
            :is-settings-open="isSettingsOpen"
            v-if="active || isUserOwner"
            :stopPropagationClick="true"
          >
            <div class="py-1" role="none">
              <button v-if="!active && isUserOwner" @click.stop="restoreHandler" class="menu-item" role="menuitem">
                <IconRefreshOutline class="mr-3 h-5 w-5 text-neutral-500 group-hover:text-gray-500" />
                Restore
              </button>
              <button v-if="!active && isUserOwner" @click.stop="deleteHandler" class="menu-item" role="menuitem">
                <IconTrashOutline class="mr-3 h-5 w-5 text-neutral-500 group-hover:text-gray-500" />
                Delete
              </button>
              <button v-if="active" @click.stop="projectSettingsHandler" class="menu-item" role="menuitem">
                <IconPencilAltOutline class="mr-3 h-5 w-5 text-neutral-500 group-hover:text-gray-500" />
                Settings
              </button>
              <button v-if="active && isUserOwner" @click.stop="archiveHandler" class="menu-item" role="menuitem">
                <IconArchiveMenu class="mr-3 h-5 w-5 text-neutral-500 group-hover:text-gray-500" />
                Archive
              </button>
            </div>
          </settings-list>
        </div>
      </div>

      <!--    <div class="absolute top-0 left-0 h-full w-full z-50 opacity-overflow rounded-xl"></div>-->
    </router-link>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import SettingsList from "@/components/SettingsList";

import IconPencilAltOutline from "@/components/icons/IconPencilAltOutline";
// import IconArchiveOutline from "@/components/icons/IconArchiveOutline";
import IconArchiveMenu from "@/components/icons/IconArchiveMenu";
import IconRefreshOutline from "@/components/icons/IconRefreshOutline";
import IconTrashOutline from "@/components/icons/IconTrashOutline";
import EstimateStatus from "@/components/estimate-status/EstimateStatus";
import Badge from "@/components/Badge";
import ListOfUserAvatars from "@/components/ListOfUserAvatars";
import ArchivedEstimateStatus from "@/components/ArchivedEstimateStatus";
import { mapGetters, mapActions } from "vuex";
import estimateDeadline from "../utils/estimateDeadline";

export default {
  name: "NewProjectItem",
  components: {
    SettingsList,
    IconArchiveMenu,
    IconPencilAltOutline,
    EstimateStatus,
    ArchivedEstimateStatus,
    IconTrashOutline,
    IconRefreshOutline,
    Badge,
    ListOfUserAvatars,
  },
  mixins: [clickaway],
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSettingsOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),

    isUserOwner() {
      return this.user.id === this.project.owner_id;
    },

    active(){
      return !this.project.archived_at;
    },

    defaultBackgroundImageClass() {
      switch (this.project.color) {
        case "yellow":
          return "overlay-yellow";
        case "blue":
          return "overlay-blue";
        case "red":
          return "overlay-red";
        case "green":
          return "overlay-green ";
        default:
          return "overlay-purple";
      }
    },

    formatDate(){
      return estimateDeadline(this.project.deadline)
    }
  },
  methods: {
    ...mapActions({
      openSettingsMode : "sideOver/openSettingsMode",
      getEstimate : "estimates/currentEstimate/getEstimate"
    }),

    away() {
      this.isSettingsOpen = false;
    },
    archiveHandler() {
      this.$emit("archiveProj", this.project.id);
      this.away();
    },
    async projectSettingsHandler() {
      await this.getEstimate(this.project.id);
      this.openSettingsMode(this.project);
      this.away();
    },
    deleteHandler() {
      this.$emit("deleteProj", this.project.id);
      this.away();
    },
    restoreHandler() {
      this.$emit("restoreProject", this.project.id);
      this.away();
    },
  },

};
</script>
<style scoped>
.menu-item {
  @apply group w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
}

.overlay-red {
  background-color: rgba(220, 38, 38, 0.7);
}
.overlay-green {
  background-color: rgba(26, 116, 49, 0.7);
}

.overlay-blue {
  background-color: rgba(35, 89, 251, 0.7);
}

.overlay-yellow {
  background-color: rgba(255, 123, 0, 0.7);
}

.overlay-purple {
  background-color: rgba(98, 41, 215, 0.7);
}

.estimate {
  max-height: 16rem;
}
</style>
