<template>
  <li class="p-3 w-full text-gray-1000 leading-5 cursor-pointer flex justify-between items-center" @click="$emit('changeCurrent' , id)">
    <span class="w-full truncate">{{ name }}</span>
    <icon-checked v-if="current" class="h-5 w-5"/>
  </li>
</template>

<script>
import IconChecked from "./icons/IconChecked";
export default {
  name : "PartSelectOption",
  components: { IconChecked },
  props : {
    name : {
      type : String ,
      required : true
    },

    current : {
      type : Boolean,
      default : false
    },
    id : {
      required: true
    }
  }
}
</script>
