<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7969 7.35571C14.7969 11.2709 11.958 14.0572 7.76562 14.0572C3.74609 14.0572 0.5 10.9915 0.5 7.19523C0.5 3.39901 3.74609 0.333252 7.76562 0.333252C9.72266 0.333252 11.3691 1.01115 12.6377 2.12899L10.6602 3.92472C8.07324 1.5673 3.2627 3.33813 3.2627 7.19523C3.2627 9.58862 5.28711 11.5282 7.76562 11.5282C10.6426 11.5282 11.7207 9.58032 11.8906 8.57039H7.76562V6.2102H14.6826C14.75 6.5616 14.7969 6.89917 14.7969 7.35571Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconGoogle",
};
</script>
