<template>
  <div class="grid grid-cols-8">
    <v-select
      @input="setSelectedItems"
      :clearable="false"
      class="block mt-1 theme-brand col-span-8 team-input"
      :options="allItems"
      :getOptionLabel="(user) => user.email"
      autofocus
      @search="value  => currentText = value"
      ref="select"
      v-model="selectValue"
      placeholder="Email or name"
      :value="currentText"
    >
      <template #header>
        <label class="block text-sm font-medium mb-1 text-gray-700">Team</label>
      </template>
      <template slot="option" slot-scope="option">
        <multiple-select-option :option="option" />
      </template>
      <template #no-options>
        <no-options-team-input  :current-text="currentText" @inviteUser="inviteUser" />
      </template>
    </v-select>
    <div class="mt-5 col-span-8">
      <ul
        class="mt-1 outline-none rounded-md text-base sm:text-sm space-y-4"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
      >
        <multiple-select-selected-option
          v-for="item in usersToShow"
          :key="item.id"
          :item="item"
          @removeItem="removeItem"
          :allow-delete="
            !!(context.attributes.allowDelete || item.user_invited === user.id || item.allowDelete) &&
            user.id !== item.id
          "
          :allow-leave="user.id === item.id && context.attributes.allowLeave"
          @leaveAndClose="$emit('leaveAndClose', $event)"
        />
      </ul>
    </div>
    <user-invited-modal />
  </div>
</template>

<script>
import { inviteUser } from "@/api/apiEstimateUsers";
import { mapGetters, mapActions } from "vuex";
import MultipleSelectOption from "@/components/MultipleSelectOption";
import MultipleSelectSelectedOption from "@/components/MultipleSelectSelectedOption";
import NoOptionsTeamInput from "@/components/NoOptionsTeamInput";
import UserInvitedModal from "@/components/modals/UserInvitedModal";

export default {
  name: "MultipleSelect",
  components: {
    MultipleSelectSelectedOption,
    MultipleSelectOption,
    NoOptionsTeamInput,
    UserInvitedModal,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allItems: [],
      selectValue: "",
      currentText: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isCreating: "sideOver/isCreating",
      currentEstimate : "sideOver/currentEstimate",
      allUsers : 'users/allUsers',
      currentEstimateId : 'estimates/currentEstimate/currentEstimateId',
      team : 'estimates/currentEstimate/team'
    }),

    usersToShow() {
      return [...this.team].sort((t1,t2) => (t2.id === this.user.id)  - (t1.id === this.user.id)).filter((user) => !user.hidden);
    },
  },
  methods: {
    ...mapActions({
      updateEstimateUsers : "estimates/currentEstimate/updateEstimateUsers",
      addUser : "estimates/currentEstimate/addUser",
      deleteUser : "estimates/currentEstimate/deleteUser"
    }),

    async removeItem(selectedItem) {
      /* eslint-disable vue/no-mutating-props */ this.context.model = this.team.filter(
        (item) => selectedItem.id !== item.id
      );

      this.allItems.push(selectedItem);

      // await this.updateEstimateUsers({ estimateId : this.currentEstimateId , users : this.context.model})

      await this.deleteUser({estimateId : this.currentEstimateId, user : selectedItem})
    },

    async setSelectedItems(selectedItem) {
      this.selectValue = null;
      this.allItems = this.allItems.filter((item) => item.id !== selectedItem.id);
      /* eslint-disable vue/no-mutating-props */ this.context.model = [
        ...this.context.model,
        { ...selectedItem, allowDelete: true, loading : true },
      ];

      await this.addUser({estimateId : this.currentEstimateId, user : {...selectedItem, allowDelete : true ,loading : true}})
      // await this.updateEstimateUsers({ estimateId : this.currentEstimateId , users : this.context.model})
    },

    async inviteUser() {
      const user = await inviteUser({ email: this.currentText });
      this.context.model.push({ ...user, hidden: true });
      this.$modal.show("user-invited-modal", { email: this.currentText });
      await this.addUser({estimateId : this.currentEstimateId, user : {...user, allowDelete : true ,loading : true}})
      this.currentText = "";
      this.$refs.select.search = "";
    },
  },
  async created() {
    if (!this.isCreating) {
      /* eslint-disable vue/no-mutating-props */
      this.context.model = [...this.currentEstimate.users];

      this.context.model.sort((a, b) => (this.user.id === a.id > this.user.id) === b.id);

      this.context.model = [
        this.context.model.find((u) => u.id === this.user.id),
        ...this.context.model.filter((u) => u.id != this.user.id),
      ];
    } else this.context.model = [this.user];

    this.allItems = this.allUsers
      .filter((item) => item.id !== this.user.id && this.context.model.map((user) => user.id).indexOf(item.id) === -1);

  },
  mounted() {
    this.$refs.select.onSearchBlur = () => {
      this.$refs.select.open = false;
    };
  },
};
</script>

<style>
.team-input .vs__search::placeholder {
  color: #818e9c;
}

.team-input .vs__dropdown-menu {
  padding: 0 !important;
  border: none !important;
  margin-top: 0.25rem !important;
  @apply rounded-sm border;
}

.team-input .vs__actions{
  @apply hidden;
}

.team-input .vs__dropdown-toggle {
  @apply border border-neutral-400 shadow;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 1 !important;
}
</style>
