<template>
  <div class="flex flex-col items-center pt-14">
    <h1 class="text-3xl text-center text-gray-900 mb-6">Welcome to Scopo.</h1>
    <p class="font-medium text-center px-4 sm:px-1 text-neutral-500">
      Here you will be able to see all of your {{ textOfStatus }} estimates.
    </p>
    <icon-no-results class="mr-2" />
  </div>
</template>

<script>
import IconNoResults from "@/components/icons/IconNoResults";

export default {
  name: "NoResult",
  props: {
    status: {
      type: String,
    },
  },
  components: { IconNoResults },
  computed: {
    textOfStatus() {
      switch (this.status) {
        case "To do":
          return "to do";
        case "In progress":
          return "in progress";
        case "Done":
          return "finished";
        default:
          return "actually";
      }
    },
  },
};
</script>
