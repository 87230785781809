<template>
  <div class="border-b border-gray-200 flex flex-row space-x-10">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="pb-5 pt-6 cursor-pointer flex flex-row items-center"
      :class="[tab.text === selectedFilter ? 'border-b-2 border-purple-500' : '']"
      @click="test(tab)"
    >
      <span class="mr-2 text-sm text-gray-400" :class="[tab.text === selectedFilter ? 'text-purple-600' : '']">
        {{ tab.text }}</span
      >
      <span
        class="rounded-2xl bg-gray-100 px-3 py-1 text-xs text-gray-900"
        :class="[tab.text === selectedFilter ? 'bg-purple-100 text-purple-500' : '']"
      >
        {{ tab.count }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSection",
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    selectedFilter: {},
  },
  methods: {
    test(tab) {
      this.$emit("change", tab.text);
    },
  },
};
</script>
