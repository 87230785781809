export default {
  state: {
    currentEstimate : null,
    isOpen : false,
    isFetching : false
  },
  getters: {
    currentEstimate : state => state.currentEstimate,
    isSettings : state => !!state.currentEstimate,
    isCreating : state => !state.currentEstimate,
    usersOfCurrentEstimate: state => state.currentEstimate.users,
    isOpen : state => state.isOpen,
  },
  mutations: {
   INIT_ESTIMATE : (state, payload) => state.currentEstimate = payload,
   OPEN : (state) => state.isOpen = true,
   CLOSE: (state) => state.isOpen = false,
  },
  actions: {
    openCreatingNewEstimateMode : ({ commit }) => {
      commit('INIT_ESTIMATE', null);
      commit('OPEN');
    },

    openSettingsMode: ({commit} , estimate) => {
      commit('OPEN')
      commit('INIT_ESTIMATE', estimate);
    },

    close : ({commit}) => {
      // dispatch('estimates/currentEstimate/updateEstimateName', unsavedData, {root : true});
      commit('INIT_ESTIMATE', null);
      commit('CLOSE');
    }
  },
  namespaced: true,
};
