<template>
  <div class="flex flex-col items-center pt-14">
    <h1 class="text-3xl text-center text-gray-900 mb-6">
      We couldn't find any results for <span class="text-indigo-600">{{ searchedValueWithLimit }}</span>
    </h1>
    <p class="font-medium text-center px-4 sm:px-1 text-gray-500">Please try searching again.</p>
    <img
      class="mt-2 sm:mt-4 w-4/6 sm:w-2/5 md:w-1/3 px-1"
      src="@/assets/img/i-empty-list.png"
      alt="empty project illustration"
    />
  </div>
</template>

<script>
import stringWithLimit from "@/utils/StringWithLimit";

export default {
  name: "EmptyFilteredList",
  props: {
    searchedValue: {
      type: String,
      required: true,
    },
  },

  computed: {
    searchedValueWithLimit() {
      return stringWithLimit(this.searchedValue, 30);
    },
  },
};
</script>
