<template>
  <row class="items-center border-t border-gray-200" :class="rowClasses">
    <div
      class="whitespace-nowrap font-bold text-purple-500 text-right text-right"
      :class="[this.isEdit ? 'col-start-4' : 'col-start-3']"
      v-if="showTotalInformation"
    >
      {{ totalHours }} h
    </div>
    <div
      class="whitespace-nowrap font-bold text-purple-500 text-right text-right"
      :class="[this.isEdit ? 'col-start-5' : 'col-start-4']"
      v-if="showTotalInformation"
    >
      ${{ totalCost }}
    </div>
  </row>
</template>

<script>
import { mapGetters } from "vuex";
import Row from "../Row";

export default {
  name: "TotalRow",
  components: { Row },
  props: {
    totalHours: {
      type: Number,
      required: true,
    },

    totalCost: {
      type: Number,
      required: true,
    },

    showTotalInformation: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    rowClasses() {
      return this.isEdit ? "grid-cols-technologies-edit-mode" : "grid-cols-technologies-view-mode";
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),

    // textSizeCostClasses() {
    //   if (this.totalCost.toString().length > 8) {
    //     return "text-sm";
    //   }
    //
    //   if (this.totalCost.toString().length > 6) {
    //     return "";
    //   }
    //
    //   return "text-lg";
    // },
  },
};
</script>

