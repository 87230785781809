import {
  addAdditionalExpenses,
  deleteAdditionalExpense,
  getAdditionalExpenses,
  updateAdditionalExpense,
} from "@/api/apiAdditionalExpenses";

import requestQueue from "@/plugins/RequestQueue";
import { v4 as uuid } from "uuid";

export default {
  state: {
    additionalExpenses: [],
  },
  getters: {
    additionalExpenses: (state) => state.additionalExpenses,
  },
  mutations: {
    SET_ADDITIONAL_EXPENSES: (state, additionalExpenses) => {
      state.additionalExpenses = [...additionalExpenses];
    },

    ADD_ADDITIONAL_EXPENSE: (state, additionalExpense) => {
      state.additionalExpenses = [...state.additionalExpenses, additionalExpense];
    },

    UPDATE_ADDITIONAL_EXPENSE: (state, updatedAdditionalExpense) => {
      state.additionalExpenses = state.additionalExpenses.map((additionalExpense) => {
        if (additionalExpense.id === updatedAdditionalExpense.id) {
          return updatedAdditionalExpense;
        } else {
          return additionalExpense;
        }
      });
    },

    DELETE_ADDITIONAL_EXPENSE: (state, additionalExpenseId) => {
      state.additionalExpenses = state.additionalExpenses.filter(
        (additionalExpense) => additionalExpense.id !== additionalExpenseId
      );
    },
  },
  actions: {
    getAdditionalExpenses: async ({ commit }, estimateId) => {
      commit("SET_ADDITIONAL_EXPENSES", await getAdditionalExpenses(estimateId));
    },

    addAdditionalExpense: async ({ commit, getters }, { estimateId, additionalExpense }) => {
      const oldData = getters.additionalExpenses;

      additionalExpense.id = uuid();

      requestQueue.createRequest({
        request: addAdditionalExpenses,
        onMutate: () => {
          commit("ADD_ADDITIONAL_EXPENSE", additionalExpense);
        },
        onError: (error) => {
          console.log(error);
          commit("SET_ADDITIONAL_EXPENSES", oldData);
        },
        onSuccess: () => {},
        fakeProperties: [],
      })({ estimateId, additionalExpense });
    },

    updateAdditionalExpense: async ({ commit, getters }, { estimateId, additionalExpense }) => {
      const oldData = getters.additionalExpenses;

      requestQueue.createRequest({
        request: updateAdditionalExpense,
        onMutate: () => {
          commit("UPDATE_ADDITIONAL_EXPENSE", additionalExpense);
        },
        onError: (error) => {
          commit("SET_ADDITIONAL_EXPENSES", oldData);
          console.log(error);
        },
        onSuccess: () => {},
        fakeProperties: [],
      })({ estimateId, additionalExpense });
      // await updateAdditionalExpense(estimateId, additionalExpense);
      // commit("SET_ADDITIONAL_EXPENSES", await getAdditionalExpenses(estimateId));
    },

    deleteAdditionalExpense: async ({ commit, getters }, { estimateId, additionalExpenseId }) => {
      const oldData = getters.additionalExpenses;

      requestQueue.createRequest({
        request: deleteAdditionalExpense,
        onMutate: () => {
          commit("DELETE_ADDITIONAL_EXPENSE", additionalExpenseId);
        },
        onError: (error) => {
          commit("SET_ADDITIONAL_EXPENSES", oldData);
          console.log(error);
        },
        onSuccess: () => {},
        fakeProperties: [],
      })({ estimateId, additionalExpenseId });

      // await deleteAdditionalExpense(estimateId, additionalExpenseId);
      // commit("SET_ADDITIONAL_EXPENSES", await getAdditionalExpenses(estimateId));
    },
  },
  namespaced: true,
};
