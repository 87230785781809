<template>
  <div class="grid px-4 py-1 gap-4" :class="rowClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Row",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rowClasses() {
      return this.small ? "h-12" : "h-14";
    },
  },
};
</script>
