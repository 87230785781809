<template>
  <div class="flex flex-col global-cont items-center">
    <div class="bg-gray-100 py-4 px-6 justify-between flex flex-row pdf z-10" id="file-header">
      <span class="truncate w-full text-gray-1000 leading-6 h-full"> {{ file.name }}</span>
      <button @click="$emit('close')" class="h-full">
        <IconClose/>
      </button>
    </div>
    <pdf-with-loading id="file-pdf" class="overflow-y-scroll pdf" :file="file"/>

  </div>
</template>

<script>
import IconClose from "../icons/IconClose";
import PdfWithLoading from "./PdfWithLoading";

export default {
   name : "PdfView",
   props : {
     file : {

     }
   },
   components : {IconClose, PdfWithLoading}
}
</script>

<style>
.overlay-background{
  background-color: rgba(17, 24, 39, 0.5);
}

.pdf{
  overflow: hidden;
  max-width: calc(100vw - 80px);
  overflow-x:scroll;
}


@media screen and (min-width: 824px) {
  .pdf{
    overflow: hidden;
    max-width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .pdf{
    overflow: hidden;
    max-width: 1024px;
  }
}

.global-cont{
  max-height: 90%;
  max-width: calc(100% - 130px);
  overflow-x:hidden;
}
</style>
