<template>
  <div>
    <category-overview v-for="category in categories" :key="category.id" :category="category" />
  </div>
</template>

<script>
import CategoryOverview from "@/components/CategoryOverview";

export default {
  name: "AllFeatures",
  components: { CategoryOverview },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>
