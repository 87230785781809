<template>
  <button
    class="h-10 flex flex-row items-center justify-center disabled:opacity-40 disabled:cursor-default"
    :class="buttonClasses"
    @click="onClick"
    type="button"
    :disabled="disabled"
  >
    <svg class="h-4 w-4 rounded-full border-b-2 border-white animate-spin mr-2" v-if="loadingSpinner"></svg>
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "primary",
      validator: (value) => ["primary", "secondary", "text", "error"].indexOf(value) !== -1,
    },
    loadingSpinner: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      switch (this.type) {
        case "primary":
          return "px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
        case "secondary":
          return "px-2 py-1 border shadow-sm text-sm leading-4 font-medium rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900";
        case "text":
          return "px-2 py-1 text-primary-500 font-medium";
        case "error":
          return "px-2 py-1 border shadow-sm rounded-md  bg-red-500  text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900";
        default:
          return "";
      }
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
