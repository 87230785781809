<template>
  <div class="flex-1 h-0 overflow-y-scroll">
    <div class="flex-1 flex flex-col justify-between">
      <div class="px-4 sm:px-6">
        <div class="space-y-6" :class="needPadding">
          <div class="grid grid-cols-6 gap-6">
            <div class="grid grid-cols-6 gap-6 col-span-6" v-if="showNameAndDeadlineFields">
              <FormulateInput
                placeholder="Estimate name..."
                error-behavior="submit"
                error-class="mt-2 text-sm text-red-600"
                input-class="block mt-1 w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                label="Estimate information"
                label-class="block text-sm font-medium text-gray-700"
                name="name"
                outer-class="col-span-4"
                validation="max:100,length"
                ref="estimateNameInput"
              />

              <div class="col-span-2 relative">
<!--                <datetime-->
<!--                  class="theme-brand absolute w-11/12 left-1 top-7 z-10"-->
<!--                  :min-datetime="minDatetime"-->
<!--                  name="deadline"-->
<!--                  v-model="initialDate"-->
<!--                  type="date"-->
<!--                  :hide-backdrop="false"-->
<!--                  :auto="true"-->
<!--                  input-class="block w-full p-1 border-transparent rounded-md  sm:text-sm"-->
<!--                  label="Deadline"-->
<!--                >-->
<!--                  &lt;!&ndash;                          <label class="block text-sm font-medium text-gray-700" slot="before">Deadline</label>&ndash;&gt;-->
<!--                </datetime>-->

<!--                <FormulateInput-->
<!--                  type="date"-->
<!--                  name="deadline"-->
<!--                  input-class="block rounded-md mt-1 w-full border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"-->
<!--                  placeholder="Sample date placeholder"-->
<!--                  :min="minDatetime"-->
<!--                  v-model="initialDate"-->
<!--                  input-has-errors-class="placeholder-red-300 border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"-->
<!--                  outer-class="w-full absolute top-0  height-38"-->
<!--                  label="Deadline"-->
<!--                  label-class="block text-sm font-medium text-gray-700"-->
<!--                  error-class="mt-2 text-sm text-red-600"-->
<!--                  error-behavior="submit"-->
<!--                />-->

                    <FormulateInput
                      type="deadline"
                      name="deadline"
                      :minDatetime="minDatetime"
                    />
              </div>
            </div>
            <div class="col-span-6 fake-padding" :class="needBorder">
              <div class="px-6 pt-4">
                <FormulateInput
                  type="team"
                  name="team"
                  :allowDelete="isCreating || currentEstimate.owner_id === user.id"
                  :allowLeave="!isCreating && currentEstimate.owner_id  !== user.id"
                  @leaveAndClose="$emit('leaveAndClose', $event)"
                ></FormulateInput>
              </div>
            </div>
            <div class="col-span-6 border-t border-gray-200 fake-padding">
              <div class="px-6 pt-4">
                <FormulateInput
                  type="parts"
                  name="categories"
                  :allowDelete="isCreating || currentEstimate.owner_id === user.id"
                ></FormulateInput>
              </div>
            </div>
          </div>
          <div class="mt-5 grid gap-6 grid-cols-6 md:mt-0 md:col-span-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EstimateForm",

  data() {
    return {
      initialDate: null,
      minDatetime: new Date().toISOString(),
    };
  },

  computed: {
    ...mapGetters({
      user: "user/user",
      isCreating: "sideOver/isCreating",
      currentEstimate: "sideOver/currentEstimate"
    }),

    isUserOwner() {
      return this.currentEstimate.owner_id === this.user.id;
    },

    showNameAndDeadlineFields() {
      return !this.isCreating ? this.isUserOwner : true;
    },

    needPadding() {
      return this.showNameAndDeadlineFields ? "pt-6 pb-6" : "";
    },

    needBorder() {
      return this.showNameAndDeadlineFields ? "border-t border-gray-200" : "";
    },
  },
  created() {
    this.initialDate = !this.isCreating ? this.currentEstimate.deadline + "T00:00:00.001Z" : new Date().toISOString();
  },

  mounted(){
    // console.log(document.getElementById('parent'));
    // console.log(document.getElementById('parts-block'));
    //
    // const top = document.getElementById('parts-block').offsetTop;
    //
    // document.getElementById('parent').scrollTo({top : top, behavior : 'smooth'})

   if(this.showNameAndDeadlineFields)this.$refs.estimateNameInput.$el.querySelector('input').focus()
  }
};
</script>

<style scoped>
.fake-padding {
  margin-right: -1.5em;
  margin-left: -1.5em;
}

.height-38 {
  height: 40px;
}
</style>
