<template>
  <div class="h-12 w-12 bg-green-100 rounded-full flex justify-center items-center">
    <IconArchiveOutline class="w-5 h-5" color="#059669" />
  </div>
</template>

<script>
import IconArchiveOutline from "@/components/icons/IconArchiveOutline";

export default {
  name: "SuccessArchive",
  components: { IconArchiveOutline },
};
</script>
