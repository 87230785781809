<template>
  <span class="py-1 px-2 rounded-md w-28 text-center bg-white z-20 h-8 leading-6" :class="additionalClasses">
    {{ textArchivedAt }}
  </span>
</template>

<script>
export default {
  name: "ArchivedEstimateStatus",

  props: {
    archived_at: {
      required: true,
    },

    withBorder: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    textArchivedAt() {
      return (
        this.archived_at[0] +
        this.archived_at[1] +
        this.archived_at[2] +
        this.archived_at[3] +
        "." +
        this.archived_at[5] +
        this.archived_at[6] +
        "." +
        this.archived_at[8] +
        this.archived_at[9]
      );
    },

    additionalClasses() {
      return this.withBorder ? "border border-gray-1000" : "";
    },
  },
};
</script>
