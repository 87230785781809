<template>
  <layout :is-footer-visible="false">
    <div class="flex flex-col items-center pt-14">
      <h1 class="text-3xl text-center text-gray-900 mb-6">Sorry, page not found</h1>
      <p class="font-medium text-center px-1 max-w-sm text-gray-500">
        Sorry, we couldn't find the page are you looking :( Check your internet connection or try again.
      </p>
      <img class="w-full sm:w-2/5 md:w-1/2 px-1" src="../../assets/img/i-404.png" alt="404 illustration" />
    </div>
  </layout>
</template>

<script>
import Layout from "@/layout/Layout";

export default {
  name: "NotFoundPage",
  components: { Layout },
};
</script>

<style scoped></style>
