var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"align-middle inline-block min-w-full"},[_c('div',{staticClass:"shadow overflow-hidden border border-gray-200 rounded-lg"},[_c('div',{staticClass:"min-w-full flex flex-col divide-y divide-gray-200",class:_vm.isEdit ? 'table-min-height' : ''},[_c('header-row'),(!_vm.categories.length)?_c('empty-data-technologies'):_c('draggable',{staticClass:"flex flex-auto flex-col divide-y divide-gray-200",attrs:{"tag":"div","draggable":".draggable"},model:{value:(_vm.listOfCategories),callback:function ($$v) {_vm.listOfCategories=$$v},expression:"listOfCategories"}},_vm._l((_vm.listOfCategories),function(technology,index){return _c('technology-row',{key:technology.id,staticClass:"h-16 py-2",class:[_vm.isEdit ? 'draggable' : ''],attrs:{"project-id":_vm.projectId,"technology":technology,"with-border":_vm.categories.length < 1 &&
                !_vm.additionalExpenses.length &&
                index === _vm.categories.length - 1 &&
                !(!_vm.additionalExpenses.length && _vm.showAdditionalExpenseForm)}})}),1),(_vm.isEdit || _vm.additionalExpenses.length > 0)?_c('div',{staticClass:"w-full bg-neutral-100 py-3 px-4 text-neutral-1000 text-xs uppercase flex flex-row items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Additional expenses")]),(_vm.isEdit)?_c('icon-information-circle',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'If You have some additional expenses, You can add them here',
            classes: 'tooltip-info-icon',
            delay: {
              show: 200,
            },
          }),expression:"{\n            content: 'If You have some additional expenses, You can add them here',\n            classes: 'tooltip-info-icon',\n            delay: {\n              show: 200,\n            },\n          }",modifiers:{"bottom":true}}],staticClass:"h-5 w-5"}):_vm._e()],1):_vm._e(),_vm._l((_vm.additionalExpenses),function(additionalExpense){return _c('additional-expense',{key:additionalExpense.name,attrs:{"additional-expense":additionalExpense}})}),(_vm.isEdit)?_c('additional-expense-form',{attrs:{"project-id":_vm.projectId}}):_vm._e(),(_vm.categories.length || _vm.totalHours || _vm.totalCost)?_c('total',{attrs:{"total-hours":_vm.totalHours,"total-cost":_vm.totalCost,"show-total-information":true}}):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }