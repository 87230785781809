<template>
  <row class="bg-gray-50 grid-cols-table" :small="true" :class="rowClasses">
    <header-label class="text-left" :class="technologiesLabelClasses">Features</header-label>
    <header-label class="text-right" :class="ratePerHourLabelClasses">Rate per hour</header-label>
    <header-label class="text-right" :class="hoursLabelClasses">Hours</header-label>
    <header-label class="text-right" :class="costLabelClasses">Cost</header-label>
  </row>
</template>

<script>
import HeaderLabel from "@/components/tables/HeaderLabel";
import Row from "@/components/tables/rows/Row";
import { mapGetters } from "vuex";

export default {
  name: "HeaderRow",
  components: { Row, HeaderLabel },
  computed: {
    rowClasses() {
      return this.isEdit ? "grid-cols-features-edit-mode" : "grid-cols-features-view-mode";
    },

    technologiesLabelClasses() {
      return this.isEdit ? "col-start-2" : "";
    },

    ratePerHourLabelClasses() {
      return this.isEdit ? "col-start-3" : "";
    },

    hoursLabelClasses() {
      return this.isEdit ? "col-start-4" : "";
    },

    costLabelClasses() {
      return this.isEdit ? "col-start-5" : "";
    },

    ...mapGetters({
      isEdit: "project/isEdit",
    }),
  },
};
</script>
