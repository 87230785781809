import { axiosInstance } from "@/api/axiosInstances";

export async function getAdditionalExpenses(estimateId) {
  const { data } = await axiosInstance.get(`/api/estimates/${estimateId}/additionalExpenses`);

  return data.data;
}

export async function addAdditionalExpenses({ estimateId, additionalExpense }) {
  const formData = new FormData();

  formData.append("name", additionalExpense.name);
  formData.append("cost", additionalExpense.cost);
  formData.append("id", additionalExpense.id);

  await axiosInstance.post(`/api/estimates/${estimateId}/additionalExpenses`, formData);
}

export async function updateAdditionalExpense({ estimateId, additionalExpense }) {
  await axiosInstance.put(
    `/api/estimates/${estimateId}/additionalExpenses/${additionalExpense.id}?name=${additionalExpense.name}&cost=${additionalExpense.cost}`
  );
}

export async function deleteAdditionalExpense({ estimateId, additionalExpenseId }) {
  await axiosInstance.delete(`/api/estimates/${estimateId}/additionalExpenses/${additionalExpenseId}`);
}
