<template>
  <FormulateForm class="h-full">
    <FormulateInput
      type="image"
      name="file"
      :outer-class="['h-full']"
      :wrapper-class="['h-full']"
      :element-class="['h-full']"
      :upload-area-class="['h-full']"
      :files-class="['h-full']"
      v-model="file"
      :uploader="normalUpload"
    >
      <template #uploadAreaMask>
        <div
          v-if="!file || file.files.length === 0"
          class="border-dashed border border-primary-500 bg-primary-100 h-full rounded-md flex justify-center items-center"
        >
          <div class="grid justify-items-center text-primary-500">
            <icon-upload class="mb-3" />
            <span class="text-primary-500 text-sm leading-5 font-medium">Upload file</span>
          </div>
        </div>
      </template>
      <template #file>
        <div class="shadow-lg rounded-md h-full flex place-items-center justify-center">
          <div>
            <div v-text="fileName" class="file-name mb-2 flex items-center text-center" />
            <div class="bg-white h-2">
              <div class="bg-indigo-600 h-2 rounded-md" :style="{ width: file.files[0].progress + '%' }" />
            </div>
          </div>
        </div>
      </template>
    </FormulateInput>
  </FormulateForm>
</template>

<script>
import IconUpload from "../icons/IconUpload";
import { axiosInstance } from "../../api/axiosInstances";

export default {
  name: "FileForm",
  components: { IconUpload },
  props : {
    projectId : {
      type : Number
    },

    uid : {

    }
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    uploadFile() {
      this.file = null;
    },

    async normalUpload(file, progress) {
      if(file.size > 1024 * 1024 * 10)
      {
        this.$emit('largeSize');
        this.file = null;
        return;
      }


      const formData = new FormData();
      this.$emit('add-new-file-form', this.uid)


      formData.append('file', file)

      const response = await axiosInstance.post(
        `/api/estimates/${this.projectId}/medias`,
        formData,
        {
          onUploadProgress: (e) => {
            progress(Math.round((e.loaded / e.total) * 100))
          }
        }
      )


      this.$emit('update', response.data.data);

      this.$nextTick(function () {
        this.$emit("uploadCompleted", this.uid);
        this.file = null
      })
    }
  },
  computed: {
    fileName() {
      return this.file.files[0].name.length <= 15
        ? this.file.files[0].name
        : this.file.files[0].name.slice(0, 12) + "...";
    },
  },
};
</script>

<style scoped>
.formulate-input[data-classification="file"] >>> .formulate-input-upload-area {
  @apply py-0;
}

.formulate-input[data-classification="file"] >>> .formulate-files li {
  @apply h-full;
}

.file-name:before {
  background-color: #a8a8a8;
  content: "";
  -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.06 83.59"><path d="M55.94,83.59a8.16,8.16,0,0,0,8.12-8.16V19.12a1.77,1.77,0,0,0-.52-1.25L46.21.59A1.69,1.69,0,0,0,45.14.08L44.69,0l-.18,0H8.13A8.18,8.18,0,0,0,0,8.16V75.41a8.16,8.16,0,0,0,8.13,8.16H55.94ZM46.68,6,58.11,17.38H46.68ZM3.52,75.43V8.16A4.64,4.64,0,0,1,8.13,3.52h35V19.16a1.75,1.75,0,0,0,1.76,1.74H60.55V75.43a4.65,4.65,0,0,1-4.61,4.65H8.13A4.65,4.65,0,0,1,3.52,75.43Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
}
</style>
