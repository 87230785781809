<template>
  <div
    class="flex flex-row items-center h-8 cursor-pointer"
    :class="hoverClassesContainer"
    @click.stop.prevent="onSelect"
    @mouseover="active = true"
    @mouseleave="active = false"
  >
    <div class="mark-circle ml-5 mr-3 rounded-full" :class="markClass"></div>
    <span class="block truncate status-span text-sm" :class="hoverClassesText">
      {{ status }}
    </span>
  </div>
</template>

<script>
export default {
  name: "StatusSelectOption",
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    onSelect() {
      this.$emit("select");
    },
  },
  computed: {
    markClass() {
      switch (this.status) {
        case "To do":
          return "bg-blue-accent";
        case "In progress":
          return "bg-yellow-accent";
        case "Done":
          return "bg-green-accent";
        default:
          return "";
      }
    },

    hoverClassesContainer() {
      return this.active ? "bg-gray-100" : "";
    },

    hoverClassesText() {
      return this.active ? "text-neutral-1000" : "";
    },
  },
};
</script>

<style>
.mark-circle {
  height: 8px;
  width: 8px;
}

.status-span {
  color: #656f79;
}
</style>
