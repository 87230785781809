<template>
  <div class="-mt-px w-0 flex-1 flex cursor-pointer">
    <a
      class="cursor-pointer border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
      @click.prevent="$emit('click')"
    >
      <IconArrowLeft class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      Previous
    </a>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/icons/IconArrowLeft";

export default {
  name: "PaginationLinkMovePrev",
  components: { IconArrowLeft },
};
</script>
