<template>
  <div>
    <formulate-editable-content
      :initial-value="part"
      @update="changeCurrentPart"
      validation="required|max:100,length"
      :exist-suffix="true"
      :need-right-padding="true"
      :input-max-length="Number(100)"
      v-if="allowDelete"
    >
      <template #suffix
        ><button type="button" class="text-primary-500 cursor-pointer" @click="deletePart">
          <icon-trash color="#EF4444" /></button
      ></template>
    </formulate-editable-content>
    <formulate-editable-content :initial-value="part" @update="changeCurrentPart" validation="required" v-else>
    </formulate-editable-content>
  </div>
</template>

<script>
// import EditableContent from "@/components/EditableContent";
import IconTrash from "@/components/icons/IconTrash";
import FormulateEditableContent from "@/components/formulate-inputs/FormulateEditableContent";

export default {
  name: "SelectedPart",
  props: {
    part: {
      type: String,
      required: true,
    },

    partId: {},

    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeCurrentPart(value) {
      this.$emit("changeCurrentPart", { value, id: this.partId });
    },

    deletePart() {
      this.$emit("deletePart");
    },
  },
  components: { FormulateEditableContent, IconTrash },
};
</script>
