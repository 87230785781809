<template>
  <FormulateForm
    class="grid grid-cols-technologies-edit-mode px-4 py-1 gap-4"
    name="addAdditionalExpenseForm"
    @submit="onSubmit"
    v-model="fields"
  >
      <div class="mt-1 relative rounded-md shadow-sm col-start-1 col-end-3">
        <FormulateInput
          type="text"
          name="name"
          ref="name"
          input-class="block w-full text-sm leading-5 border-gray-300 rounded-md"
          placeholder="Additional expense name"
          validation="bail|required:trim|max:50,length"
          error-behavior="submit"
        />
      </div>
      <div class="mt-1 relative rounded-md shadow-sm flex flex-row col-start-4 col-end-6 cost-input flex justify-self-end">
        <div class="absolute inset-y-0 left-0 pl-3 mr-7 flex items-center pointer-events-none max-height-dollar">
          <span class="text-gray-500 sm:text-sm"> $ </span>
        </div>
        <FormulateInput
          placeholder="Cost"
          type="text"
          name="cost"
          input-class="block w-full pl-7 text-sm leading-5 border-gray-300 rounded-md"
          validation="bail|required:trim|number|min:0,value|max:10000000,value"
          error-behavior="submit"
        />
      </div>

    <button class="text-primary-500 pr-1 cursor-pointer text-sm font-bold additional-expense-add-button flex justify-center items-center ">
      <icon-plus/>
    </button>
  </FormulateForm>
</template>

<script>
import { mapActions } from "vuex";
import IconPlus from "../../../icons/IconPlus";
// import IconPlusSm from "../../../icons/IconPlusSm";

export default {
  name: "AdditionalExpenseForm",
  components : {
   IconPlus
  },
  props: {
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      fields: {
        name: "",
        cost: null,
      },
    };
  },
  methods: {
    ...mapActions({
      addAdditionalExpense: "estimates/currentEstimate/addAdditionalExpense",
    }),

    async onSubmit() {
      await this.addAdditionalExpense({
        estimateId: this.$route.params.projectId,
        additionalExpense: { name: this.fields.name.trim(), cost: this.fields.cost.trim() },
      });
      this.reset();
    },
    reset() {
      this.$formulate.reset("addAdditionalExpenseForm");
      this.fields.name = "";
      this.fields.cost = null;
      this.$refs.name.$el.querySelector("input").focus();
    },
  },

  destroyed() {},
};
</script>

<style>
.input-name {
  max-width: 18rem;
}

.dollar {
  margin-right: -20px;
  z-index: 50;
}

.max-height-dollar {
  max-height: 38.48px;
}
.additional-expense-add-button{
  max-height: 2.675rem;
}

.cost-input{
  max-width: 100px;
}
</style>
