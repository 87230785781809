<template>
  <a
    class="cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
    :class="[current ? 'border-indigo-500 text-indigo-600' : '']"
    @click.prevent="$emit('click')"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: "PaginationLink",
  props: {
    current: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
