import { axiosInstance } from "@/api/axiosInstances";

export async function getUsersByEmail(email) {
  const { data } = email
    ? await axiosInstance.get("/api/users/email", {
        params: { email },
      })
    : await axiosInstance.get("/api/users/email");

  return data.data;
}
