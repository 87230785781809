<template>
  <file-modal @movePrev="movePrev" @moveNext="moveNext" :file="files[index]" @close="$emit('close')" :show-arrows="files.length > 1"/>
</template>

<script>
import { mapGetters} from "vuex";
import FileModal from "./file-readers/FileModal";

export default {
  name : "FileViewer",
  components : {
    FileModal
  },
  props : {
    initialFileId : {
    }
  },
  data(){
    return{
      currentIndex : 0
    }
  },
  methods : {
    moveNext(){
      this.currentIndex = (this.currentIndex + 1) % this.files.length
    },

    movePrev(){
      this.currentIndex--;
      if(this.currentIndex < 0 )
      {
        this.currentIndex = this.files.length - 1;
      }
    }
  },
  computed : {
    ...mapGetters({
      files : "estimates/currentEstimate/avalibleToModalFiles"
    }),

    index(){
      return this.currentIndex % this.files.length
    },

    currentComponent() {
      const re = /.*\.pdf$/

       return re.test(this.files[this.index].url) ? 'FilePreview' : "PhotoReader"
    }
  },

  created() {
    for (let i = 0; i < this.files.length; i++)
      if (this.files[i].id === this.initialFileId)
      {
        this.currentIndex = i;
        break;
      }
  }
}
</script>
