<template>
  <header class="flex flex-row items-center justify-between space-x-4">
    <div class="flex flex-1 flex-row items-center">
      <div class="flex items-center mr-4" v-if="showArrow">
        <router-link to="/projects" >
          <icon-prev />
        </router-link>
      </div>
      <div class="flex flex-col space-y-1 sm:max-w-sm md:max-w-md">
        <div class="text-2xl mt-2 w-full whitespace-nowrap overflow-x-hidden overflow-ellipsis">
          {{ this.project.name }}
        </div>
        <div v-if="showStatus">
          <estimate-status
            class="mr-2"
            v-if="!project.archived_at"
            :type="project.archived_at ? 'Archived' : project.status"
            :archived-date="project.archived_at"
          />
          <archived-estimate-status class="mr-2" v-else :archived_at="project.archived_at" :with-border="true" />
          <span class="text-gray-500 text-sm leading-4">Deadline {{ formatDate }} </span>
        </div>
      </div>
      <div class="ml-12 flex"><list-of-user-avatars v-if="showTeam" :team="project.users" :max-count="3" /></div>
    </div>
    <slot class="flex" />
  </header>
</template>

<script>
import IconPrev from "@/components/icons/IconPrev";
import EstimateStatus from "@/components/estimate-status/EstimateStatus";
import ArchivedEstimateStatus from "@/components/ArchivedEstimateStatus";
import ListOfUserAvatars from "@/components/ListOfUserAvatars";
import estimateDeadline from "../../utils/estimateDeadline";

export default {
  name: "FilePageHeaderSection",
  components: { ArchivedEstimateStatus, IconPrev, EstimateStatus, ListOfUserAvatars },
  props: {
    project: {
      required: true,
    },

    showTeam: {
      type: Boolean,
      default: false,
    },

    showArrow: {
      type: Boolean,
      default: true,
    },

    showStatus: {
      type : Boolean,
      default : true
    }
  },

  computed: {
    formatDate() {
      return estimateDeadline(this.project.deadline);
    },
  },
};
</script>
